import React from "react";
import "../Styles/style.css";
import Innerloader from "../../images/innerloader.gif";

const InnerLoader = () => {
  return (
    <div className="h-100">
      <div className="innerloaderMain">
        <img className="loaderGif" src={Innerloader} alt="" srcSet="" />
      </div>
    </div>
  );
};

export default InnerLoader;
