import * as React from "react";
import { useState } from "react";
import "../Styles/style.css";
import ResetOtp from "../modals/password-reset-otp";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import SuccessTick from "../../images/success-tick.gif";
import baseUrl from "../../api/ipconfig.js";
import ResetPassword from "./reset-password";

export default function ForgotPasswordBody() {
  const [otpPopup, setOtpPopup] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [successLogin, setSuccessLogin] = useState(false);

  const [dataFromChild, setDataFromChild] = useState("");

  const handleDataFromChild = (data) => {
    // Update the parent component's state with data from the child component
    setDataFromChild(data);
  };

  const OtpClose = () => {
    setOtpPopup(false);
  };

  //   const OtpOpen = () => {
  //     setOtpPopup(true);
  //   };

  const handleOtp = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.get(
        `${baseUrl}/fame/generateVerificationCode`,
        {
          params: {
            email: email,
          },
        }
      );

      setIsSubmitting(false);
      setSuccessLogin(true);

      setTimeout(() => {
        setSuccessLogin(false);
        setOtpPopup(true);
      }, 1000);
    } catch (error) {
      console.error("Error on otp", error);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {dataFromChild === "OTP verified" ? (
        <ResetPassword email={email} />
      ) : (
        <>
          <ResetOtp
            open={otpPopup}
            onClose={OtpClose}
            email={email}
            sendDataToParent={handleDataFromChild}
          />
          <div className="container my-5" style={{ height: "360px" }}>
            <div className="postInform w-75 m-auto py-5 ">
              <div className="text-center">
                <h4>Password Reset</h4>
              </div>
              <form onSubmit={handleOtp} className="mt-3">
                <div className="row w-75 m-auto ">
                  <div className="col-md-12 login_inputStyle">
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        <i className="fa-solid fa-envelope"></i>
                      </span>
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        placeholder="Type your Email Address"
                        aria-label="email"
                        aria-describedby="basic-addon1"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div id="titleHelp" className="form-text p-0">
                    OTP will send on this email
                  </div>
                  <div className="text-center pt-4">
                    <button
                      type="submit"
                      className="btn bgSecondary color-white"
                      style={{ width: "130px" }}
                    >
                      {isSubmitting ? (
                        <>
                          <CircularProgress
                            size="16px"
                            sx={{ color: "#fff" }}
                          />
                        </>
                      ) : successLogin ? (
                        <img
                          className="successAnim"
                          src={SuccessTick}
                          alt="success"
                          srcSet=""
                        />
                      ) : (
                        <>
                          Continue
                          <i className="fa-solid fa-chevron-right ms-3"></i>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
}
