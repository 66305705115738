import * as React from "react";
import "../Styles/style.css";
import { useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoginModal from "../loginModal/index";
import SuccessTick from "../../images/success-tick.gif";
import TopSlider from "./topSlider";
import setAuthToken from "../../auth/auth.js";
import { useAuthContext } from "../../hooks/useAuthContext";
import { AuthContext } from "../../context/AuthContext";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import baseUrl from "../../api/ipconfig";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

export default function Home() {
  const { dispatch } = useAuthContext();
  const { user } = React.useContext(AuthContext);

  const history = useHistory();

  const [errOpen, setErrOpen] = React.useState(false);

  const handleBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrOpen(false);
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSignSubmitting, setIsSignSubmitting] = useState(false);
  const [successLogin, setSuccessLogin] = useState(false);
  const [roleName, setRoleName] = useState(" ");
  const [userName, setUserName] = useState(" ");
  const [error, setError] = useState("");

  const xorEncrypt = (data, key) => {
    const encryptedData = data.split("").map((char, i) => {
      const keyChar = key.charCodeAt(i % key.length);
      const encryptedChar = char.charCodeAt(0) ^ keyChar;
      return String.fromCharCode(encryptedChar);
    });
    return encryptedData.join("");
  };

  // const [user, setUser] = useState(null);

  React.useEffect(() => {
    // //console.log("========useeffect++++");
    let replicafetchData = localStorage.getItem("data");
    let fetchData = JSON.parse(replicafetchData);
    // setUser(fetchData);
    if (user) {
      history.push("/inspection-orders");
    }
    // setShow(true)
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post(`${baseUrl}/inspectorlogin`, {
        email: userName,
        password,
      });

      //console.log(response.data);

      localStorage.setItem("token", response.data.token);
      setAuthToken(response.data.token);
      setSuccessLogin(true);
      setIsSubmitting(false);
      // window.location.reload();

      let resp = response.data.token;
      let base64Url = resp.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      let jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      const data = JSON.parse(jsonPayload);
      localStorage.setItem("data", JSON.stringify(data));

      const userResponse = await axios.get(`${baseUrl}/getUser`, {
        headers: {
          Authorization: `Bearer ${response.data.token}`,
        },
      });

      const encryptedUserData = xorEncrypt(
        JSON.stringify(userResponse.data),
        "noman"
      );
      Cookies.set("%2515M%25", encryptedUserData, {
        expires: 7,
      });

      await dispatch({ type: "LOGIN", payload: userResponse.data });
      // setLoading(false);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    } catch (err) {
      setError(err.response.data.error);
      console.error(err.response.data.error);
      setIsSubmitting(false);
      setErrOpen(true);
    }
  };

  return (
    <>
      <div>
        <Snackbar
          open={errOpen}
          autoHideDuration={4000}
          onClose={() => setErrOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setErrOpen(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>

        <div className="container">
          <Box className="myLogin p-3 p-md-4">
            <div className="modalBody_area  px-2 ">
              <h3 className="pb-2 text-center">Login</h3>
              <h6 className="pb-2 text-center">
                Connect with <span>Buyers</span>
              </h6>
              <p className="pb-2 connect_para text-center">
                You can connect with thousands of buyers and quick search
              </p>
              <div>
                <form className="row px-3" onSubmit={handleLogin}>
                  <div className="col-md-12 login_inputStyle mb-3">
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        <i className="fa-solid fa-envelope"></i>
                      </span>
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        placeholder="Type your Email Address"
                        aria-label="email"
                        aria-describedby="basic-addon1"
                        required
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 login_inputStyle mb-3">
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        <i className="fa-solid fa-lock"></i>
                      </span>
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        placeholder="Type your password"
                        aria-label="password"
                        aria-describedby="basic-addon1"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-12 p-0">
                    <button
                      type="submit"
                      className="btn mt-3 fw-btn model_loginBTn w-100"
                    >
                      {isSubmitting ? (
                        <>
                          <CircularProgress
                            size="16px"
                            sx={{ color: "#fff" }}
                          />
                        </>
                      ) : successLogin ? (
                        <img
                          className="successAnim"
                          src={SuccessTick}
                          alt="success"
                          srcSet=""
                        />
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>
                </form>
              </div>
              <div className="text-center pt-3">
                <p className="privacyText">
                  By continuing, you are agreeing to the{" "}
                  <a href="/">terms of service</a> and{" "}
                  <a href="/">privacy policy</a>
                </p>
              </div>
            </div>
          </Box>
        </div>
      </div>
    </>
  );
}
