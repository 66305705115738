import React, { useEffect } from "react";
import InspectorFormBody from "../components/inspector/inspectorForm";
import { AuthContext } from "../context/AuthContext";
import { useHistory } from "react-router-dom";

export default function InspectorForm() {
  const { user } = React.useContext(AuthContext);
  const history = useHistory();

  // useEffect(() => {
  //     document.title = 'Inspection Form | FameWheels';
  //     if (!user) {
  //         history.push("/")
  //       }
  //   }, []);
  return (
    <>
      <InspectorFormBody />
    </>
  );
}
