import React, { useRef, useEffect, useState } from "react";
import "../../Styles/style.css";
import Button from "@mui/material/Button";
import ImageModal from "../imagePreviewModal";
import axios from "axios";
import InspectionSuccess from "../../modals/inspection-success";
import { useHistory } from "react-router-dom";
import LoadingModal from "../../modals/loading-modal";
import ImageIcon from "@mui/icons-material/Image";
import { Alert, Box, Modal, Snackbar } from "@mui/material";
import carSkeleton from "../../../images/car-skeleton.png";
import { Form } from "react-bootstrap";
import baseUrl, { baseStepsUrl } from "../../../api/ipconfig";
import InnerLoader from "../../loader/innerLoader";
import imageCompression from "browser-image-compression";

const Step7 = ({ onSuccess, previouse, user, step3, setStep3 }) => {
  const token = localStorage.getItem("token");

  const [pending, setPending] = useState(false);
  const [pendingData, setPendingData] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [uploadingImages, setUploadingImages] = useState([]);
  const [uploadingImagesIndex, setUploadingImagesIndex] = useState([]);

  const [rearBumper, setRearBumper] = useState("");

  const [leftFootBand, setLeftFootBand] = useState("");
  const [rearLeftDoor, setRearLeftDoor] = useState("");
  const [rearLeftFender, setRearLeftFender] = useState("");
  const [frontLeftDoor, setFrontLeftDoor] = useState("");
  const [frontLeftFender, setFrontLeftFender] = useState("");
  const [roof, setRoof] = useState("");
  const [trunk, setTrunk] = useState("");
  const [imagePath, setImagePath] = useState(null);
  const [defectId, setDefectId] = useState(null);

  const [defectImage, setDefectImage] = useState(null);

  const [roofDefectTypes, setRoofDefectTypes] = useState([]);
  const [leftFootBandDefectTypes, setLeftFootBandDefectTypes] = useState([]);
  const [rearBumperDefectTypes, setRearBumperDefectTypes] = useState([]);
  const [frontLeftFenderDefectTypes, setFrontLeftFenderDefectTypes] = useState(
    []
  );
  const [rearLeftFenderDefectTypes, setRearLeftFenderDefectTypes] = useState(
    []
  );
  const [rearLeftDoorDefectTypes, setRearLeftDoorDefectTypes] = useState([]);
  const [frontLeftDoorDefectTypes, setFrontLeftDoorDefectTypes] = useState([]);
  const [trunkDefectTypes, setTrunkDefectTypes] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  /// defect buttons

  const [trunkDefectBtn, setTrunkDefectBtn] = useState(false);
  const [rearBumperDefectBtn, setRearBumperDefectBtn] = useState(false);

  const [rearLeftFenderDefectBtn, setRearLeftFenderDefectBtn] = useState(false);
  const [leftFootBandDefectBtn, setLeftFootBandDefectBtn] = useState(false);
  const [rearLeftDoorDefectBtn, setRearLeftDoorDefectBtn] = useState(false);
  const [frontLeftDoorDefectBtn, setFrontLeftDoorDefectBtn] = useState(false);
  const [frontLeftFenderDefectBtn, setFrontLeftFenderDefectBtn] =
    useState(false);
  const [roofDefectBtn, setRoofDefectBtn] = useState(false);

  // example start

  const [prevImg, setPrevImg] = useState(null);
  const [openImageGallery, setOpenImageGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [imageID, setImageId] = useState(null);

  const [defectedAreaPic, setDefectedAreaPic] = useState("");
  const [partId, setPartId] = useState("");
  const [openList, setOpenList] = useState(false);
  const [openCarSkeleton, setOpenCarSkeleton] = useState(false);

  const handleImageGallery = () => {
    // getImages(imageID);
    setOpenImageGallery(true);
    // setGalleryImages(images);
  };
  // example end

  const formRef = useRef(null);
  const btnRef = useRef(null);
  const history = useHistory();
  const [successIsOpen, setSuccessIsOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const extractTokenFromUrl = (url, paramName) => {
    const urlSearchParams = new URLSearchParams(url);
    return urlSearchParams.get(paramName);
  };

  const getAllDefects = async () => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    try {
      const response = await axios.get(`${baseUrl}/getalldefect`, {
        params: {
          iinitial_token: StepToken,
          inspectionstep_id: 3,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.status === 200) {
        if (response?.data?.roof_defect) {
          setRoofDefectTypes(response?.data?.roof_defect);
        } else {
          setRoofDefectTypes([]);
        }

        if (response?.data?.frontleftfender_defect) {
          setFrontLeftFenderDefectTypes(response?.data?.frontleftfender_defect);
        } else {
          setFrontLeftFenderDefectTypes([]);
        }

        if (response?.data?.leftfootboard_defect) {
          setLeftFootBandDefectTypes(response?.data?.leftfootboard_defect);
        } else {
          setLeftFootBandDefectTypes([]);
        }

        if (response?.data?.rearbumper_defect) {
          setRearBumperDefectTypes(response?.data?.rearbumper_defect);
        } else {
          setRearBumperDefectTypes([]);
        }

        if (response?.data?.rearleftdoor_defect) {
          setRearLeftDoorDefectTypes(response?.data?.rearleftdoor_defect);
        } else {
          setRearLeftDoorDefectTypes([]);
        }

        if (response?.data?.frontleftdoor_defect) {
          setFrontLeftDoorDefectTypes(response?.data?.frontleftdoor_defect);
        } else {
          setFrontLeftDoorDefectTypes([]);
        }

        if (response?.data?.trunk_defect) {
          setTrunkDefectTypes(response?.data?.trunk_defect);
        } else {
          setTrunkDefectTypes([]);
        }

        if (response?.data?.rearleftfender_defect) {
          setRearLeftFenderDefectTypes(response?.data?.rearleftfender_defect);
        } else {
          setRearLeftFenderDefectTypes([]);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const formData = new FormData();

    const fetchDraftPending = async () => {
      formData.append("iinitial_token", StepToken);
      formData.append("inspectionstep_id", 3);

      try {
        const response = await axios.post(
          `${baseUrl}/inspectionstepdetails`,
          formData,
          {
            // params: {
            //   iinitial_token: StepToken,
            //   inspectionstep_id: 3,
            // },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        //console.log(response?.data?.initial, "draft step interior");
        const formData2 = response?.data?.initial;
        populateFormWithResponse(formData2);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      getAllDefects();
    };

    fetchDraftPending();
  }, [token]);

  const populateFormWithResponse = (formData) => {
    if (!formData || typeof formData !== "object") {
      console.error("Invalid formData:", formData);
      return;
    }

    const formElements = formRef.current.elements;

    Object.keys(formData).forEach((fieldName) => {
      const field = formElements[fieldName];

      if (field) {
        field.value = formData[fieldName];
      }
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const form = formRef.current;
      if (form) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formRef]);

  const LoginClose = () => {
    setSuccessIsOpen(false);
  };

  const [images, setImages] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalImage, setModalImage] = React.useState(null);

  const [errOpen, setErrOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const getImages = async (fieldName) => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");

    setImageLoading(true);

    try {
      const response = await axios.get(`${baseUrl}/getinspectionimage`, {
        params: {
          iinitial_token: StepToken,
          image_name: fieldName,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      //console.log(response?.data?.images);

      setImages((prevImages) => ({
        ...prevImages,
        [fieldName]: [...response.data?.images],
      }));

      setGalleryImages(response?.data?.images);

      setImagePath(response?.data?.imagepath);
    } catch (error) {
      //console.log(error);
    } finally {
      setImageLoading(false);
    }
  };

  const handleImageChange = async (e, fieldName) => {
    const files = Array.from(e.target.files);
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");

    if (files.length > 0) {
      try {
        // Set pending status
        // setPending(true);
        setUploadingImages([...uploadingImages, fieldName]);
        setUploadingImagesIndex([...uploadingImagesIndex, fieldName]);
        setPendingData("Image uploading");

        const uploadPromises = files.map(async (file) => {
          const options = {
            maxSizeMB: 0.06,
            maxWidthOrHeight: 1024,
            useWebWorker: true,
          };

          const compressedFile = await imageCompression(file, options);
          //console.log("Compressed file:", compressedFile);

          const compressedImageFile = new File([compressedFile], file.name, {
            type: file.type,
          });

          const formData = new FormData();
          formData.append("image_name", fieldName);
          formData.append("iinitial_token", StepToken);
          formData.append("inspectionstep_id", 2);
          formData.append(`file[]`, compressedImageFile);

          const response = await axios.post(
            `${baseUrl}/saveinspectionimage`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          // Call getImages for each uploaded image
          await getImages(fieldName);

          // Return the response
          setUploadingImages((prevUploadImages) =>
            prevUploadImages.filter((item) => item !== fieldName)
          );
          setUploadingImagesIndex((prevUploadImages) =>
            prevUploadImages.filter((item) => item !== fieldName)
          );

          return response;
        });

        // Wait for all uploadPromises to resolve
        await Promise.all(uploadPromises);

        // Update the uploadingImages state after all images are uploaded

        setSuccessOpen(true);
        setSuccess("Images uploaded successfully");
      } catch (error) {
        console.error("Error uploading images:", error);
        setErrOpen(true);
        setError("Couldn't upload image, try again");
        setUploadingImages([]);
        setUploadingImagesIndex([]);
      } finally {
        // Reset pending status
        setPending(false);
      }
    }
  };

  const deleteDefect = async () => {
    try {
      const formData = new FormData();
      formData.append("defect_id", defectId?.id);

      const response = await axios.post(`${baseUrl}/defectdelete`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status >= 200 && response.status < 300) {
        //console.log("Delete success:", response.data);
        // Assuming getAllDefects and getDefects are functions defined elsewhere
        getAllDefects();
        setOpenDeleteModal(false);
        setSuccessOpen(true);
        setSuccess(response?.data?.message);
        // getDefects();
      } else {
        //console.log("Delete failed with status:", response.status);
        setErrOpen(true);
        setError("Delete failed with status:", response.status);
      }
    } catch (error) {
      console.error("Error deleting defect:", error);
      setError(error.response.data?.error);
      //console.log(error);
      setErrOpen(true);
    }
  };

  const handleDeleteDefect = (type) => {
    //console.log("defectId ================== > ", type);
    setOpenDeleteModal(true);
    setDefectId(type);
  };

  const handleDefectImageChange = (e, fieldName) => {
    const files = e.target.files;
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    if (files.length > 0) {
      const newImages = Array.from(files).map((file) => {
        setDefectImage(file);

        const reader = new FileReader();

        return new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result);
            setPrevImg(reader.result);
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(newImages).then((results) => {
        setImages((prevImages) => ({
          ...prevImages,
          [fieldName]: [...(prevImages[fieldName] || []), ...results],
        }));
      });
    }
  };

  const handleDeleteImage = async (ind, id) => {
    try {
      setPending(true);
      setPendingData("Deleting Image");
      const response = await axios.delete(`${baseUrl}/deleteinspection`, {
        params: {
          image_id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.status === 200) {
        const updatedGalleryImages = [...galleryImages];
        updatedGalleryImages.splice(ind, 1);
        setGalleryImages(updatedGalleryImages);

        if (images[imageID]) {
          const updatedImages = [...images[imageID]];
          updatedImages.splice(ind, 1);
          setImages({ ...images, [imageID]: updatedImages });
        }
      }
      setSuccessOpen(true);
      setSuccess("Image Deleted Successfully");
    } catch (error) {
      //console.log("delet image error", error);
      setErrOpen(true);
      setError("Couldn't delete image");
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    if (galleryImages.length === 0) {
      setOpenImageGallery(false);
    }
  }, [galleryImages.length]);

  //console.log("gallery images ====> ", galleryImages);

  //console.log(galleryImages.length);

  const handleDefectPart = (part) => {
    //console.log(part);
  };

  const getDefects = async () => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    try {
      const response = await axios.get(`${baseUrl}/getdefectimages`, {
        params: {
          iinitial_token: StepToken,
          defect_place: partId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      //console.log(response?.data);

      if (response.status === 200) {
        if (response?.data?.defect_place === "roof_defect") {
          setRoofDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "frontleftfender_defect") {
          setFrontLeftFenderDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "leftfootboard_defect") {
          setLeftFootBandDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "rearbumper_defect") {
          setRearBumperDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "rearleftdoor_defect") {
          setRearLeftDoorDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "frontleftdoor_defect") {
          setFrontLeftDoorDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "trunk_defect") {
          setTrunkDefectTypes(response?.data?.defect_rows);
        }

        if (response?.data?.defect_place === "rearleftfender_defect") {
          setRearLeftFenderDefectTypes(response?.data?.defect_rows);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const defectsTypes = async () => {
    setPending(true);
    setPendingData("Uploading Defect...");

    const formData = new FormData();

    const options = {
      maxSizeMB: 0.06,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };

    // if (defectImage) {
           
    //   const compressedFile = await imageCompression(defectImage, options);
    //   console.log("Compressed file:", compressedFile);
    //   // console.log(compressedFile)
      
    //   const compressedImageFile = new File([compressedFile], defectImage.name, {
    //     type: defectImage.type,
    //   });
     
    //     formData.append("file", compressedImageFile);
   

    // }else{
    //   return
    // }

    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    formData.append("defect_place", partId);
    formData.append("defect_type", defectedAreaPic);
    formData.append("iinitial_token", StepToken);
    formData.append("inspectionstep_id", 3);
    if (prevImg) { 
      formData.append("file", defectImage);
    }

    // if (prevImg) {
    //   formData.append("file", compressedImageFile);
    // }

    try {
      const response = await axios.post(`${baseUrl}/defects`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      //console.log(response?.data);

      if (response?.data) {
        getDefects();
        setSuccessOpen(true);
        setSuccess(response?.data);
      }
      setDefectedAreaPic("");
    } catch (error) {
      setError(error.response.data?.error);
      //console.log(error);
      setErrOpen(true);
    }
    // if (partId === "ibody_frontbumperimage") {
    //   setFrontBumperDefectTypes([...frontBumperDefectTypes,defectedAreaPic])
    // }

    // if (partId === "ibody_offfrontwingimage") {
    //   setFrontRightFenderDefectTypes([...frontRightFenderDefectTypes,defectedAreaPic])
    // }

    // if (partId === "ibody_offfrontdoorimage") {
    //   setFrontRightDoorDefectTypes([...frontRightDoorDefectTypes,defectedAreaPic])
    // }

    // if (partId === "ibody_offbackdoorimage") {
    //   setRearRightDoorDefectTypes([...rearRightDoorDefectTypes,defectedAreaPic])
    // }

    // if (partId === "ibody_offbackwingimage") {
    //   setRearRightFenderDefectTypes([...rearRightFenderDefectTypes,defectedAreaPic])
    // }

    // if (partId === "ibody_rightfootbandimage") {
    //   setRightFootBandDefectTypes([...RightFootBandDefectTypes,defectedAreaPic])
    // }
    setPending(false);
  };

  const handleModalOpen = (image) => {
    setIsModalOpen(true);
    setModalImage(image);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  const previewOpen = (image) => {
    setIsModalOpen(true);
    setModalImage(image);
  };

  const dataURLtoBlob = (dataURL) => {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const arr = new Uint8Array(raw.length);

    for (let i = 0; i < raw.length; i++) {
      arr[i] = raw.charCodeAt(i);
    }

    return new Blob([arr], { type: contentType });
  };

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitting(true);

      const url = window.location.search;
      const StepToken = extractTokenFromUrl(url, "token");
      const formId = extractTokenFromUrl(url, "form_id");

      const CreateUser = async () => {
        if (formRef.current) {
          const params = {
            data: new FormData(formRef.current),
          };
          params.data.append("userId", user?.id);
          params.data.append("iinitial_token", StepToken);
          params.data.append(
            "iinitial_id",
            formId ? formId : previouse?.iinitial_id
          );
          params.data.append("inspectionstep_id", 3);

          Object.entries(images).forEach(([fieldName, file]) => {
            params.data.append(fieldName, file);
          });

          try {
            const response = await axios.post(
              `${baseUrl}/saveacinspection`,
              params?.data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (response?.data) {
              setSuccessOpen(true);
              setSuccess("Step 3 Completed");
            }
            //console.log("====================================");
            //console.log(response);
            if (response?.status !== 200) {
              setIsSubmitting(false);
            }
            formRef.current.hasUnsavedChanges = false;
          } catch (e) {
            console.error(e);
            setErrOpen(true);
            setError(`Step 3 Submit Failed!`);
            setIsSubmitting(false);
            return;
          } finally {
            btnRef.current?.setLoader(false);
          }
        }
        onSuccess();
      };

      if (formRef.current) {
        btnRef.current?.setLoader(true);
        try {
          setPending(true);
          setPendingData("Completing Step 3");
          await CreateUser();
        } catch (e) {
          console.error(e);
          // Handle error if needed
        } finally {
          setPending(false);
          setIsSubmitting(false);
        }
      }
    },
    [history, images, previouse?.iinitial_id, token, user?.id]
  );

  const defectsArr = [
    {
      value: "P-0.55",
      name: "P",
    },
    {
      value: "A1-0.005",
      name: "A1",
    },
    {
      value: "A2-0.0314786",
      name: "A2",
    },
    {
      value: "A3-0.056",
      name: "A3",
    },
    {
      value: "B1-0.005",
      name: "B1",
    },
    {
      value: "B2-0.0314786",
      name: "B2",
    },
    {
      value: "B3-0.056",
      name: "B3",
    },
    {
      value: "C1-0.005",
      name: "C1",
    },
    {
      value: "C2-0.022508",
      name: "C2",
    },
    {
      value: "D1-0.005",
      name: "D1",
    },
    {
      value: "D2-0.0314786",
      name: "D2",
    },
    {
      value: "D3-0.056",
      name: "D3",
    },
    {
      value: "E1-0",
      name: "E1",
    },
    {
      value: "E2-0",
      name: "E2",
    },
    {
      value: "E3-0",
      name: "E3",
    },
    {
      value: "S1-0.005",
      name: "S1",
    },
    {
      value: "S2-0.022508",
      name: "S2",
    },
    {
      value: "W1-0",
      name: "W1",
    },
    {
      value: "W2-0",
      name: "W2",
    },
    {
      value: "W3-0",
      name: "W3",
    },
    {
      value: "Y1-0.005",
      name: "Y1",
    },
    {
      value: "Y2-0.022508",
      name: "Y2",
    },
    {
      value: "Y3-0.03",
      name: "Y3",
    },
    {
      value: "X-0.03",
      name: "X",
    },
    {
      value: "XX-0",
      name: "XX",
    },
    {
      value: "H-0.03004",
      name: "H",
    },
  ];

  const getImageByValue = (value) => {
    // Find the item with the specified value
    const item = uploadingImages.find((item) => item === value);

    //console.log("item image ===== >",item)

    // Return the found item
    return item;
  };

  const hidePendingImage = (imageName) => {
    const updatedImages = uploadingImagesIndex.filter(
      (item) => item !== imageName
    );
    // Update the state with the new array
    setUploadingImagesIndex(updatedImages);
  };

  const updateCarPart = (field, value) => {
    setStep3((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <>
      <LoadingModal
        open={isSubmitting}
        onClose={() => setIsSubmitting(false)}
      />

      <Snackbar
        open={errOpen}
        autoHideDuration={4000}
        onClose={() => setErrOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccessOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>

      <Snackbar
        open={pending}
        autoHideDuration={15000}
        onClose={() => setPending(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setPending(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          {pendingData}
        </Alert>
      </Snackbar>

      {uploadingImagesIndex?.map((img, index) => (
        <Snackbar
          key={index}
          open={true}
          // onClose={() => hidePendingImage(img)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          style={{ marginTop: `${index * 60}px` }}
        >
          <Alert
            onClose={() => hidePendingImage(img)}
            severity="info"
            style={{ width: "65%" }}
          >
            {`${pendingData}`}
          </Alert>
        </Snackbar>
      ))}

      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal position-relative p-3 p-md-4">
          <p className="text-center">
            Are You Sure You Want To Delete {defectId?.defect_type} Defect ?
          </p>

          <Button
            className="bg-danger text-white border-0 text-center fw-600"
            onClick={deleteDefect}
            fullWidth
          >
            Delete
          </Button>
        </Box>
      </Modal>

      {/* list modal  */}

      <Modal
        open={openCarSkeleton}
        onClose={() => setOpenCarSkeleton(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal position-relative p-3 p-md-4">
          <div className="position-relative">
            <img
              src={carSkeleton}
              className=" object-fit-contain w-100"
              alt="car-skeleton"
            />

            {roofDefectTypes && (
              <div
                onClick={() => partId === "roof_defect" && setOpenList(true)}
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "36%",
                  top: "35%",
                  width: "28%",
                  height: "22%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {roofDefectTypes &&
                    roofDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {frontLeftDoorDefectTypes && (
              <div
                onClick={() =>
                  partId === "frontleftdoor_defect" && setOpenList(true)
                }
                className=" border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  right: "8%",
                  bottom: "34%",
                  width: "18%",
                  height: "18%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {frontLeftDoorDefectTypes &&
                    frontLeftDoorDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {trunkDefectTypes && (
              <div
                onClick={() => partId === "trunk_defect" && setOpenList(true)}
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "32.5%",
                  top: "15%",
                  width: "35%",
                  height: "8%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {trunkDefectTypes &&
                    trunkDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {leftFootBandDefectTypes && (
              <div
                onClick={() =>
                  partId === "leftfootboard_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  right: "2.5%",
                  top: "37%",
                  width: "6%",
                  height: "30%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {leftFootBandDefectTypes &&
                    leftFootBandDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {rearBumperDefectTypes && (
              <div
                onClick={() =>
                  partId === "rearbumper_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  left: "30%",
                  top: "3%",
                  width: "40%",
                  height: "9%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {rearBumperDefectTypes &&
                    rearBumperDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {rearLeftFenderDefectTypes && (
              <div
                onClick={() =>
                  partId === "rearleftfender_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2 border-black position-absolute"
                style={{
                  zIndex: "9999",
                  right: "10%",
                  top: "17.5%",
                  width: "13%",
                  height: "17%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {rearLeftFenderDefectTypes &&
                    rearLeftFenderDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {frontLeftFenderDefectTypes && (
              <div
                onClick={() =>
                  partId === "frontleftfender_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  right: "10%",
                  bottom: "16.5%",
                  width: "12%",
                  height: "17%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {frontLeftFenderDefectTypes &&
                    frontLeftFenderDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {rearLeftDoorDefectTypes && (
              <div
                onClick={() =>
                  partId === "rearleftdoor_defect" && setOpenList(true)
                }
                className="border-1 d-flex gap-1 p-2  border-black position-absolute"
                style={{
                  zIndex: "9999",
                  right: "7%",
                  top: "35%",
                  width: "26%",
                  height: "13%",
                }}
              >
                <div className="d-flex flex-wrap position-absolute gap-1">
                  {rearLeftDoorDefectTypes &&
                    rearLeftDoorDefectTypes?.map((type, ind) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteDefect(type);
                          }}
                          key={ind}
                          className="bg-danger rounded-pill text-white text-center fw-600"
                          style={{
                            padding: "2px",
                            width: "19px",
                            height: "19px",
                            fontSize: "10px",
                          }}
                        >
                          {type.defect_type}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </Box>
      </Modal>

      {/* logic to open list modal  */}

      <Modal
        open={openList}
        onClose={() => setOpenList(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="sm-modal position-relative p-3 p-md-4">
          <select
            aria-label="Default select example"
            className="form-select btntextSize"
            onChange={(e) => setDefectedAreaPic(e.target.value)}
          >
            <option value="">Select</option>
            {defectsArr?.map((defect) => (
              <option value={defect.value}>{defect.name}</option>
            ))}
          </select>

          {!prevImg && (
            <Button
              variant="text"
              component="label"
              className="color-white LegendIcon bg p-2"
              fullWidth
              disabled={partId && defectedAreaPic !== "" ? false : true}
            >
              <span className=" me-2 text-capitalize">Add Image</span>{" "}
              <i className="fa-solid fa-plus"></i>
              <input
                name={partId}
                className="form-control visually-hidden"
                id={partId}
                accept=".jpg,.jpeg,.png"
                type="file"
                onChange={(e) => handleDefectImageChange(e, partId)}
              />
            </Button>
          )}
          {prevImg && (
            <div className="position-relative">
              <img
                src={prevImg}
                className=" object-fit-contain w-100 rounded my-3"
                alt="defect image"
              />
              <button
                className="btn btn-danger px-2 py-1 rounded-circle position-absolute"
                style={{ right: -5, top: "7px" }}
                onClick={() => setPrevImg(null)}
              >
                X
              </button>
            </div>
          )}

          <Button
            className="color-white LegendIcon bg p-2 text-capitalize"
            fullWidth
            disabled={partId && defectedAreaPic !== "" ? false : true}
            onClick={() => {
              handleDefectPart(partId);
              defectsTypes();
              setPrevImg(false);
              setOpenList(false);
            }}
          >
            {" "}
            Create{" "}
          </Button>
        </Box>
      </Modal>

      {/* image gallery  */}

      <Modal
        open={openImageGallery}
        onClose={() => setOpenImageGallery(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        {imageLoading ? (
          <>
            <InnerLoader />
          </>
        ) : (
          <Box className="md-modal position-relative p-3 p-md-4 d-flex flex-wrap gap-3 justify-content-around align-items-center">
            {galleryImages &&
              galleryImages?.map((img, ind) => (
                <div className="row">
                  <div
                    key={ind}
                    className="d-flex position-relative border rounded border-secondary-subtle p-2 col-lg-6 col-12 galleryImage"
                  >
                    <img
                      src={`${imagePath}/${img.inspectionimage_name}`}
                      alt={img.inspectionimage_name}
                      className=" object-fit-contain w-100"
                    />
                    <button
                      className="btn btn-danger px-2 py-1 rounded-circle position-absolute"
                      style={{ right: -12, top: -5 }}
                      onClick={() =>
                        handleDeleteImage(ind, img.inspectionimage_id)
                      }
                    >
                      X
                    </button>
                  </div>
                </div>
              ))}
          </Box>
        )}
      </Modal>

      <div className="container my-5">
        <div className="postInform p-5">
          <form ref={formRef} onSubmit={onSubmit} className="postAdForm">
            <div className="row">
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_trunk"
                    className="form-label btntextSize text-black"
                  >
                    Trunk
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_trunk"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.trunk}
                      onChange={(e) => {
                        updateCarPart("trunk", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setTrunkDefectBtn(true);
                        } else {
                          setTrunkDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {trunkDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step3.trunk === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("trunk_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_trunkimage &&
                      images.iac_trunkimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_trunkimage);
                            setImageId("iac_trunkimage");
                            getImages("iac_trunkimage");
                          }}
                        >
                          {images.iac_trunkimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_trunkimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_trunkimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_trunkimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_trunkimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_trunkimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_bootfloor"
                    className="form-label btntextSize text-black"
                  >
                    Boot Floor
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_bootfloor"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.bootFloor}
                      onChange={(e) =>
                        updateCarPart("bootFloor", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_bootfloorimage &&
                      images.iac_bootfloorimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_bootfloorimage);
                            setImageId("iac_bootfloorimage");
                            getImages("iac_bootfloorimage");
                          }}
                        >
                          {images.iac_bootfloorimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_bootfloorimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_bootfloorimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_bootfloorimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_bootfloorimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_bootfloorimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_bootlockpillartyresize"
                    className="form-label btntextSize text-black"
                  >
                    Boot Lock Pillar
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_bootlockpillartyresize"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.bootLockPillar}
                      onChange={(e) =>
                        updateCarPart("bootLockPillar", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_bootlockpillarimage &&
                      images.iac_bootlockpillarimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_bootlockpillarimage);
                            setImageId("iac_bootlockpillarimage");
                            getImages("iac_bootlockpillarimage");
                          }}
                        >
                          {images.iac_bootlockpillarimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_bootlockpillarimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_bootlockpillarimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_bootlockpillarimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_bootlockpillarimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_bootlockpillarimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_floordesk"
                    className="form-label btntextSize text-black"
                  >
                    Floor Deck
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_floordesk"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.floorDeck}
                      onChange={(e) =>
                        updateCarPart("floorDeck", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_floordeckimage &&
                      images.iac_floordeckimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_floordeckimage);
                            setImageId("iac_floordeckimage");
                            getImages("iac_floordeckimage");
                          }}
                        >
                          {images.iac_floordeckimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_floordeckimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_floordeckimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_floordeckimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_floordeckimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_floordeckimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_differentialoilleakage"
                    className="form-label btntextSize text-black"
                  >
                    Differential Oil Leakage
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_differentialoilleakage"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.differentialOilLeakage}
                      onChange={(e) =>
                        updateCarPart("differentialOilLeakage", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="No Leakage">No Leakage</option>
                      <option value="Leakage">Leakage</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_differentialoilleakageimage &&
                      images.iac_differentialoilleakageimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.iac_differentialoilleakageimage
                            );
                            setImageId("iac_differentialoilleakageimage");
                            getImages("iac_differentialoilleakageimage");
                          }}
                        >
                          {images.iac_differentialoilleakageimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_differentialoilleakageimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_differentialoilleakageimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "iac_differentialoilleakageimage"
                              )
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_differentialoilleakageimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "iac_differentialoilleakageimage"
                              )
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_sparetire"
                    className="form-label btntextSize text-black"
                  >
                    Spare Tire
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_sparetire"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.spareTyre}
                      onChange={(e) =>
                        updateCarPart("spareTyre", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Present">Present</option>
                      <option value="Not Present">Not Present</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_sparetyreimage &&
                      images.iac_sparetyreimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_sparetyreimage);
                            setImageId("iac_sparetyreimage");
                            getImages("iac_sparetyreimage");
                          }}
                        >
                          {images.iac_sparetyreimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_sparetyreimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_sparetyreimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_sparetyreimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_sparetyreimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_sparetyreimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_tools"
                    className="form-label btntextSize text-black"
                  >
                    Tools
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_tools"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.tools}
                      onChange={(e) => updateCarPart("tools", e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Complete">Complete</option>
                      <option value="Incomplete">Incomplete</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_toolsimage &&
                      images.iac_toolsimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_toolsimage);
                            setImageId("iac_toolsimage");
                            getImages("iac_toolsimage");
                          }}
                        >
                          {images.iac_toolsimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_toolsimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_toolsimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_toolsimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_toolsimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_toolsimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_jack"
                    className="form-label btntextSize text-black"
                  >
                    Jack
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_jack"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.jack}
                      onChange={(e) => updateCarPart("jack", e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Present">Present</option>
                      <option value="Not Present or Not Working">
                        Not Present or Not Working
                      </option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_jackimage &&
                      images.iac_jackimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_jackimage);
                            setImageId("iac_jackimage");
                            getImages("iac_jackimage");
                          }}
                        >
                          {images.iac_jackimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_jackimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_jackimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_jackimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_jackimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_jackimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_puncturerepairekit"
                    className="form-label btntextSize text-black"
                  >
                    Puncture Repaire Kit
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_puncturerepairekit"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.punctureRepairKit}
                      onChange={(e) =>
                        updateCarPart("punctureRepairKit", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Present">Present</option>
                      <option value="Not Present">Not Present</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_puncturerepairekitimage &&
                      images.iac_puncturerepairekitimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.iac_puncturerepairekitimage
                            );
                            setImageId("iac_puncturerepairekitimage");
                            getImages("iac_puncturerepairekitimage");
                          }}
                        >
                          {images.iac_puncturerepairekitimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_puncturerepairekitimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_puncturerepairekitimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "iac_puncturerepairekitimage"
                              )
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_puncturerepairekitimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                "iac_puncturerepairekitimage"
                              )
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_trunklock"
                    className="form-label btntextSize text-black"
                  >
                    Trunk Lock
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_trunklock"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.trunkLock}
                      onChange={(e) =>
                        updateCarPart("trunkLock", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Ok with issues">Ok with issues</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_trunklockimage &&
                      images.iac_trunklockimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_trunklockimage);
                            setImageId("iac_trunklockimage");
                            getImages("iac_trunklockimage");
                          }}
                        >
                          {images.iac_trunklockimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_trunklockimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_trunklockimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_trunklockimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_trunklockimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_trunklockimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_lefttaillightcondition"
                    className="form-label btntextSize text-black"
                  >
                    Left Taillight Condition
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_lefttaillightcondition"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.leftTailLightCondition}
                      onChange={(e) =>
                        updateCarPart("leftTailLightCondition", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Perfect">Perfect</option>
                      <option value="Scratches">Scratches</option>
                      <option value="Foggy">Foggy</option>
                      <option value="Repaired">Repaired</option>
                      <option value="Cracked or Broken">
                        Cracked or Broken
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_lefttaillightimage &&
                      images.iac_lefttaillightimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_lefttaillightimage);
                            setImageId("iac_lefttaillightimage");
                            getImages("iac_lefttaillightimage");
                          }}
                        >
                          {images.iac_lefttaillightimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_lefttaillightimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_lefttaillightimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_lefttaillightimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_lefttaillightimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_lefttaillightimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              {/* rear bumper  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_rearbumper"
                    className="form-label btntextSize text-black"
                  >
                    Rear Bumper
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_rearbumper"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.rearBumper}
                      onChange={(e) => {
                        updateCarPart("rearBumper", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRearBumperDefectBtn(true);
                        } else {
                          setRearBumperDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {rearBumperDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step3.rearBumper === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("rearbumper_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_rearbumperimage &&
                      images.iac_rearbumperimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_rearbumperimage);
                            setImageId("iac_rearbumperimage");
                            getImages("iac_rearbumperimage");
                          }}
                        >
                          {images.iac_rearbumperimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_rearbumperimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_rearbumperimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_rearbumperimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_rearbumperimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_rearbumperimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              {/* rear sub frame  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_rearsubframe"
                    className="form-label btntextSize text-black"
                  >
                    Rear Sub Frame
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_rearsubframe"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.rearSubFrame}
                      onChange={(e) =>
                        updateCarPart("rearSubFrame", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_rearsubframeimage &&
                      images.iac_rearsubframeimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_rearsubframeimage);
                            setImageId("iac_rearsubframeimage");
                            getImages("iac_rearsubframeimage");
                          }}
                        >
                          {images.iac_rearsubframeimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_rearsubframeimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_rearsubframeimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_rearsubframeimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_rearsubframeimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_rearsubframeimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              {/* rear windshield  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_rearwindshieldcondition"
                    className="form-label btntextSize text-black"
                  >
                    Rear Windshield Condition
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_rearwindshieldcondition"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.rearWindshieldCondition}
                      onChange={(e) =>
                        updateCarPart("rearWindshieldCondition", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Scratches">Scratches</option>
                      <option value="Cracked">Cracked</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_rearwindshieldimage &&
                      images.iac_rearwindshieldimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_rearwindshieldimage);
                            setImageId("iac_rearwindshieldimage");
                            getImages("iac_rearwindshieldimage");
                          }}
                        >
                          {images.iac_rearwindshieldimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_rearwindshieldimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_rearwindshieldimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_rearwindshieldimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_rearwindshieldimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_rearwindshieldimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_leftdpiller"
                    className="form-label btntextSize text-black"
                  >
                    Left D Pillar
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_leftdpiller"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.leftDPillar}
                      onChange={(e) =>
                        updateCarPart("leftDPillar", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_leftdpillarimage &&
                      images.iac_leftdpillarimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_leftdpillarimage);
                            setImageId("iac_leftdpillarimage");
                            getImages("iac_leftdpillarimage");
                          }}
                        >
                          {images.iac_leftdpillarimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_leftdpillarimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_leftdpillarimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_leftdpillarimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_leftdpillarimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_leftdpillarimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_rearleftfender"
                    className="form-label btntextSize text-black"
                  >
                    Rear Left Fender
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_rearleftfender"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.rearLeftFender}
                      onChange={(e) => {
                        updateCarPart("rearLeftFender", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRearLeftFenderDefectBtn(true);
                        } else {
                          setRearLeftFenderDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {rearLeftFenderDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step3.rearLeftFender === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("rearleftfender_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_rearleftfenderimage &&
                      images.iac_rearleftfenderimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_rearleftfenderimage);
                            setImageId("iac_rearleftfenderimage");
                            getImages("iac_rearleftfenderimage");
                          }}
                        >
                          {images.iac_rearleftfenderimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_rearleftfenderimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_rearleftfenderimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_rearleftfenderimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_rearleftfenderimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_rearleftfenderimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_leftcpillar"
                    className="form-label btntextSize text-black"
                  >
                    Left C Pillar
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_leftcpillar"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.leftCPillar}
                      onChange={(e) =>
                        updateCarPart("leftCPillar", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_leftcpillarimage &&
                      images.iac_leftcpillarimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_leftcpillarimage);
                            setImageId("iac_leftcpillarimage");
                            getImages("iac_leftcpillarimage");
                          }}
                        >
                          {images.iac_leftcpillarimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_leftcpillarimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_leftcpillarimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_leftcpillarimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_leftcpillarimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_leftcpillarimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_leftfootboard"
                    className="form-label btntextSize text-black"
                  >
                    Left Foot Board
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_leftfootboard"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.leftFootBand}
                      onChange={(e) => {
                        updateCarPart("leftFootBand", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setLeftFootBandDefectBtn(true);
                        } else {
                          setLeftFootBandDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {leftFootBandDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step3.leftFootBand === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("leftfootboard_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_leftfootboardimage &&
                      images.iac_leftfootboardimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_leftfootboardimage);
                            setImageId("iac_leftfootboardimage");
                            getImages("iac_leftfootboardimage");
                          }}
                        >
                          {images.iac_leftfootboardimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_leftfootboardimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_leftfootboardimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_leftfootboardimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_leftfootboardimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_leftfootboardimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_rearleftdoor"
                    className="form-label btntextSize text-black"
                  >
                    Rear Left Door
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_rearleftdoor"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      value={step3.rearLeftDoor}
                      onChange={(e) => {
                        updateCarPart("rearLeftDoor", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRearLeftDoorDefectBtn(true);
                        } else {
                          setRearLeftDoorDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {rearLeftDoorDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step3.rearLeftDoor === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("rearleftdoor_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_rearleftdoorimage &&
                      images.iac_rearleftdoorimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_rearleftdoorimage);
                            setImageId("iac_rearleftdoorimage");
                            getImages("iac_rearleftdoorimage");
                          }}
                        >
                          {images.iac_rearleftdoorimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_rearleftdoorimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_rearleftdoorimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_rearleftdoorimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_rearleftdoorimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_rearleftdoorimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_leftbpiller"
                    className="form-label btntextSize text-black"
                  >
                    Left B Pillar
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_leftbpiller"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.leftBPillar}
                      onChange={(e) =>
                        updateCarPart("leftBPillar", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_leftbpillarimage &&
                      images.iac_leftbpillarimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_leftbpillarimage);
                            setImageId("iac_leftbpillarimage");
                            getImages("iac_leftbpillarimage");
                          }}
                        >
                          {images.iac_leftbpillarimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_leftbpillarimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_leftbpillarimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_leftbpillarimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_leftbpillarimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_leftbpillarimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_frontleftdoor"
                    className="form-label btntextSize text-black"
                  >
                    Front Left Door
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_frontleftdoor"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.frontLeftDoor}
                      onChange={(e) => {
                        updateCarPart("frontLeftDoor", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setFrontLeftDoorDefectBtn(true);
                        } else {
                          setFrontLeftDoorDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {frontLeftDoorDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step3.frontLeftDoor === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("frontleftdoor_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_frontleftdoorimage &&
                      images.iac_frontleftdoorimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_frontleftdoorimage);
                            setImageId("iac_frontleftdoorimage");
                            getImages("iac_frontleftdoorimage");
                          }}
                        >
                          {images.iac_frontleftdoorimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_frontleftdoorimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_frontleftdoorimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_frontleftdoorimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_frontleftdoorimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_frontleftdoorimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_leftsidemirror"
                    className="form-label btntextSize text-black"
                  >
                    Left Side Mirror
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_leftsidemirror"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.leftMirror}
                      onChange={(e) =>
                        updateCarPart("leftMirror", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Working">Working</option>
                      <option value="Broken or Not Rotating">
                        Broken OR Not Rotating
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_leftsidemirrorimage &&
                      images.iac_leftsidemirrorimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_leftsidemirrorimage);
                            setImageId("iac_leftsidemirrorimage");
                            getImages("iac_leftsidemirrorimage");
                          }}
                        >
                          {images.iac_leftsidemirrorimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_leftsidemirrorimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_leftsidemirrorimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_leftsidemirrorimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_leftsidemirrorimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_leftsidemirrorimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              {/* left a pillar  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_leftapiller"
                    className="form-label btntextSize text-black"
                  >
                    Left A Pillar
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_leftapiller"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.leftAPillar}
                      onChange={(e) =>
                        updateCarPart("leftAPillar", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>
                  </div>
                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_leftapillarimage &&
                      images.iac_leftapillarimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_leftapillarimage);
                            setImageId("iac_leftapillarimage");
                            getImages("iac_leftapillarimage");
                          }}
                        >
                          {images.iac_leftapillarimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_leftapillarimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_leftapillarimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_leftapillarimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_leftapillarimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_leftapillarimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              {/* front left fender  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_frontleftfender"
                    className="form-label btntextSize text-black"
                  >
                    Front Left Fender
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_frontleftfender"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.frontLeftFender}
                      onChange={(e) => {
                        updateCarPart("frontLeftFender", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setFrontLeftFenderDefectBtn(true);
                        } else {
                          setFrontLeftFenderDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {frontLeftFenderDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step3.frontLeftFender === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("frontleftfender_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_frontleftfenderimage &&
                      images.iac_frontleftfenderimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_frontleftfenderimage);
                            setImageId("iac_frontleftfenderimage");
                            getImages("iac_frontleftfenderimage");
                          }}
                        >
                          {images.iac_frontleftfenderimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_frontleftfenderimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_frontleftfenderimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_frontleftfenderimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_frontleftfenderimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_frontleftfenderimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_roof"
                    className="form-label btntextSize text-black"
                  >
                    Roof
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_roof"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.roof}
                      onChange={(e) => {
                        updateCarPart("roof", e.target.value);
                        if (e.target.value === "Not Ok") {
                          setRoofDefectBtn(true);
                        } else {
                          setRoofDefectBtn(false);
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>

                    {roofDefectBtn && (
                      <Button
                        onClick={() => {
                          if (step3.roof === "Not Ok") {
                            setOpenCarSkeleton(true);
                            setPartId("roof_defect");
                          }
                        }}
                        className="color-white text-capitalize bg-primary my-2 p-2 mb-2"
                        fullWidth
                      >
                        Select Defected Location
                      </Button>
                    )}
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_roofimage &&
                      images.iac_roofimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_roofimage);
                            setImageId("iac_roofimage");
                            getImages("iac_roofimage");
                          }}
                        >
                          {images.iac_roofimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_roofimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_roofimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_roofimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_roofimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_roofimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              {/* sun roof glasses  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_sunroofglasses"
                    className="form-label btntextSize text-black"
                  >
                    Sun Roof Glass
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_sunroofglasses"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.sunRoofGlass}
                      onChange={(e) =>
                        updateCarPart("sunRoofGlass", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Ok">Ok</option>
                      <option value="Scratches">Scratches</option>
                      <option value="Crack">Crack</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_runroofglassesimage &&
                      images.iac_runroofglassesimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_runroofglassesimage);
                            setImageId("iac_runroofglassesimage");
                            getImages("iac_runroofglassesimage");
                          }}
                        >
                          {images.iac_runroofglassesimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_runroofglassesimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_runroofglassesimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_runroofglassesimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_runroofglassesimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_runroofglassesimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              {/* ladder frame  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_ladderframe"
                    className="form-label btntextSize text-black"
                  >
                    Ladder Frame
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_ladderframe"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.ladderFrame}
                      onChange={(e) =>
                        updateCarPart("ladderFrame", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Non-Accidented">Non-Accidented</option>
                      <option value="Accidented">Accidented</option>
                    </select>

                    <br />
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_ladderframeimage &&
                      images.iac_ladderframeimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_ladderframeimage);
                            setImageId("iac_ladderframeimage");
                            getImages("iac_ladderframeimage");
                          }}
                        >
                          {images.iac_ladderframeimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_ladderframeimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_ladderframeimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_ladderframeimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_ladderframeimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_ladderframeimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              {/* starter option  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_starteroperation"
                    className="form-label btntextSize text-black"
                  >
                    Starter Operation
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_starteroperation"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.starterOperation}
                      onChange={(e) =>
                        updateCarPart("starterOperation", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_starteroperationimage &&
                      images.iac_starteroperationimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.iac_starteroperationimage
                            );
                            setImageId("iac_starteroperationimage");
                            getImages("iac_starteroperationimage");
                          }}
                        >
                          {images.iac_starteroperationimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_starteroperationimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_starteroperationimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_starteroperationimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_starteroperationimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_starteroperationimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              {/* cold start  */}
              <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_coldstart"
                    className="form-label btntextSize text-black"
                  >
                    Cold Start
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_coldstart"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={step3.coldStart}
                      onChange={(e) =>
                        updateCarPart("coldStart", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_coldstartimage &&
                      images.iac_coldstartimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_coldstartimage);
                            setImageId("iac_coldstartimage");
                            getImages("iac_coldstartimage");
                          }}
                        >
                          {images.iac_coldstartimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    {!getImageByValue("iac_coldstartimage") && (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Button
                          variant="text"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-camera"></i> Camera
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_coldstartimage"
                            accept="image/**"
                            capture="user"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_coldstartimage")
                            }
                          />
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                        >
                          <span className="text-capitalize btntextSize">
                            <i className="fa-solid fa-image"></i> Gallery
                          </span>
                          <input
                            className="form-control visually-hidden"
                            id="iac_coldstartimage"
                            accept=".jpg,.jpeg,.png"
                            type="file"
                            onChange={(e) =>
                              handleImageChange(e, "iac_coldstartimage")
                            }
                          />
                        </Button>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              {/*roof  */}

              {/* left foot board  */}

              {/* <div className="col-lg-4 col-xl-3 mb-3">
                <div>
                  <label
                    for="iac_turnclock"
                    className="form-label btntextSize text-black"
                  >
                    Turn Lock
                  </label>
                  <div className="input-group">
                    <select
                      name="iac_turnclock"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Ok with Issues">Ok with Issues</option>
                      <option value="Not Working">Not Working</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.iac_turnlockimage &&
                      images.iac_turnlockimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.iac_turnlockimage);
                            setImageId("iac_turnlockimage");
                          }}
                        >
                          {images.iac_turnlockimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <Button
                      variant="text"
                      component="label"
                      className="color-white LegendIcon p-2"
                    >
                      <span className="me-2 text-capitalize btntextSize">
                        Add Image
                      </span>{" "}
                      <i className="fa-solid fa-plus"></i>
                      <input
                        name="iac_turnlockimage"
                        className="form-control visually-hidden"
                        id="iac_turnlockimage"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "iac_turnlockimage")
                        }
                      />
                    </Button>
                  </span>
                </div>
              </div> */}

              <div className="col-12 mt-3">
                <label
                  for="iac_comment"
                  className="form-label btntextSize text-black"
                >
                  Comment (Optional)
                </label>

                <Form.Control
                  as="textarea"
                  name="iac_comment"
                  placeholder="Leave a comment here"
                  style={{ height: "130px" }}
                  className="btntextSize"
                />
              </div>

              <div className="text-center pt-2 pb-4">
                <Button
                  type="submit"
                  disabled={pending === true ? true : false}
                  className=" px-5 bgSecondary color-white"
                  variant="contained"
                >
                  Next
                </Button>
              </div>
            </div>
          </form>
          {isModalOpen && (
            <ImageModal
              image={modalImage}
              onClose={handleModalClose}
              open={previewOpen}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Step7;
