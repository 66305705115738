import * as React from "react";
import "../../Styles/style.css";
import { NavLink } from "react-router-dom";

export default function OrdersNavigation({pendingOrders}) {
  return (
    <>
      <div className="myAds_options">
        <ul>
          <li>
            <NavLink to="/inspection-orders" activeClassName="BtnActive">
              <button className="btn">Pending Orders ({pendingOrders}) </button>
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="#" activeClassName="BtnActive">
              <button className="btn">Completed Orders (0)</button>
            </NavLink>
          </li> */}
        </ul>
      </div>
    </>
  );
}
