import * as React from "react";
import "../Styles/style.css";
import { NavLink } from "react-router-dom";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { AuthContext } from "../../context/AuthContext";

export default function MyadsNavigation() {
  const { user } = React.useContext(AuthContext);

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <>
      <div className="myAds_options">
        <div className=" text-end d-none">
          <button onClick={toggleDrawer("right", true)}>
            <i className="fa-solid fa-bars-staggered mb-3"></i>
          </button>
          <div>
            <React.Fragment key="right">
              {/* <SwipeableDrawer
                anchor="right"
                open={state.right}
                onClose={toggleDrawer("right", false)}
                onOpen={toggleDrawer("right", true)}
              >
                <div>
                  This is the content for the right drawer.
                </div>
              </SwipeableDrawer> */}
            </React.Fragment>
          </div>
        </div>
        <ul>
         
          {user?.role_id !== 5 ? (
            <>
              <li>
                <NavLink to="/my-orders" activeClassName="BtnActive">
                  <button className="btn">My Pending Orders</button>
                </NavLink>
              </li>
              <li>
                <NavLink to="/My-ads" activeClassName="BtnActive">
                  <button className="btn">active ads</button>
                </NavLink>
              </li>
            
              <li>
                <NavLink to="/pending-ads" activeClassName="BtnActive">
                  <button className="btn">pending ads</button>
                </NavLink>
              </li>
              <li>
                <NavLink to="/rejected-ads" activeClassName="BtnActive">
                  <button className="btn">Rejected ads</button>
                </NavLink>
              </li>
              <li>
                <NavLink to="/my-saved-ads" activeClassName="BtnActive">
                  <button className="btn">Saved ads</button>
                </NavLink>
              </li>
              <li>
                <NavLink to="/payment-history" activeClassName="BtnActive">
                  <button className="btn">Payment History</button>
                </NavLink>
              </li>
            </>
          ) :
          null}
         
          {user?.role_id === 1 || user?.role_id === 5 ? (
            <div className="d-lg-block d-flex justify-content-center">
              <li>
                <NavLink to="/inspection-history" activeClassName="BtnActive">
                  <button className="btn p-2">Inspection Reports</button>
                </NavLink>
              </li>
              
              <li>
                <NavLink to="/inspection-draft" activeClassName="BtnActive">
                  <button className="btn p-2">Inspection Draft </button>
                </NavLink>
              </li>

              <li>
                <NavLink to="/offline-draft" activeClassName="BtnActive">
                  <button className="btn p-2">Offline Draft </button>
                </NavLink>
              </li>

              <li>
                <NavLink to="/my-orders" activeClassName="BtnActive">
                  <button className="btn p-2">My Pending Orders</button>
                </NavLink>
              </li>

<h6 className="pt-3 pb-1 fw-bold d-md-block d-none ">
  Bidding Inspections
</h6>

<li>
                <NavLink to="/bidding-pending-orders" activeClassName="BtnActive">
                  <button className="btn p-2">Bidding Pending Orders</button>
                </NavLink>
              </li>
              <li>
                <NavLink to="/bidding-inspection-draft" activeClassName="BtnActive">
                  <button className="btn p-2">Bidding Inspection Draft </button>
                </NavLink>
              </li>
              <li>
                <NavLink to="/bidding-inspection-reports" activeClassName="BtnActive">
                  <button className="btn p-2">Bidding Inspection Reports</button>
                </NavLink>
              </li>
              
            </div>
          ) : null}
         
        </ul>
      </div>
    </>
  );
}
