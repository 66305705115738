import React from "react";
import Inspection from "../components/vehicleInspection/index.jsx";

export default function VehicleInspection() {
  return (
    <>
      <Inspection />
    </>
  );
}
