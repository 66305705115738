//  const baseUrl = 'http://192.168.18.244:8080';

// const baseUrl = "http://146.71.76.22/famewheelsbackend";
const baseUrl = "https://bacend.famewheels.com"; // old server
// const baseUrl = "http://192.168.18.80:8000"; // new server
// export const baseUrl2 = "103.65.248.139:5001"; // new server

// export const baseStepsUrl = "http://146.71.76.22/famewheelsbackend";

export default baseUrl;
