import  React, {useEffect} from 'react';
import MyOrders from '../components/vehicleInspection/user/myOrders.jsx';
import { AuthContext } from '../context/AuthContext.js';
import { useHistory } from 'react-router-dom';

export default function UserInspectionOrders() {

    const { user } = React.useContext(AuthContext);
    const history = useHistory()

    useEffect(() => {
        document.title = 'My Orders | FameWheels';
        if (!user) {
            history.push("/")
          }
      }, []);
    return (
        <>


            <MyOrders />
        </>
    );
}