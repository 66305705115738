import React, {useEffect} from 'react';
import ProfileSettings from '../components/profile/settings.jsx';
import { AuthContext } from '../context/AuthContext.js';
import { useHistory } from 'react-router-dom';

export default function MyProfile() {
    const { user } = React.useContext(AuthContext);
    const history = useHistory()
    useEffect(() => {
        document.title = 'My Profile | FameWheels';
        if (!user) {
            history.push("/")
          }
      }, []);
    return (
        <>


            <ProfileSettings />
        </>
    );
}