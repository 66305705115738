import React from "react";
import { CircularProgress } from "@mui/material";

const CircularRangeSlider = ({
  minValue,
  maxValue,
  defaultValue,
  ProgressHeading,
  size,
  thickness,
}) => {
  // const [value, setValue] = React.useState(defaultValue);

  return (
    <div className=" text-center d-flex flex-column justify-content-start h-100 mb-5">
      <h5 className="report-customer-info mb-2">{ProgressHeading}</h5>
      <div className=" position-relative">
        <CircularProgress
          variant="determinate"
          value={defaultValue}
          size={size}
          thickness={thickness}
          style={{ borderRadius: "50%", color: "#b80505" }} // Make it circular by setting the border radius to 50%
        />
        <h4 className="report-customer-info fw-600 progresValuePercent">
          {defaultValue}%
        </h4>
      </div>
    </div>
  );
};

export default CircularRangeSlider;
