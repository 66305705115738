import React, { useRef, useEffect, useState } from "react";
import "../../Styles/style.css";
import Button from "@mui/material/Button";
import ImageModal from "../imagePreviewModal";
import axios from "axios";
import LoadingModal from "../../modals/loading-modal";
import ImageIcon from "@mui/icons-material/Image";
import { Alert, Box, Modal, Snackbar } from "@mui/material";
import { Form } from "react-bootstrap";
import baseUrl, { baseStepsUrl } from "../../../api/ipconfig";
import InnerLoader from "../../loader/innerLoader";
import { openDB } from "idb";
import imageCompression from "browser-image-compression";

const Step6 = ({
  onSuccess,
  previouse,
  user,
  userData,
  vehicleState,
  setVehicleState,
}) => {
  const token = localStorage.getItem("token");

  const formRef = useRef(null);
  const btnRef = useRef(null);

  const [pending, setPending] = useState(false);
  const [pendingData, setPendingData] = useState("");
  const [errOpen, setErrOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [imageLoading, setImageLoading] = useState(false);

  const [images, setImages] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalImage, setModalImage] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSubmittingFields, setIsSubmittingFields] = React.useState(false);
  const [stepImages, setStepImages] = useState(null);

  const [frontRightTyreBrand, setFrontRightTyreBrand] = useState(null);
  const [frontLeftTyreBrand, setFrontLeftTyreBrand] = useState(null);
  const [rearRightTyreBrand, setRearRightTyreBrand] = useState(null);
  const [rearLeftTyreBrand, setRearLeftTyreBrand] = useState(null);

  const [frontRightTyreBrandType, setFrontRightTyreBrandType] = useState(null);
  const [frontLeftTyreBrandType, setFrontLeftTyreBrandType] = useState(null);
  const [rearRightTyreBrandType, setRearRightTyreBrandType] = useState(null);
  const [rearLeftTyreBrandType, setRearLeftTyreBrandType] = useState(null);

  const [openImageGallery, setOpenImageGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [imageID, setImageId] = useState(null);
  const [isSycc, setIsSync] = useState(null);
  const [frontLeftBrakePad, setFrontLeftBrakePad] = useState(null);

  const extractTokenFromUrl = (url, paramName) => {
    const urlSearchParams = new URLSearchParams(url);
    return urlSearchParams.get(paramName);
  };

  const url = window.location.search;

  const StepToken = extractTokenFromUrl(url, "token");

  const syncable = extractTokenFromUrl(url, "sync");

  useEffect(() => {
    setTimeout(() => {
      setIsSync(syncable);
    }, 1500);
  }, [syncable]);

  const handleDeleteImage = async (ind, id) => {
    try {
      const db = await openDB("my-database", 1);

      // Open a transaction to access the object store
      const tx = db.transaction("images", "readwrite");
      const store = tx.objectStore("images");

      // Delete the image with the specified id
      await store.delete(id);

      const updatedGalleryImages = [...galleryImages];
      updatedGalleryImages.splice(ind, 1);

      setGalleryImages(updatedGalleryImages);

      // After deletion, update the local images
      await getLocalImages();
    } catch (error) {
      //console.log("delete image error", error);
      setErrOpen(true);
      setError("Couldn't delete image");
    } finally {
      await getLocalImages();
    }
  };

  useEffect(() => {
    if (galleryImages.length === 0) {
      setOpenImageGallery(false);
    }
  }, [galleryImages.length]);

  const postImages = async () => {
    if (stepImages?.length > 0) {
      for (const image of stepImages) {
        try {
          setIsSubmitting(true);
          const uploadEndpoint = `${baseUrl}/saveinspectionimage`;
          const formData = new FormData();

          // Append common form data fields
          formData.append("iinitial_token", StepToken);
          formData.append("inspectionstep_id", 2);

          // Append each image to formData
          formData.append(`file[]`, image.image);
          formData.append(`image_name`, image.name); // Assuming name is an array

          //console.log("FormData:", formData.get("image_name"));

          const response = await axios.post(uploadEndpoint, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });

          //console.log("API Response:", response.data);

          await handleDeleteImage(image.id, image.id);
        } catch (error) {
          console.log(error);
        } finally {
          setIsSubmitting(false);
        }
      }
    }
  };

  const handleImageGallery = (images) => {
    setOpenImageGallery(true);
    // setGalleryImages(images);
  };

  useEffect(() => {
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const formData = new FormData();

    const fetchDraftPending = async () => {
      formData.append("iinitial_token", StepToken);
      formData.append("inspectionstep_id", 4);

      try {
        const response = await axios.post(
          `${baseUrl}/inspectionstepdetails`,
          formData,
          {
            // params: {
            //   iinitial_token: StepToken,
            //   inspectionstep_id: 3,
            // },
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        //console.log(response?.data?.initial, "draft step interior");
        const formData2 = response?.data?.initial;
        populateFormWithResponse(formData2);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDraftPending();
  }, [token]);

  const populateFormWithResponse = (formData) => {
    if (!formData || typeof formData !== "object") {
      console.error("Invalid formData:", formData);
      return;
    }

    const formElements = formRef.current.elements;

    Object.keys(formData).forEach((fieldName) => {
      const field = formElements[fieldName];

      if (field) {
        field.value = formData[fieldName];
      }
    });
  };

  const getLocalImages = async () => {
    try {
      setImageLoading(true);
      const db = await openDB("my-database", 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains("images")) {
            db.createObjectStore("images", {
              keyPath: "id",
              autoIncrement: true,
            });
          }
        },
      });

      if (!db) {
        console.error("IndexedDB database not found.");
        return;
      }

      const tx = db.transaction("images", "readonly");
      const store = tx.objectStore("images");
      const imageData = await store.getAll();
      await tx.done;

      const filteredImagesData = imageData.filter(
        (image) => image.token === StepToken
      );

      const updatedImages = await filteredImagesData.reduce(
        async (accPromise, image) => {
          const acc = await accPromise;

          if (!acc[image.name]) {
            acc[image.name] = [];
          }

          const base64Image = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(image.image);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });

          acc[image.name].push({ ...image, image: base64Image });
          return acc;
        },
        Promise.resolve({})
      );

      setImages((prevState) => ({
        ...updatedImages,
      }));

      // ******************************* do not remove this comment **********************************

      // //console.log("Filtered images data:", filteredImagesData);

      const imagesOfStep = filteredImagesData.filter(
        (images) => images.inspection_step === 4
      );

      setStepImages(imagesOfStep);
    } catch (error) {
      console.error("Error retrieving images:", error);
      setError(error);
    } finally {
      setImageLoading(false);
    }
  };

  useEffect(() => {
    getLocalImages();
  }, []);

  useEffect(() => {
    if (StepToken) {
      const formData = localStorage.getItem(`${StepToken}_fourth`);
      if (formData) {
        const newData = JSON.parse(formData);

        setFrontLeftBrakePad(newData?.itest_frontlefttyrepad);

        //console.log("newData === >>>> ", newData);
        setTimeout(() => {
          populateFormWithResponse(newData);
        }, 0);
      } else {
        return;
      }
    }
  }, [populateFormWithResponse]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const form = formRef.current;
      if (form) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formRef]);

  const getImages = async (fieldName) => {
    setGalleryImages(images[fieldName]);
  };

  const handleImageChange = async (e, fieldName) => {
    const files = Array.from(e.target.files);

    // Open the IndexedDB database
    const db = await openDB("my-database", 1, {
      upgrade(db) {
        // Check if the object store exists before creating it
        if (!db.objectStoreNames.contains("images")) {
          // Create an object store for storing images
          db.createObjectStore("images", {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      },
    });

    // Iterate over each file
    for (const file of files) {
      //console.log("Original file:", file);

      const options = {
        maxSizeMB: 0.06,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(file, options);
      //console.log("Compressed file:", compressedFile);

      const compressedImageFile = new File([compressedFile], file.name, {
        type: file.type,
      });

      const imageData = {
        name: fieldName,
        image: compressedImageFile,
        token: StepToken,
        inspection_step: 4,
      };

      try {
        // Store the image data in IndexedDB
        const tx = db.transaction("images", "readwrite");
        const store = tx.objectStore("images");
        await store.add(imageData);
        await tx.done;
        //console.log("Image uploaded to IndexedDB successfully");
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }

    // Retrieve the stored images from IndexedDB
    await getLocalImages();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  const previewOpen = (image) => {
    setIsModalOpen(true);
    setModalImage(image);
  };

  const onSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      const formId = extractTokenFromUrl(url, "form_id");

      const CreateUser = async () => {
        // const imageFile = dataURLtoBlob(images.ibody_rediatorcoreimage);

        const formData = new FormData(formRef.current);

        // Convert formData to JSON object
        const formDataObject = {};
        formData.forEach((value, key) => {
          formDataObject[key] = value;
        });

        // Serialize form data to JSON string
        const serializedFormData = JSON.stringify(formDataObject);

        const inspectionDraftArray = JSON.parse(
          localStorage.getItem("inspection_draft")
        );

        inspectionDraftArray.forEach((obj) => {
          // Check if the token matches the one you want to overwrite
          if (obj.token === StepToken) {
            obj.step = "4";
            obj.token = StepToken;
          }
        });

        //console.log("inspectionDraftArray === ?>>> ",inspectionDraftArray);

        localStorage.setItem(
          "inspection_draft",
          JSON.stringify(inspectionDraftArray)
        );

        // Store serialized form data in localStorage

        localStorage.setItem(`${StepToken}_fourth`, serializedFormData);

        if (formRef.current) {
          const params = {
            data: new FormData(formRef.current),
          };
          params.data.append("userId", userData?.id);
          params.data.append(
            "iinitial_id",
            formId ? formId : previouse?.iinitial_id
          );
          params.data.append("iinitial_token", StepToken);
        }
        onSuccess();
      };
      if (formRef.current) {
        btnRef.current?.setLoader(true);
        try {
          setPending(true);
          setPendingData("Completing Step 2");
          await CreateUser();
        } catch (e) {
          console.error(e);
          // Handle error if needed
        } finally {
          setPending(false);
          setIsSubmitting(false);
        }
      }
    },
    [onSuccess, previouse?.iinitial_token, token, user?.id, images, StepToken]
  );

  const handleSaveFields = async () => {
    setIsSubmitting(true);
    setIsSubmittingFields(true);
    const url = window.location.search;
    const StepToken = extractTokenFromUrl(url, "token");
    const formId = extractTokenFromUrl(url, "form_id");

    const CreateUser = async () => {
      if (formRef.current) {
        const params = {
          data: new FormData(formRef.current),
        };
        params.data.append("user_id", userData?.id);
        params.data.append(
          "iinitial_id",
          formId ? formId : previouse?.iinitial_id
        );
        params.data.append("iinitial_token", StepToken);

        Object.entries(images).forEach(([fieldName, file]) => {
          params.data.append(fieldName, file);
        });
        try {
          const response = await axios.post(
            `${baseUrl}/savetestdriveinspection`,
            params?.data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response?.data) {
            setSuccessOpen(true);
            setSuccess("Step 4 Completed");

            setTimeout(() => {
              onSuccess();
            }, 500);
          }
          // localStorage.removeItem(`${StepToken}_fourth`)
          //console.log("====================================");
          //console.log(response);
          setIsSubmitting(false);
          //console.log("====================================");
          formRef.current.hasUnsavedChanges = false;
        } catch (e) {
          console.error(e);
          setErrOpen(true);
          setError(`Step 4 Submit Failed!`);
          setIsSubmitting(false);
          return;
        } finally {
          btnRef.current?.setLoader(false);
        }
      }
    };

    if (formRef.current) {
      btnRef.current?.setLoader(true);
      try {
        setIsSubmitting(true);
        setIsSubmittingFields(true);
        setPending(true);
        setPendingData("Completing Step 4");
        await CreateUser();
      } catch (e) {
        console.error(e);
        // Handle error if needed
      } finally {
        setPending(false);
        setIsSubmitting(false);
        setIsSubmittingFields(true);
      }
    }
  };

  const handleSave = async () => {
    try {
      await postImages();
      await handleSaveFields();
    } catch (error) {
      //console.log(error)
    }
  };

  useEffect(() => {
    if (isSycc == "true") {
      setTimeout(() => {
        handleSave();
      }, 500);
    }
  }, [isSycc]);

  // const updateVehicleState = (key, value) => {
  //   setVehicleState(prevState => ({
  //     ...prevState,
  //     [key]: value
  //   }));
  // };

  const updateVehicleState = (part, value) => {
    setVehicleState((prevState) => {
      const updatedStep4 = {
        ...prevState,
        [part]: value,
      };
      localStorage.setItem(`${StepToken}_fourth`, JSON.stringify(updatedStep4));
      return updatedStep4;
    });
  };

  return (
    <>
      <LoadingModal
        open={isSubmitting}
        onClose={() => setIsSubmitting(false)}
      />

      <Snackbar
        open={errOpen}
        autoHideDuration={4000}
        onClose={() => setErrOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccessOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>

      <Snackbar
        open={pending}
        onClose={() => setPending(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setPending(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          {pendingData}
        </Alert>
      </Snackbar>

      <Modal
        open={openImageGallery}
        onClose={() => setOpenImageGallery(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        {imageLoading ? (
          <>
            <InnerLoader />
          </>
        ) : (
          <Box className="md-modal position-relative p-3 p-md-4 d-flex flex-wrap gap-3 justify-content-around align-items-center">
            {galleryImages &&
              galleryImages?.map((img, ind) => (
                <div className="row">
                  <div
                    key={ind}
                    className="d-flex position-relative border rounded border-secondary-subtle p-2 col-lg-6 col-12 galleryImage"
                  >
                    <img
                      src={`${img.image}`}
                      alt={img.name}
                      className=" object-fit-contain w-100"
                    />
                    <button
                      className="btn btn-danger px-2 py-1 rounded-circle position-absolute"
                      style={{ right: -12, top: -5 }}
                      onClick={() => handleDeleteImage(ind, img.id)}
                    >
                      X
                    </button>
                  </div>
                </div>
              ))}
          </Box>
        )}
      </Modal>

      <div className="container my-5">
        <div className="postInform p-5">
          {/* <div className="text-center text-capatalize pb-5">
            <h4>Tyre</h4>
          </div> */}
          <form ref={formRef} onSubmit={onSubmit} className="postAdForm">
            <div className="row">
              <div className="col-lg-4 col-xl-3 mb-3">
                <label
                  for="itest_tyresize"
                  className="form-label btntextSize text-black"
                >
                  Tyre Size
                </label>
                <input
                  name="itest_tyresize"
                  type="text"
                  className="form-control btntextSize"
                  id="post-title"
                  aria-describedby="titleHelp"
                  placeholder="e.g 192/16/R80"
                  required
                  value={vehicleState.tyreSize}
                  onChange={(e) =>
                    updateVehicleState("tyreSize", e.target.value)
                  }
                />

                <span
                  className="d-flex justify-content-between align-items-center"
                  id="basic-addon2"
                >
                  <span>
                    {images?.itest_tyresizeimage &&
                    images.itest_tyresizeimage.length > 0 ? (
                      <span
                        className="color-secondary fw-600 p-0"
                        onClick={() => {
                          handleImageGallery(images.itest_tyresizeimage);
                          setImageId("itest_tyresizeimage");
                          getImages("itest_tyresizeimage");
                        }}
                      >
                        {images.itest_tyresizeimage.length} photos{" "}
                        <ImageIcon color="error" />
                      </span>
                    ) : (
                      <span className="color-secondary fw-600 p-0">
                        0 photos <ImageIcon color="error" />
                      </span>
                    )}
                  </span>

                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Button
                      variant="text"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-camera"></i> Camera
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="itest_tyresizeimage"
                        accept="image/**"
                        capture="user"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "itest_tyresizeimage")
                        }
                      />
                    </Button>

                    <Button
                      variant="contained"
                      component="label"
                      className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                    >
                      <span className="text-capitalize btntextSize">
                        <i className="fa-solid fa-image"></i> Gallery
                      </span>
                      <input
                        className="form-control visually-hidden"
                        id="itest_tyresizeimage"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        onChange={(e) =>
                          handleImageChange(e, "itest_tyresizeimage")
                        }
                      />
                    </Button>
                  </div>
                </span>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_frontrighttyre"
                    className="form-label btntextSize text-black"
                  >
                    Front Right Tyre
                  </label>
                  <div className="input-group">
                    <input
                      name="itest_frontrighttyre"
                      type="number"
                      className="form-control btntextSize"
                      id="post-title"
                      aria-describedby="titleHelp"
                      placeholder="Front Right Tyre"
                      required
                      min={0}
                      max={100}
                      value={vehicleState.frontRightTyre}
                      onChange={(e) =>{
                        const value = e.target.value;
                        if (value <= 100) {
                        updateVehicleState("frontRightTyre", value)
                        }
                      }}
                    />
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_frontrighttyreimage &&
                      images.itest_frontrighttyreimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_frontrighttyreimage
                            );
                            setImageId("itest_frontrighttyreimage");
                            getImages("itest_frontrighttyreimage");
                          }}
                        >
                          {images.itest_frontrighttyreimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontrighttyreimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontrighttyreimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontrighttyreimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontrighttyreimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_frontrighttyrebrand"
                    className="form-label btntextSize text-black"
                  >
                    Front Right Tyre Brand
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_frontrighttyrebrand"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      value={vehicleState.frontRightTyreBrand}
                      onChange={(e) => {
                        setFrontRightTyreBrand(e.target.value);
                        setFrontRightTyreBrandType(e.target.value);
                        updateVehicleState(
                          "frontRightTyreBrand",
                          e.target.value
                        );
                      }}
                      required
                    >
                      <option value="">Select</option>
                      <option value="General">General</option>
                      <option value="Yokohama">Yokohama</option>
                      <option value="Michelin">Michelin</option>
                      <option value="Euro Tycoon">Euro Tycoon</option>
                      <option value="Euro Star">Euro Star</option>

                      <option value="Bg Luxo Plus">Bg Luxo Plus</option>
                      <option value="Bg Trako Plus">Bg Trako Plus</option>
                      <option value="Bridgestone">Bridgestone</option>
                      <option value="Dunlop">Dunlop</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  {frontRightTyreBrandType === "Other" && (
                    <>
                      <div className="input-group mt-2">
                        <input
                          name="itest_frontrighttyrebrand"
                          type="text"
                          className="form-control btntextSize"
                          id="post-title"
                          aria-describedby="titleHelp"
                          placeholder="Front Right Tyre Brand"
                          value={frontRightTyreBrand}
                          onChange={(e) =>
                            setFrontRightTyreBrand(e.target.value)
                          }
                        />
                      </div>
                    </>
                  )}

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_frontrighttyrebrandimage &&
                      images.itest_frontrighttyrebrandimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_frontrighttyrebrandimage
                            );
                            setImageId("itest_frontrighttyrebrandimage");
                            getImages("itest_frontrighttyrebrandimage");
                          }}
                        >
                          {images.itest_frontrighttyrebrandimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontrighttyrebrandimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "itest_frontrighttyrebrandimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontrighttyrebrandimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "itest_frontrighttyrebrandimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_frontrightbrakepad"
                    className="form-label btntextSize text-black"
                  >
                    Front Right Brake Pad
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_frontrightbrakepad"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.frontRightBrakePad}
                      onChange={(e) =>
                        updateVehicleState("frontRightBrakePad", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="More than 50%">More than 50%</option>
                      <option value="Less than 50%">Less than 50%</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_frontrightbrakepadimage &&
                      images.itest_frontrightbrakepadimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_frontrightbrakepadimage
                            );
                            setImageId("itest_frontrightbrakepadimage");
                            getImages("itest_frontrightbrakepadimage");
                          }}
                        >
                          {images.itest_frontrightbrakepadimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontrightbrakepadimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "itest_frontrightbrakepadimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontrightbrakepadimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "itest_frontrightbrakepadimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_frontrightdisc"
                    className="form-label btntextSize text-black"
                  >
                    Front Right Disc
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_frontrightdisc"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.frontRightDisc}
                      onChange={(e) =>
                        updateVehicleState("frontRightDisc", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Smooth">Smooth</option>
                      <option value="Slight Linings">Slight Linings</option>
                      <option value="Linings">Linings</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_frontrightdiscimages &&
                      images.itest_frontrightdiscimages.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_frontrightdiscimages
                            );
                            setImageId("itest_frontrightdiscimages");
                            getImages("itest_frontrightdiscimages");
                          }}
                        >
                          {images.itest_frontrightdiscimages.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontrightdiscimages"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontrightdiscimages")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontrightdiscimages"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontrightdiscimages")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_righttieroadend"
                    className="form-label btntextSize text-black"
                  >
                    Right Tie Rod End
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_righttieroadend"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.rightTieRodEnd}
                      onChange={(e) =>
                        updateVehicleState("rightTieRodEnd", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Boot Damage or Play">
                        Boot Damage or Play
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_righttierodendimage &&
                      images.itest_righttierodendimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_righttierodendimage
                            );
                            setImageId("itest_righttierodendimage");
                            getImages("itest_righttierodendimage");
                          }}
                        >
                          {images.itest_righttierodendimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_righttierodendimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_righttierodendimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_righttierodendimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_righttierodendimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_rightballjoint"
                    className="form-label btntextSize text-black"
                  >
                    Right Ball Joint
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_rightballjoint"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.rightBallJoint}
                      onChange={(e) =>
                        updateVehicleState("rightBallJoint", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Boot Damage or Play">
                        Boot Damage or Play
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_rightballjointimage &&
                      images.itest_rightballjointimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_rightballjointimage
                            );
                            setImageId("itest_rightballjointimage");
                            getImages("itest_rightballjointimage");
                          }}
                        >
                          {images.itest_rightballjointimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rightballjointimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rightballjointimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rightballjointimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rightballjointimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_frontrightboots"
                    className="form-label btntextSize text-black"
                  >
                    Front Right Boots
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_frontrightboots"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.frontRightBoots}
                      onChange={(e) =>
                        updateVehicleState("frontRightBoots", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Damage">Damage</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_frontrightbootsimage &&
                      images.itest_frontrightbootsimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_frontrightbootsimage
                            );
                            setImageId("itest_frontrightbootsimage");
                            getImages("itest_frontrightbootsimage");
                          }}
                        >
                          {images.itest_frontrightbootsimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontrightbootsimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontrightbootsimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontrightbootsimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontrightbootsimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_frontrightbushes"
                    className="form-label btntextSize text-black"
                  >
                    Front Right Bushes
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_frontrightbushes"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.frontRightBushes}
                      onChange={(e) =>
                        updateVehicleState("frontRightBushes", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Damage">Damage</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_fontrightbushesimage &&
                      images.itest_fontrightbushesimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_fontrightbushesimage
                            );
                            setImageId("itest_fontrightbushesimage");
                            getImages("itest_fontrightbushesimage");
                          }}
                        >
                          {images.itest_fontrightbushesimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_fontrightbushesimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_fontrightbushesimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_fontrightbushesimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_fontrightbushesimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_rearzlinks"
                    className="form-label btntextSize text-black"
                  >
                    Right Z Links
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_rearzlinks"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.rightZLinks}
                      onChange={(e) =>
                        updateVehicleState("rightZLinks", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Ok">Ok</option>
                      <option value="Boot Damage or Play">
                        Boot Damage or Play
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_rightzlinksimages &&
                      images.itest_rightzlinksimages.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.itest_rightzlinksimages);
                            setImageId("itest_rightzlinksimages");
                            getImages("itest_rightzlinksimages");
                          }}
                        >
                          {images.itest_rightzlinksimages.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rightzlinksimages"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rightzlinksimages")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rightzlinksimages"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rightzlinksimages")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_frontrightshock"
                    className="form-label btntextSize text-black"
                  >
                    Front Right Shock
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_frontrightshock"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.frontRightShock}
                      onChange={(e) =>
                        updateVehicleState("frontRightShock", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_frontrightshockimages &&
                      images.itest_frontrightshockimages.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_frontrightshockimages
                            );
                            setImageId("itest_frontrightshockimages");
                            getImages("itest_frontrightshockimages");
                          }}
                        >
                          {images.itest_frontrightshockimages.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontrightshockimages"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontrightshockimages")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontrightshockimages"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontrightshockimages")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_rearrighttyre"
                    className="form-label btntextSize text-black"
                  >
                    Rear Right Tyre
                  </label>
                  <div className="input-group">
                    <input
                      name="itest_rearrighttyre"
                      type="number"
                      className="form-control"
                      id="post-title"
                      aria-describedby="titleHelp"
                      placeholder="Front Right Tyre"
                      required
                      min={0}
                      max={100}
                      value={vehicleState.rearRightTyre}
                      onChange={(e) =>{
                        const value = e.target.value;
                        if (value <= 100) {
                          updateVehicleState("rearRightTyre", value)
                        }
                      }}
                    />
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_rearrighttyreimage &&
                      images.itest_rearrighttyreimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.itest_rearrighttyreimage);
                            setImageId("itest_rearrighttyreimage");
                            getImages("itest_rearrighttyreimage");
                          }}
                        >
                          {images.itest_rearrighttyreimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rearrighttyreimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rearrighttyreimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rearrighttyreimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rearrighttyreimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_rearrighttyrebrand"
                    className="form-label btntextSize text-black"
                  >
                    Rear Right Tyre Brand
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_rearrighttyrebrand"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.rearRightTyreBrand}
                      onChange={(e) => {
                        setRearRightTyreBrand(e.target.value);
                        setRearRightTyreBrandType(e.target.value);
                        updateVehicleState(
                          "rearRightTyreBrand",
                          e.target.value
                        );
                      }}
                    >
                      <option value="">Select</option>
                      <option value="General">General</option>
                      <option value="Yokohama">Yokohama</option>
                      <option value="Michelin">Michelin</option>
                      <option value="Euro Tycoon">Euro Tycoon</option>
                      <option value="Euro Star">Euro Star</option>

                      <option value="Bg Luxo Plus">Bg Luxo Plus</option>
                      <option value="Bg Trako Plus">Bg Trako Plus</option>
                      <option value="Bridgestone">Bridgestone</option>
                      <option value="Dunlop">Dunlop</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  {rearRightTyreBrandType === "Other" && (
                    <>
                      <div className="input-group mt-2">
                        <input
                          name="itest_rearrighttyrebrand"
                          type="text"
                          className="form-control btntextSize"
                          id="post-title"
                          aria-describedby="titleHelp"
                          placeholder="Front Right Tyre Brand"
                          value={rearRightTyreBrand}
                          onChange={(e) =>
                            setRearRightTyreBrand(e.target.value)
                          }
                        />
                      </div>
                    </>
                  )}

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_rearrighttyrebrandimage &&
                      images.itest_rearrighttyrebrandimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_rearrighttyrebrandimage
                            );
                            setImageId("itest_rearrighttyrebrandimage");
                            getImages("itest_rearrighttyrebrandimage");
                          }}
                        >
                          {images.itest_rearrighttyrebrandimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rearrighttyrebrandimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "itest_rearrighttyrebrandimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rearrighttyrebrandimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "itest_rearrighttyrebrandimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_rearrightbushes"
                    className="form-label btntextSize text-black"
                  >
                    Rear Right Bushes
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_rearrightbushes"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.rearRightBushes}
                      onChange={(e) =>
                        updateVehicleState("rearRightBushes", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="No Damage Found">No Damage Found</option>
                      <option value="Damaged">Damaged</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_rearrightbushesimages &&
                      images.itest_rearrightbushesimages.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_rearrightbushesimages
                            );
                            setImageId("itest_rearrightbushesimages");
                            getImages("itest_rearrightbushesimages");
                          }}
                        >
                          {images.itest_rearrightbushesimages.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rearrightbushesimages"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rearrightbushesimages")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rearrightbushesimages"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rearrightbushesimages")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_rearrightshock"
                    className="form-label btntextSize text-black"
                  >
                    Rear Right Shock
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_rearrightshock"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.rearRightShock}
                      onChange={(e) =>
                        updateVehicleState("rearRightShock", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_rearrightshockimage &&
                      images.itest_rearrightshockimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_rearrightshockimage
                            );
                            setImageId("itest_rearrightshockimage");
                            getImages("itest_rearrightshockimage");
                          }}
                        >
                          {images.itest_rearrightshockimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rearrightshockimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rearrightshockimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rearrightshockimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rearrightshockimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_rearlefttyre"
                    className="form-label btntextSize text-black"
                  >
                    Rear Left Tyre
                  </label>
                  <div className="input-group">
                    <input
                      name="itest_rearlefttyre"
                      type="number"
                      className="form-control"
                      id="post-title"
                      aria-describedby="titleHelp"
                      placeholder="Front Left Tyre"
                      required
                      min={0}
                      max={100}
                      value={vehicleState.rearLeftTyre}
                      onChange={(e) =>{
                        const value = e.target.value;
                        if (value <= 100) {
                        updateVehicleState("rearLeftTyre", value)
                        }
                      }}
                    />
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_rearlefttyreimage &&
                      images.itest_rearlefttyreimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.itest_rearlefttyreimage);
                            setImageId("itest_rearlefttyreimage");
                            getImages("itest_rearlefttyreimage");
                          }}
                        >
                          {images.itest_rearlefttyreimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rearlefttyreimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rearlefttyreimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rearlefttyreimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rearlefttyreimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_rearlefttyrebrand"
                    className="form-label btntextSize text-black"
                  >
                    Rear Left Tyre Brand
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_rearlefttyrebrand"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.rearLeftTyreBrand}
                      onChange={(e) => {
                        setRearLeftTyreBrand(e.target.value);
                        setRearLeftTyreBrandType(e.target.value);
                        updateVehicleState("rearLeftTyreBrand", e.target.value);
                        updateVehicleState(
                          "rearLeftTyreBrandType",
                          e.target.value
                        );
                      }}
                    >
                      <option value="">Select</option>
                      <option value="General">General</option>
                      <option value="Yokohama">Yokohama</option>
                      <option value="Michelin">Michelin</option>
                      <option value="Euro Tycoon">Euro Tycoon</option>
                      <option value="Euro Star">Euro Star</option>

                      <option value="Bg Luxo Plus">Bg Luxo Plus</option>
                      <option value="Bg Trako Plus">Bg Trako Plus</option>
                      <option value="Bridgestone">Bridgestone</option>
                      <option value="Dunlop">Dunlop</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  {rearLeftTyreBrandType === "Other" && (
                    <>
                      <div className="input-group mt-2">
                        <input
                          name="itest_rearlefttyrebrand"
                          type="text"
                          className="form-control btntextSize"
                          id="post-title"
                          aria-describedby="titleHelp"
                          placeholder="Front Right Tyre Brand"
                          value={rearLeftTyreBrand}
                          onChange={(e) => setRearLeftTyreBrand(e.target.value)}
                        />
                      </div>
                    </>
                  )}

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_rearlefttyrebrandimage &&
                      images.itest_rearlefttyrebrandimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_rearlefttyrebrandimage
                            );
                            setImageId("itest_rearlefttyrebrandimage");
                            getImages("itest_rearlefttyrebrandimage");
                          }}
                        >
                          {images.itest_rearlefttyrebrandimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rearlefttyrebrandimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rearlefttyrebrandimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rearlefttyrebrandimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rearlefttyrebrandimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_rearleftbushes"
                    className="form-label btntextSize text-black"
                  >
                    Rear Left Bushes
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_rearleftbushes"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.rearLeftBushes}
                      onChange={(e) =>
                        updateVehicleState("rearLeftBushes", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="No Damage Found">No Damage Found</option>
                      <option value="Damaged">Damaged</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_rearleftbushesimage &&
                      images.itest_rearleftbushesimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_rearleftbushesimage
                            );
                            setImageId("itest_rearleftbushesimage");
                            getImages("itest_rearleftbushesimage");
                          }}
                        >
                          {images.itest_rearleftbushesimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rearleftbushesimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rearleftbushesimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rearleftbushesimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rearleftbushesimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_rearleftshock"
                    className="form-label btntextSize text-black"
                  >
                    Rear Left Shock
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_rearleftshock"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.rearLeftShock}
                      onChange={(e) =>
                        updateVehicleState("rearLeftShock", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_rearleftshockimage &&
                      images.itest_rearleftshockimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.itest_rearleftshockimage);
                            setImageId("itest_rearleftshockimage");
                            getImages("itest_rearleftshockimage");
                          }}
                        >
                          {images.itest_rearleftshockimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rearleftshockimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rearleftshockimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rearleftshockimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rearleftshockimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_frontlefttyre"
                    className="form-label btntextSize text-black"
                  >
                    Front Left Tyre
                  </label>
                  <div className="input-group">
                    <input
                      name="itest_frontlefttyre"
                      type="number"
                      className="form-control"
                      id="post-title"
                      aria-describedby="titleHelp"
                      placeholder="Front Left Tyre"
                      required
                      min={0}
                      max={100}
                      value={vehicleState.frontLeftTyre}
                      onChange={(e) =>{
                        const value = e.target.value;
                        if (value <= 100) {
                        updateVehicleState("frontLeftTyre", value)
                        }
                      }}
                    />
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_frontlefttyreimage &&
                      images.itest_frontlefttyreimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.itest_frontlefttyreimage);
                            setImageId("itest_frontlefttyreimage");
                            getImages("itest_frontlefttyreimage");
                          }}
                        >
                          {images.itest_frontlefttyreimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontlefttyreimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontlefttyreimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontlefttyreimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontlefttyreimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_frontlefttyrebrand"
                    className="form-label btntextSize text-black"
                  >
                    Front Left Tyre Brand
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_frontlefttyrebrand"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.frontLeftTyreBrandType}
                      onChange={(e) => {
                        setFrontLeftTyreBrand(e.target.value);
                        setFrontLeftTyreBrandType(e.target.value);
                        updateVehicleState(
                          "frontLeftTyreBrandType",
                          e.target.value
                        );
                      }}
                    >
                      <option value="">Select</option>
                      <option value="General">General</option>
                      <option value="Yokohama">Yokohama</option>
                      <option value="Michelin">Michelin</option>
                      <option value="Euro Tycoon">Euro Tycoon</option>
                      <option value="Euro Star">Euro Star</option>
                      <option value="Bg Luxo Plus">Bg Luxo Plus</option>
                      <option value="Bg Trako Plus">Bg Trako Plus</option>
                      <option value="Bridgestone">Bridgestone</option>
                      <option value="Dunlop">Dunlop</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  {frontLeftTyreBrandType === "Other" && (
                    <>
                      <div className="input-group mt-2">
                        <input
                          name="itest_frontlefttyrebrand"
                          type="text"
                          className="form-control btntextSize"
                          id="post-title"
                          aria-describedby="titleHelp"
                          placeholder="Front Right Tyre Brand"
                          value={frontLeftTyreBrand}
                          onChange={(e) =>
                            setFrontLeftTyreBrand(e.target.value)
                          }
                        />
                      </div>
                    </>
                  )}

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_frontlefttyrebrandimage &&
                      images.itest_frontlefttyrebrandimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_frontlefttyrebrandimage
                            );
                            setImageId("itest_frontlefttyrebrandimage");
                            getImages("itest_frontlefttyrebrandimage");
                          }}
                        >
                          {images.itest_frontlefttyrebrandimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontlefttyrebrandimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "itest_frontlefttyrebrandimage"
                            )
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontlefttyrebrandimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(
                              e,
                              "itest_frontlefttyrebrandimage"
                            )
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_frontlefttyrepad"
                    className="form-label btntextSize text-black"
                  >
                    Front Left Brake Pad
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_frontlefttyrepad"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={frontLeftBrakePad}
                      onChange={(e) => setFrontLeftBrakePad(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="More than 50%">More than 50%</option>
                      <option value="Less than 50%">Less than 50%</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_frontlefttyrepad &&
                      images.itest_frontlefttyrepad.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.itest_frontlefttyrepad);
                            setImageId("itest_frontlefttyrepad");
                            getImages("itest_frontlefttyrepad");
                          }}
                        >
                          {images.itest_frontlefttyrepad.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontlefttyrepad"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontlefttyrepad")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontlefttyrepad"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontlefttyrepad")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_frontleftdisc"
                    className="form-label btntextSize text-black"
                  >
                    Front Left Disc
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_frontleftdisc"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.frontLeftDisc}
                      onChange={(e) =>
                        updateVehicleState("frontLeftDisc", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Smooth">Smooth</option>
                      <option value="Slight Linings">Slight Linings</option>
                      <option value="Linings">Linings</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_frontleftdiscimage &&
                      images.itest_frontleftdiscimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.itest_frontleftdiscimage);
                            setImageId("itest_frontleftdiscimage");
                            getImages("itest_frontleftdiscimage");
                          }}
                        >
                          {images.itest_frontleftdiscimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontleftdiscimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontleftdiscimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontleftdiscimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontleftdiscimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_lefttierodend"
                    className="form-label btntextSize text-black"
                  >
                    Left Tie Rod End
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_lefttierodend"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.leftTieRodEnd}
                      onChange={(e) =>
                        updateVehicleState("leftTieRodEnd", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Boot Damaged or Play">
                        Boot Damaged or Play
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_lefttierodendimage &&
                      images.itest_lefttierodendimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.itest_lefttierodendimage);
                            setImageId("itest_lefttierodendimage");
                            getImages("itest_lefttierodendimage");
                          }}
                        >
                          {images.itest_lefttierodendimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_lefttierodendimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_lefttierodendimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_lefttierodendimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_lefttierodendimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_leftballjoint"
                    className="form-label btntextSize text-black"
                  >
                    Left Ball Joint
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_leftballjoint"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.leftBallJoint}
                      onChange={(e) =>
                        updateVehicleState("leftBallJoint", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Boot Damaged or Play">
                        Boot Damaged or Play
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_leftballjointimage &&
                      images.itest_leftballjointimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.itest_leftballjointimage);
                            setImageId("itest_leftballjointimage");
                            getImages("itest_leftballjointimage");
                          }}
                        >
                          {images.itest_leftballjointimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_leftballjointimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_leftballjointimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_leftballjointimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_leftballjointimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_frontleftboots"
                    className="form-label btntextSize text-black"
                  >
                    Front Left Boots
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_frontleftboots"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.frontLeftBoots}
                      onChange={(e) =>
                        updateVehicleState("frontLeftBoots", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Damaged">Damaged</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_frontleftbootsimage &&
                      images.itest_frontleftbootsimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_frontleftbootsimage
                            );
                            setImageId("itest_frontleftbootsimage");
                            getImages("itest_frontleftbootsimage");
                          }}
                        >
                          {images.itest_frontleftbootsimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontleftbootsimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontleftbootsimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontleftbootsimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontleftbootsimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_frontleftbushes"
                    className="form-label btntextSize text-black"
                  >
                    Front Left Bushes
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_frontleftbushes"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.frontLeftBushes}
                      onChange={(e) =>
                        updateVehicleState("frontLeftBushes", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Damaged">Damaged</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_frontleftbushesimage &&
                      images.itest_frontleftbushesimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_frontleftbushesimage
                            );
                            setImageId("itest_frontleftbushesimage");
                            getImages("itest_frontleftbushesimage");
                          }}
                        >
                          {images.itest_frontleftbushesimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontleftbushesimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontleftbushesimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontleftbushesimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontleftbushesimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_leftzlinks"
                    className="form-label btntextSize text-black"
                  >
                    Left Z Links
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_leftzlinks"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.leftZLinks}
                      onChange={(e) =>
                        updateVehicleState("leftZLinks", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Ok">Ok</option>
                      <option value="Boot Damaged or Play">
                        Boot Damaged or Play
                      </option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_leftzlinksimage &&
                      images.itest_leftzlinksimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.itest_leftzlinksimage);
                            setImageId("itest_leftzlinksimage");
                            getImages("itest_leftzlinksimage");
                          }}
                        >
                          {images.itest_leftzlinksimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_leftzlinksimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_leftzlinksimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_leftzlinksimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_leftzlinksimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_frontleftshock"
                    className="form-label btntextSize text-black"
                  >
                    Front Left Shock
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_frontleftshock"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.frontLeftShock}
                      onChange={(e) =>
                        updateVehicleState("frontLeftShock", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Ok">Ok</option>
                      <option value="Not Ok">Not Ok</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_frontleftshockimage &&
                      images.itest_frontleftshockimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(
                              images.itest_frontleftshockimage
                            );
                            setImageId("itest_frontleftshockimage");
                            getImages("itest_frontleftshockimage");
                          }}
                        >
                          {images.itest_frontleftshockimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontleftshockimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontleftshockimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_frontleftshockimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_frontleftshockimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_rims"
                    className="form-label btntextSize text-black"
                  >
                    Rims
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_rims"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.rims}
                      onChange={(e) =>
                        updateVehicleState("rims", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Alloy">Alloy</option>
                      <option value="Normal">Normal</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_rimsimage &&
                      images.itest_rimsimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.itest_rimsimage);
                            setImageId("itest_rimsimage");
                            getImages("itest_rimsimage");
                          }}
                        >
                          {images.itest_rimsimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rimsimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rimsimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_rimsimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_rimsimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 mb-3">
                <div>
                  <label
                    for="itest_wheelcaps"
                    className="form-label btntextSize text-black"
                  >
                    Wheel Caps
                  </label>
                  <div className="input-group">
                    <select
                      name="itest_wheelcaps"
                      className="form-select btntextSize"
                      id="vehicleCategory"
                      aria-label="Default select example"
                      required
                      value={vehicleState.wheelCaps}
                      onChange={(e) =>
                        updateVehicleState("wheelCaps", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Present">Present</option>
                      <option value="Broken">Broken</option>
                      <option value="Not Present">Not Present</option>
                    </select>
                  </div>

                  <span
                    className="d-flex justify-content-between align-items-center"
                    id="basic-addon2"
                  >
                    <span>
                      {images?.itest_wheelcapsimage &&
                      images.itest_wheelcapsimage.length > 0 ? (
                        <span
                          className="color-secondary fw-600 p-0"
                          onClick={() => {
                            handleImageGallery(images.itest_wheelcapsimage);
                            setImageId("itest_wheelcapsimage");
                            getImages("itest_wheelcapsimage");
                          }}
                        >
                          {images.itest_wheelcapsimage.length} photos{" "}
                          <ImageIcon color="error" />
                        </span>
                      ) : (
                        <span className="color-secondary fw-600 p-0">
                          0 photos <ImageIcon color="error" />
                        </span>
                      )}
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <Button
                        variant="text"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none bgSecondary"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-camera"></i> Camera
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_wheelcapsimage"
                          accept="image/**"
                          capture="user"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_wheelcapsimage")
                          }
                        />
                      </Button>

                      <Button
                        variant="contained"
                        component="label"
                        className="px-3 py-1 rounded-pill color-white rounded mt-2 shadow-none"
                      >
                        <span className="text-capitalize btntextSize">
                          <i className="fa-solid fa-image"></i> Gallery
                        </span>
                        <input
                          className="form-control visually-hidden"
                          id="itest_wheelcapsimage"
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          onChange={(e) =>
                            handleImageChange(e, "itest_wheelcapsimage")
                          }
                        />
                      </Button>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-3">
                <label
                  for="itest_comment"
                  className="form-label btntextSize text-black"
                >
                  Comment (Optional)
                </label>

                <Form.Control
                  as="textarea"
                  name="itest_comment"
                  placeholder="Leave a comment here"
                  style={{ height: "130px" }}
                  className="btntextSize"
                />
              </div>

              <div className="d-flex justify-content-center gap-2 align-items-center text-center pt-2 pb-4">
                <Button
                  disabled={pending === true ? true : false}
                  type="submit"
                  className="px-5 bgSecondary color-white"
                  variant="contained"
                >
                  Next
                </Button>

                {isSycc && (
                  <Button
                    type="button"
                    disabled={pending === true || isSubmitting === true}
                    className="px-5 color-white"
                    variant="contained"
                    onClick={handleSave}
                  >
                    save
                  </Button>
                )}
              </div>
            </div>
          </form>
          {isModalOpen && (
            <ImageModal
              image={modalImage}
              onClose={handleModalClose}
              open={previewOpen}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Step6;
