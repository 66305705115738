import * as React from "react";
import { useState } from "react";
import "../Styles/style.css";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import SuccessTick from "../../images/success-tick.gif";
import baseUrl from "../../api/ipconfig.js";
import Cookies from "js-cookie";
import { useAuthContext } from "../../hooks/useAuthContext";
import setAuthToken from "../../auth/auth";
import { useHistory } from "react-router-dom";

const xorEncrypt = (data, key) => {
  const encryptedData = data.split("").map((char, i) => {
    const keyChar = key.charCodeAt(i % key.length);
    const encryptedChar = char.charCodeAt(0) ^ keyChar;
    return String.fromCharCode(encryptedChar);
  });
  return encryptedData.join("");
};

export default function ResetPassword({ email }) {
  const { dispatch } = useAuthContext();
  const history = useHistory();

  const [newPassword, setNewPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [successLogin, setSuccessLogin] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [res, setRes] = useState("");
  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);

    if (newPassword !== value) {
      setPasswordMatchError("Passwords did not match");
    } else {
      setPasswordMatchError("");
    }
  };

  const handleOtp = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.get(`${baseUrl}/fame/changePassword`, {
        params: {
          email: email,
          newPassword: newPassword,
        },
      });

      setIsSubmitting(false);
      setSuccessLogin(true);
      if (response?.status === 200) {
        try {
          const response = await axios.post(`${baseUrl}/fame/authenticate`, {
            userName: email,
            password: newPassword,
          });
          localStorage.setItem("token", response.data.token);
          setAuthToken(response.data.token);
        //console.log(response, "Login successfully");

          const userResponse = await axios.get(`${baseUrl}/getUser`, {
            headers: {
              Authorization: `Bearer ${response.data.token}`,
            },
          });
          const encryptedUserData = xorEncrypt(
            JSON.stringify(userResponse.data),
            "noman"
          );
          Cookies.set("%2515M%25", encryptedUserData, {
            expires: 7,
          });

          await dispatch({ type: "LOGIN", payload: userResponse.data });
          history.push("/");
        } catch (error) {
          //console.log(error, "Login Unsuccessfully");
          setRes(error?.response?.data?.message);
        }
      }
    } catch (error) {
      console.error("Error on password change", error);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="container my-5" style={{ height: "360px" }}>
        <div className="postInform w-75 m-auto py-5 ">
          <div className="text-center">
            <h4>Set New Password</h4>
          </div>
          <form onSubmit={handleOtp} className="mt-3">
            <div className="row w-75 m-auto ">
              <div className="col-md-12 login_inputStyle mb-3">
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa-solid fa-lock"></i>
                  </span>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    onChange={(e) => setNewPassword(e.target.value)}
                    value={newPassword}
                    required
                  />
                </div>
              </div>
              <div className={`col-md-12 login_inputStyle mb-0`}>
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa-solid fa-lock"></i>
                  </span>
                  <input
                    type="password"
                    className="form-control"
                    id="memberPassword"
                    name=""
                    placeholder="Confirm Password"
                    required
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                </div>
              </div>

              {passwordMatchError && (
                <p className="error-message p-0 m-0">{passwordMatchError}</p>
              )}
              <div className="text-center pt-4">
                <p className="warning">
                  {res === "User not found with email : NONE_PROVIDED"
                    ? "User not found with email"
                    : ""}
                </p>
                <button
                  type="submit"
                  className="btn fw-secondary"
                  style={{ width: "130px" }}
                >
                  {isSubmitting ? (
                    <>
                      <CircularProgress size="16px" sx={{ color: "#fff" }} />
                    </>
                  ) : successLogin ? (
                    <img
                      className="successAnim"
                      src={SuccessTick}
                      alt="success"
                      srcSet="success"
                    />
                  ) : (
                    <>
                      Continue
                      <i className="fa-solid fa-chevron-right ms-3"></i>
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
