import * as React from "react";
import "../../Styles/style.css";
import OrdersNavigation from "./inspectionorder-navigation";
import Header from "../../header";

export default function Completedorders() {
  return (
    <>
    <Header />
    <div className="MyAds_main py-5">
        <div className="container MyAds_body">
        <h3 className="pb-5 text-center fw-title">
          Inspection <span>Orders </span>
        </h3>
          <div className="row pb-5">
            <div className="col-lg-2  p-0">
              <OrdersNavigation />
            </div>
            <div className="col-lg-10 ">
              <div className="ms-3 adsSection">
            completed orders

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
