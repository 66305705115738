import React, { useEffect, useState } from "react";

import "../Styles/style.css";
import { Stepper, Step, StepLabel, Button } from "@mui/material";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import Step4 from "./steps/step4";
import Step5 from "./steps/step5";
import Step6 from "./steps/step6";
import Step7 from "./steps/step7";
import Step8 from "./steps/step8";
import Step9 from "./steps/step9";
import { AuthContext } from "../../context/AuthContext";

const steps = [
  "Step 1",
  "Step 2",
  "Step 3",
  "Step 4",
  "Step 5",
  "Step 6",
  "Step 7",
  "Step 8",
  // "Skeleton",
];

const InspectorFormBody = () => {
  const { user } = React.useContext(AuthContext);

  const [activeStep, setActiveStep] = useState(0);
  const [reponse, setResponse] = useState("");

  // step 1 states

  const [mileage, setMileage] = useState(null);
  const [engineNo, setEngineNo] = useState(null);
  const [chassisNo, setChassisNo] = useState(null);
  const [registeredNo, setRegisteredNo] = useState(null);
  const [confirmRegisteredNo, setConfirmRegisteredNo] = useState(null);
  const [driveType, setDriveType] = useState(null);
  const [engineType, setEngineType] = useState(null);
  const [cngInstall, setCngInstall] = useState(null);
  const [color, setColor] = useState(null);
  const [engineCapacity, setEngineCapacity] = useState(null);
  const [transmissionType, setTransmissionType] = useState(null);
  const [registeredCity, setRegisteredCity] = useState(null);
  const [registeredYear, setRegisteredYear] = useState(null);

  // step 2 states

  const [step2, seStep2] = useState({
    rightMirror: null,
    rightAPillar: null,
    leftHeadlightCondition: null,
    rightHeadlightCondition: null,
    radiatorCoreSupport: null,
    leftFrontRail: null,
    rightFrontRail: null,
    cowlPanelFirewall: null,
    frontSubFrame: null,
    leftStrutPowerApon: null,
    rightStrutPowerApon: null,
    frontWindShield: null,
    terminalsCondition: null,
    voltage: null,
    hoses: null,
    bonnetButton: null,
    trunkButton: null,
    fuelCapButton: null,
    engineOilLevel: null,
    wiringHarness: null,
    beltsFan: null,
    beltsAc: null,
    radiator: null,
    frontRightBPillar: null,
    rearRightCPillar: null,
    rearRightDPillar: null,
    bonnet: null,
    frontBumper: null,
    frontRightFender: null,
    frontRightDoor: null,
    rearRightDoor: null,
    rightFootBand: null,
    rearRightFender: null,
    rightTailLightCondition: null
  });


  const [step3, setStep3] = useState({
    leftFootBand: null,
    rearLeftDoor: null,
    rearLeftFender: null,
    frontLeftDoor: null,
    frontLeftFender: null,
    roof: null,
    trunk: null,
    bootFloor: null,
    bootLockPillar: null,
    floorDeck: null,
    differentialOilLeakage: null,
    spareTyre: null,
    tools: null,
    jack: null,
    punctureRepairKit: null,
    trunkLock: null,
    leftTailLightCondition: null,
    rearBumper: null,
    rearSubFrame: null,
    rearWindshieldCondition: null,
    leftDPillar: null,
    leftCPillar: null,
    leftBPillar: null,
    leftAPillar: null,
    leftMirror: null,
    sunRoofGlass: null,
    ladderFrame: null,
    starterOperation: null,
    coldStart: null
  });


  // step 4 states

  const [vehicleState, setVehicleState] = useState({
    tyreSize: null,
    frontRightTyre: null,
    frontRightTyreBrand: null,
    frontLeftTyreBrand: null,
    rearRightTyreBrand: null,
    rearLeftTyre: null,
    rearLeftTyreBrand: null,
    frontRightBrakePad: null,
    frontRightDisc: null,
    rightTieRodEnd: null,
    rightBallJoint: null,
    frontRightBoots: null,
    frontRightBushes: null,
    rightZLinks: null,
    frontRightShock: null,
    rearRightTyre: null,
    rearRightBushes: null,
    rearRightShock: null,
    frontLeftTyre: null,
    rearLeftBushes: null,
    rearLeftShock: null,
    frontRightTyreBrandType: null,
    frontLeftTyreBrandType: null,
    rearRightTyreBrandType: null,
    rearLeftTyreBrandType: null,
    frontLeftBrakePad: null,
    frontLeftDisc: null,
    leftTieRodEnd: null,
    leftBallJoint: null,
    frontLeftBoots: null,
    frontLeftBushes: null,
    leftZLinks: null,
    frontLeftShock: null,
    rims: null,
    wheelCaps: null,
  });


// step 5 states 

  const [step5, setStep5] = useState({
    engineOilLeakage: null,
    transmissionOilLeakage: null,
    transferOilLeakage: null,
    coolantOilLeakage: null,
    brakeOilLeakage: null,
    poerSteeringOilLeakage: null,
    engineBlowManualCheck: null,
    engineNoise: null,
    engineVibration: null,
    exhaustSound : null,
    suctionFan : null,
    charging : null,
    alternatorOperation : null,
    engineMounts : null,
    pulleyAdjuster : null,
    acFitted : null,
  });


  
// step 6 states 

const [step6, setStep6] = useState({
  acOperational: null,
  rightseatadjusterrecliner: null,
  rightseatadjusterleartrack: null,
  rightfrontseatelectricfunction: null,
  frontrightpowerwindow: null,
  windowsafetylock: null,
  frontrightseatposhish: null,
  frontrightdoorwindow: null,
  rightseatbelt:null,
  rearviewmirrordimmer:null,
  horn:null,
  lightsleverswitch:null,
  wiperswasherlever:null,
  windscreenwiper:null,
  steeringwheelcondition:null,
  steeringwheelbuttons:null,
  autolockbutton:null,
  interiorlightings:null,
  dashcontrolsac:null,
  dashcontrolsdefog:null,
  dashcontrolshazzardlights:null,
  dashcontrolsparkingbutton:null,
  parking_handbrake:null,
  dashcontrolothers:null,
  audioVideo:null,
  rearViewCamera:null,
  sunRoofControl:null,
  rightHeadlightWorking:null,
  leftHeadlightWorking:null,
  fogLightsWorking:null,
  rightTaillightWorking:null,
  leftTaillightWorking:null,
  gauges:null,
  computerCheckup:null,
  poerSteeringWarningLight:null,
  keyFobLowLight:null,
  batteryWarningLight:null,
  airBagWarningLight:null,
  tempratureWarningLight:null,
  absWarningLight:null,
  oilPressureWarningLight:null,
  blower:null,
  heating:null,
  cooling:null,
  rearRightPowerManualLever:null,
  rearSeatPoshish:null,
  rearRightDoorWindow:null,
  rearLeftPowerManualLever:null,
  rearLeftDoorWindow:null,
  rearSeatBelts:null,
  leftSeatAdjusterRecliner:null,
  leftSeatAdjusterLearTrack:null,
  leftFrontSeatElectricFunction:null,
  frontLeftPowerManualLever:null,
  frontLeftSeatPoshish:null,
  frontLeftDoorWindow:null,
  leftSeatBelt:null,
  gloveBox:null,
  dashboardCondition:null,
  floorMat:null,
  roofPoshish:null,
});

// step 8 states 

const [step8, setStep8] = useState({
  testDrive:null,
  acOperationalDriving:null,
  heaterOperation:null,
  enginePick:null,
  clutchOperation:null,
  gearShiftingManual:null,
  gearShiftingAutomatic:null,
  shifting4x4:null,
  driveShaftNoise:null,
  frontPropellerShaft:null,
  rearPropellerShaft:null,
  differentialNoise:null,
  brakePedalOperation:null,
  absOperation:null,
  steeringOperation:null,
  steeringWheelPlay:null,
  steeringWheelAlignment:null,
  frontSuspension:null,
  rearSuspension:null,
  speedoMeter:null,
  testDriveBy:null,
})



  useEffect(() => {
    const extractTokenFromUrl = (url, paramName) => {
      const urlSearchParams = new URLSearchParams(url);
      return urlSearchParams.get(paramName);
    };

    const url = window.location.search;
    const inspectorStep = extractTokenFromUrl(url, "inspector_Step");
    const token = extractTokenFromUrl(url, "token");

    if (inspectorStep !== null && !isNaN(inspectorStep)) {
      setActiveStep(Number(inspectorStep));
    }
  }, []);

  const userData = localStorage.getItem("data");
  const userId = JSON.parse(userData);

  const handleResponse = (apiData) => {
    setResponse(apiData);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };





  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Step1
            mileage={mileage}
            setMileage={setMileage}
            engineNo={engineNo}
            setEngineNo={setEngineNo}
            chassisNo={chassisNo}
            setChassisNo={setChassisNo}
            registeredNo={registeredNo}
            setRegisteredNo={setRegisteredNo}
            confirmRegisteredNo={confirmRegisteredNo}
            setConfirmRegisteredNo={setConfirmRegisteredNo}
            driveType={driveType}
            setDriveType={setDriveType}
            engineType={engineType}
            setEngineType={setEngineType}
            cngInstall={cngInstall}
            setCngInstall={setCngInstall}
            color={color}
            setColor={setColor}
            engineCapacity={engineCapacity}
            setEngineCapacity={setEngineCapacity}
            transmissionType={transmissionType}
            setTransmissionType={setTransmissionType}
            registeredCity={registeredCity}
            setRegisteredCity={setRegisteredCity}
            registeredYear={registeredYear}
            setRegisteredYear={setRegisteredYear}
            onSuccess={handleNext}
            APIResponse={handleResponse}
            user={userId}
            userData={user}
          />
        );
      case 1:
        return (
          <Step9
          step2={step2} 
          setStep2={seStep2}
          
            onSuccess={handleNext}
            previouse={reponse}
            user={userId}
            userData={user}
          />
        );
      case 2:
        return (
          <Step7
          step3={step3} 
          setStep3={setStep3} 
          
            onSuccess={handleNext}
            previouse={reponse}
            user={userId}
            userData={user}
          />
        );
      case 3:
        return (
          <Step6
vehicleState={vehicleState}
setVehicleState={setVehicleState}
            onSuccess={handleNext}
            previouse={reponse}
            user={userId}
            userData={user}
          />
        );
      case 4:
        return (
          <Step3
          step5={step5}
setStep5={setStep5}
            onSuccess={handleNext}
            previouse={reponse}
            user={userId}
            userData={user}
          />
        );
      case 5:
        return (
          <Step5
          step6={step6}
          setStep6={setStep6}
            onSuccess={handleNext}
            previouse={reponse}
            user={userId}
            userData={user}
          />
        );
      case 6:
        return (
          <Step2
            onSuccess={handleNext}
            previouse={reponse}
            user={userId}
            userData={user}
          />
        );
      case 7:
        return (
          <Step4
          step8={step8}
          setStep8={setStep8}

            onSuccess={handleNext}
            previouse={reponse}
            user={userId}
            userData={user}
          />
        );
      // case 8:
      // return (
      //   <Step9 onSuccess={handleNext} previouse={reponse} user={userId} userData={user} />
      // );
      default:
        return <div>Unknown stepIndex</div>;
    }
  };

  return (
    <>
      <div className="container mt-3">
        <div className="text-center post-intro pb-4">
          <h3 className=" fw-700">
            {" "}
            <span className="text-danger"> Offline </span> Inspector Form
          </h3>
        </div>
        <Stepper
          className=" flex-wrap inspectorStepper"
          activeStep={activeStep}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="d-flex justify-content-start align-items-center mt-4">
          {activeStep > 0 && (
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className="me-3 color-secondary fw-600 fs-5 text-capitalize "
            >
              <i class="fa-solid fa-angle-left me-2"></i>
              Back
            </Button>
          )}
          {/* <Button variant="contained" color="primary" onClick={handleNext}>
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button> */}
        </div>
        <div className="inspectorForm_root mt-3">
          {activeStep === steps.length ? (
            <div>
              <p>All steps completed</p>
            </div>
          ) : (
            <div>
              <div>{getStepContent(activeStep)}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InspectorFormBody;
