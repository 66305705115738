// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB8MGQI99IZyXoSKaiZzXRcxupKHL69ous",
  authDomain: "famewheelsapp.firebaseapp.com",
  projectId: "famewheelsapp",
  storageBucket: "famewheelsapp.appspot.com",
  messagingSenderId: "887324875467",
  appId: "1:887324875467:web:c8fae3f5edd1dc4609994e",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };
