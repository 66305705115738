import React, { useEffect, useState } from "react";
import "../Styles/style.css";
import axios from "axios";
import logo from "../../images/fame-wheels-logo.png";
import CircularRangeSlider from "./circular-progress";
import CarWrap from "../../images/car-wrap.png";
import Button from "@mui/material/Button";
import Brakes from "../../images/brake.png";
import Heater from "../../images/ac-heater.png";
import Engine from "../../images/engine.png";
import TestDrive from "../../images/test-drive.png";
import Suspension from "../../images/suspension.png";
import Tyre from "../../images/tyre.png";
import Electrical from "../../images/electrician.png";
// import Exterior from "../../images/exterior.png";
import Interior from "../../images/interior.png";
import ImageModal from "../inspector/imagePreviewModal";
import LoadingModal from "../modals/loading-modal";
import baseUrl from "../../api/ipconfig";
import InnerLoader from "../loader/innerLoader";
import { Box, Modal, Switch } from "@mui/material";
import carImage from "../../images/car-image.png";
import { CircularProgress } from "@mui/material";

export default function InspectionReport() {
  const [showDefects, setShowDefects] = useState(false);
  const [checked, setChecked] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [imageURL, setImageURL] = useState();
  const [defectURL, setDefectURL] = useState();
  const [isLoading, setLoading] = useState(false);
  const [defectData, setDefectData] = useState(null);
  const [dataLoad, setDataLoad] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [legends, setLegends] = useState([]);
  const [imagePath, setImagePath] = useState(null);
  const [openImageGallery, setOpenImageGallery] = useState(false);
  const [images, setImages] = useState({});
  const [imageID, setImageId] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [cities, setCities] = useState([]);
  const [years, setYears] = useState([]);
  const [isImages, setIsImages] = useState({});
  const [lastImages, setLastImages] = useState({});
  const [isWeb, setIsWeb] = useState(window.innerWidth > 992);

  const token = localStorage.getItem("token");

  useEffect(() => {
    // Update isLargeScreen when the window is resized
    const handleResize = () => {
      setIsWeb(window.innerWidth > 992);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const extractTokenFromUrl = (url) => {
    const parts = url.split("inspection-report?");
    return parts.length > 1 ? parts[parts.length - 1] : 0;
  };
  const url = window.location.href;
  const inspectionToken = extractTokenFromUrl(url);

  const fetchAllImages = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`${baseUrl}/inspectionfieldvalidation`, {
        params: {
          iinitial_token: inspectionToken,
        },
      });

      //console.log("all image", response?.data?.data);
      setIsImages(response?.data?.data);
      setLastImages(response?.data?.gallerydata);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getPercentage = async () => {
    try {
      const response = await axios.get(`${baseUrl}/percent`, {
        params: {
          iinitial_token: inspectionToken,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      //console.log("percentage", response?.data?.percentage);
      setPercentage(response?.data?.percentage);
    } catch (error) {
      console.error("Error fetching inspection report:", error);
    }
  };

  const fetchInspectionReport = async () => {
    try {
      const response = await axios.get(`${baseUrl}/inspectionreport`, {
        params: {
          iinitial_token: inspectionToken,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setReportData(response.data);
      setImageURL(response?.data?.gallerypath);
      setDefectURL(response?.data?.defectpath);
    } catch (error) {
      console.error("Error fetching inspection report:", error);
    }
  };

  const fetchCity = async () => {
    try {
      const response = await axios.get(`${baseUrl}/cities`);

      // //console.log("All Cities", response.data);
      setCities(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchYears = async () => {
    try {
      const response = await axios.get(`${baseUrl}/getModelYear`);

      // //console.log("All Cities", response.data);
      //console.log("years ======== > ", response.data);
      setYears(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getLegends = async () => {
    try {
      const response = await axios.get(`${baseUrl}/inspectiondefectreport`, {
        params: {
          iinitial_token: inspectionToken,
        },
      });

      setLegends(response?.data?.data);
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    fetchAllImages();
    fetchInspectionReport();
    getPercentage();
    fetchCity();
    fetchYears();
    getLegends();
  }, []);

  const handlePrint = () => {
    window.print();
  };
  // ${reportData?.bodypath}
  const handleImage = async (fieldName) => {
    // const url = window.location.search;
    // const StepToken = extractTokenFromUrl(url, "token");
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/getinspectionimage`, {
        params: {
          iinitial_token: inspectionToken,
          image_name: fieldName,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      //console.log(response?.data);

      setImages((prevImages) => ({
        ...prevImages,
        [fieldName]: [...response.data?.images],
      }));

      setImagePath(response?.data?.imagepath);
      handleImageGallery(response?.data?.images);
      //console.log("image-path === ", imagePath);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const LoginOpen = () => {
    setIsOpen(true);
  };

  const LoginClose = () => {
    setIsOpen(false);
  };

  const handleImageGallery = (images) => {
    setOpenImageGallery(true);
    setGalleryImages(images);
  };

  // const legendDivs = document.querySelectorAll(".inspectionLegends > div");
  // const inspectionLegendImgs = document.querySelectorAll(
  //   ".inspection_legendImg"
  // );

  // legendDivs?.forEach((legendDiv, index) => {
  //   legendDiv.addEventListener("click", () => {
  //     // Toggle the visibility of the corresponding image div
  //     inspectionLegendImgs.forEach((img, i) => {
  //       if (i === index) {
  //         img.style.display = "block";
  //       } else {
  //         img.style.display = "none";
  //       }
  //     });
  //   });
  // });

  //console.log(imageURL);

  const handleDefecrGet = async (place, defect) => {
    const url = window.location.href;
    const inspectionToken = extractTokenFromUrl(url);
    //console.log(place, defect);
    setDefectData(null);
    setDataLoad(true);
    try {
      const response = await axios.get(`${baseUrl}/defectimagebydefect`, {
        params: {
          iinitial_token: inspectionToken,
          defect_place: place,
          defect_type: defect,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      //console.log(response?.data);
      setDefectData(response?.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setDataLoad(false);
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <LoadingModal open={isLoading} onClose={() => setLoading(false)} />

      <Modal
        open={openImageGallery}
        onClose={() => setOpenImageGallery(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Fw-popups"
      >
        <Box className="md-modal position-relative p-3 p-md-4 d-flex flex-wrap gap-3 justify-content-around align-items-center">
          {galleryImages?.map((img, ind) => (
            <div className="row">
              <div
                key={ind}
                className="d-flex position-relative border rounded border-secondary-subtle p-2 col-lg-6 col-12 galleryImage"
              >
                <img
                  src={`${imagePath}/${img.inspectionimage_name}`}
                  alt={img.inspectionimage_name}
                  className=" object-fit-contain w-100"
                />
              </div>
            </div>
          ))}
        </Box>
      </Modal>

      {isWeb ? (
        <div className="container webView mb-5">
          <div>
            <div className="row inspectionMain align-items-center mt-5">
              <div className="col-lg-12 text-end border-bottom pb-3 mb-4 print-hidden">
                <Button
                  onClick={handlePrint}
                  variant="contained"
                  className=" bgSecondary text-white"
                >
                  <i className="fa-solid fa-print me-2"></i>
                  Print Report
                </Button>
              </div>

              <div className="col-lg-9 col-7">
                <h4 className="fw-800">
                  CAR INSPECTION <span className="color-secondary">REPORT</span>
                </h4>
              </div>

              <div className="col-lg-3 col-5 text-center">
                <img className="inspectReportLogo mb-2" src={logo} alt="Icon" />
              </div>
              <h4 className="text-danger text-center mb-2 mt-4 fw-bold">
                {reportData?.cartitle?.make_name}{" "}
                {reportData?.cartitle?.model_name}{" "}
                {reportData?.cartitle?.year_name}
              </h4>
            </div>
            <div className="row my-5 pb-3 align-items-center">
              <div className="col-md-6 col-12 ">
                {lastImages?.iex_front_view_image !== 0 ? (
                  lastImages?.iex_front_view_image?.length > 0 && (
                    <img
                      className="inspectReportCar mb-2"
                      src={`${imageURL}/iex_front_view_image/${lastImages?.iex_front_view_image[0]?.inspectionimage_name}`}
                      alt={"car-image"}
                    />
                  )
                ) : (
                  <img
                    className="inspectReportCar mb-2"
                    src={carImage}
                    alt={"car-image"}
                  />
                )}
              </div>

              <div className="col-md-6 col-12 text-center ratingBar">
                <h4 className="progress-heading fs-3 color-secondary">
                  {reportData?.initial?.iinitial_vehicletitle}
                </h4>
                <div className=" position-relative">
                  <CircularProgress
                    variant="determinate"
                    value={percentage?.allpercentage * 10}
                    maxValue={100}
                    size={150}
                    thickness={2}
                    style={{ borderRadius: "50%", color: "#b80505" }} // Make it circular by setting the border radius to 50%
                  />
                  <h4 className="report-customer-info fw-600 progresValuePercent">
                    {percentage?.allpercentage} / 10
                  </h4>
                </div>
                <h4 className="progress-heading pt-2 fs-5">Overall Rating</h4>
              </div>
            </div>
          </div>
          <div>
            {/* <div className="row">
              <div className="col-lg-12 my-4">
                <h4 className="fw-700">Customer Information</h4>
              </div>
              <div className="col-lg-12 col-6 d-flex pb-3">
                <div className="col-lg-2">
                  <h5 className="report-customer-info">Seller Name : </h5>{" "}
                </div>
                <div className="col-lg-10">
                  <div className="borderbottom ms-3">
                    <h5 className="report-customer-info">
                      {reportData?.initial?.iinitial_sellername}
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-6 d-flex pb-3">
                <div className="col-lg-2">
                  <h5 className="report-customer-info ">Seller Phone : </h5>{" "}
                </div>
                <div className="col-lg-10">
                  <div className="borderbottom ms-3">
                    <h5 className="report-customer-info">
                      {reportData?.initial?.iinitial_sellerno}
                    </h5>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row mt-4">
              <div className="col-lg-12 my-4">
                <h4 className="fw-700">Car Detail</h4>
              </div>
              <div className="row ">
                <div className="col-lg-4 col-4">
                  <h5 className="report-customer-info">engine type</h5>{" "}
                  <p className="CustomerData">
                    {reportData?.initial?.iinitial_enginetype}
                  </p>
                </div>
                <div className="col-lg-2 col-2">
                  <h5 className="report-customer-info">mileage</h5>{" "}
                  <p className="CustomerData">
                    {reportData?.initial?.iinitial_mileage}
                  </p>
                </div>
                <div className="col-lg-3 col-3">
                  <h5 className="report-customer-info">inspection date</h5>{" "}
                  <p className="CustomerData">
                    {reportData?.initial?.iinitial_date}
                  </p>
                </div>
                <div className="col-lg-3 col-3">
                  <h5 className="report-customer-info">engine no</h5>{" "}
                  <p className="CustomerData">
                    {reportData?.initial?.iinitial_engineno}
                  </p>
                </div>
              </div>
              <div className="row ">
                <div className="col-lg-4 col-4">
                  <h5 className="report-customer-info">transmission type</h5>{" "}
                  <p className="CustomerData">
                    {reportData?.initial?.iinitial_transmission}
                  </p>
                </div>
                <div className="col-lg-2 col-2">
                  <h5 className="report-customer-info">CNG Install</h5>{" "}
                  <p className="CustomerData">
                    {reportData?.initial?.iinitial_cnginstall}{" "}
                  </p>
                </div>
                <div className="col-lg-3 col-3">
                  <h5 className="report-customer-info">engine capacity</h5>{" "}
                  <p className="CustomerData">
                    {reportData?.initial?.iinitial_enginecapacity}
                  </p>
                </div>
                <div className="col-lg-3 col-3">
                  <h5 className="report-customer-info">chassis no</h5>{" "}
                  <p className="CustomerData">
                    {reportData?.initial?.iinitial_chassisno}
                  </p>
                </div>
              </div>
              <div className="row pb-5">
                <div className="col-lg-4 col-4">
                  <h5 className="report-customer-info">registered city</h5>{" "}
                  <p className="CustomerData">karachi</p>
                </div>
                <div className="col-lg-2 col-2">
                  <h5 className="report-customer-info">drive type</h5>{" "}
                  <p className="CustomerData">
                    {reportData?.initial?.iinitial_drivetype}{" "}
                  </p>
                </div>
                <div className="col-lg-3 col-3">
                  <h5 className="report-customer-info">registration no</h5>{" "}
                  <p className="CustomerData">
                    {reportData?.initial?.iinitial_registeredno}
                  </p>
                </div>
                <div className="col-lg-3 col-3">
                  <h5 className="report-customer-info">Colour</h5>{" "}
                  <p className="CustomerData">
                    {reportData?.initial?.iinitial_color}
                  </p>
                </div>

                {/* <div className="col-lg-3 col-3">
                  <h5 className="report-customer-info">Comment</h5>{" "}
                  <p className="CustomerData">
                    {reportData?.initial?.inspector_comment}
                  </p>
                </div> */}
              </div>

              <div className="row mt-5 pt-3">
                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.acheater}
                    ProgressHeading="AC / HEATER"
                    size={100}
                    thickness={2}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.enginetransmission}
                    ProgressHeading="ENGINE / TRANSMISSION / CLUTCH"
                    size={100}
                    thickness={2}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.exteriorbasic}
                    ProgressHeading="EXTERIOR"
                    size={100}
                    thickness={2}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.exteriorvalue?.dfpercent}
                    ProgressHeading="SKELETON"
                    size={100}
                    thickness={2}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.exteriorvalue?.acpercent}
                    ProgressHeading="ACCIDENT CHECKLIST"
                    size={100}
                    thickness={2}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.brakes}
                    ProgressHeading="BRAKES"
                    size={100}
                    thickness={2}
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.suspension}
                    ProgressHeading="SUSPENSION/STEERING"
                    size={100}
                    thickness={2}
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.interior}
                    ProgressHeading="INTERIOR"
                    size={100}
                    thickness={2}
                  />
                </div>

                <div className="col-lg-3 col-3">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.electronics}
                    ProgressHeading="ELECTRICAL & ELECTRONICS"
                    size={100}
                    thickness={2}
                  />
                </div>

                <div className="col-lg-3 col-3">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.tyres}
                    ProgressHeading="TYRES"
                    size={100}
                    thickness={2}
                  />
                </div>
              </div>

              <div className="col-lg-12 my-5">
                <div className="d-flex justify-content-between gap-3 align-items-center">
                  <h4 className="fw-700">Exterior Condition</h4>

                  <div className="d-flex justify-content-center align-items-center gap-3 p-2 rounded border">
                    <p className="p-0 m-0">
                      {checked ? "Hide" : "Show"} Problems
                    </p>
                    <Switch
                      checked={checked}
                      onChange={handleChange}
                      color="error"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>

                  {/* <Button className="bgSecondary color-white" onClick={()=>setShowDefects(!showDefects)}>{showDefects?"Hide":"Show"} Problems</Button> */}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  {checked ? (
                    <div className="inspectionLegends position-relative mb-4">
                      <img
                        className="carWrap img-fluid"
                        src={CarWrap}
                        alt=""
                        srcSet=""
                      />
                      <div
                        onClick={() =>
                          isImages?.ibody_bonnetimage === 1 &&
                          reportData?.bonnet_defect?.length <= 0 &&
                          handleImage(`ibody_bonnetimage`)
                        }
                      >
                        {reportData?.bonnet_defect?.length > 0 &&
                          reportData?.bonnet_defect?.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.ibody_frontbumperimage === 1 &&
                          reportData?.frontBumper_defect?.length <= 0 &&
                          handleImage(`ibody_frontbumperimage`)
                        }
                      >
                        {reportData?.frontBumper_defect?.length > 0 &&
                          reportData?.frontBumper_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.iac_roofimage === 1 &&
                          reportData?.roof_defect?.length <= 0 &&
                          handleImage(`iac_roofimage`)
                        }
                      >
                        {reportData?.roof_defect?.length > 0 &&
                          reportData?.roof_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.iac_trunkimage === 1 &&
                          reportData?.trunk_defect?.length <= 0 &&
                          handleImage(`iac_trunkimage`)
                        }
                      >
                        {reportData?.trunk_defect?.length > 0 &&
                          reportData?.trunk_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.iac_rearbumperimage === 1 &&
                          reportData?.rearbumper_defect?.length <= 0 &&
                          handleImage(`iac_rearbumperimage`)
                        }
                      >
                        {reportData?.rearbumper_defect?.length > 0 &&
                          reportData?.rearbumper_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.iac_frontleftdoorimage === 1 &&
                          reportData?.frontleftdoor_defect?.length <= 0 &&
                          handleImage(`iac_frontleftdoorimage`)
                        }
                      >
                        {reportData?.frontleftdoor_defect?.length > 0 &&
                          reportData?.frontleftdoor_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.iac_rearleftdoorimage === 1 &&
                          reportData?.rearleftdoor_defect?.length <= 0 &&
                          handleImage(`iac_rearleftdoorimage`)
                        }
                      >
                        {reportData?.rearleftdoor_defect?.length > 0 &&
                          reportData?.rearleftdoor_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.iac_frontleftfenderimage === 1 &&
                          reportData?.frontleftfender_defect?.length <= 0 &&
                          handleImage(`iac_frontleftfenderimage`)
                        }
                      >
                        {reportData?.frontleftfender_defect?.length > 0 &&
                          reportData?.frontleftfender_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.ibody_offfrontwingimage === 1 &&
                          reportData?.offfrontwing_defect?.length <= 0 &&
                          handleImage(`ibody_offfrontwingimage`)
                        }
                      >
                        {reportData?.offfrontwing_defect?.length > 0 &&
                          reportData?.offfrontwing_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.ibody_offfrontdoorimage === 1 &&
                          reportData?.offfrontdoor_defect?.length <= 0 &&
                          handleImage(`ibody_offfrontdoorimage`)
                        }
                      >
                        {reportData?.offfrontdoor_defect?.length > 0 &&
                          reportData?.offfrontdoor_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.ibody_offbackdoorimage === 1 &&
                          reportData?.offbackdoor_defect?.length <= 0 &&
                          handleImage(`ibody_offbackdoorimage`)
                        }
                      >
                        {reportData?.offbackdoor_defect?.length > 0 &&
                          reportData?.offbackdoor_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>

                      <div
                        onClick={() =>
                          isImages?.ibody_offbackwingimage === 1 &&
                          reportData?.offbackwing_defect?.length <= 0 &&
                          handleImage(`ibody_offbackwingimage`)
                        }
                      >
                        {reportData?.offbackwing_defect?.length > 0 &&
                          reportData?.offbackwing_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>

                      <div
                        onClick={() =>
                          isImages?.iac_rearleftfenderimage === 1 &&
                          reportData?.rearleftfender_defect?.length <= 0 &&
                          handleImage(`iac_rearleftfenderimage`)
                        }
                      >
                        {reportData?.rearleftfender_defect?.length > 0 &&
                          reportData?.rearleftfender_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>

                      <div
                        onClick={() =>
                          isImages?.ibody_rightfootbandimage === 1 &&
                          reportData?.rightfootboard_defect?.length <= 0 &&
                          handleImage(`ibody_rightfootbandimage`)
                        }
                      >
                        {reportData?.rightfootboard_defect?.length > 0 &&
                          reportData?.rightfootboard_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>

                      <div
                        onClick={() =>
                          isImages?.iac_leftfootboardimage === 1 &&
                          reportData?.leftfootboard_defect?.length <= 0 &&
                          handleImage(`iac_leftfootboardimage`)
                        }
                      >
                        {reportData?.leftfootboard_defect?.length > 0 &&
                          reportData?.leftfootboard_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    <div className="inspectionLegends position-relative mb-4">
                      <img
                        className="carWrap img-fluid"
                        src={CarWrap}
                        alt=""
                        srcSet=""
                      />
                      <div
                        onClick={() =>
                          isImages?.ibody_bonnetimage === 1 &&
                          handleImage(`ibody_bonnetimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.ibody_frontbumperimage === 1 &&
                          handleImage(`ibody_frontbumperimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.iac_roofimage === 1 &&
                          handleImage(`iac_roofimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.iac_trunkimage === 1 &&
                          handleImage(`iac_trunkimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.iac_rearbumperimage === 1 &&
                          handleImage(`iac_rearbumperimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.iac_frontleftdoorimage === 1 &&
                          handleImage(`iac_frontleftdoorimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.iac_rearleftdoorimage === 1 &&
                          handleImage(`iac_rearleftdoorimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.iac_frontleftfenderimage === 1 &&
                          handleImage(`iac_frontleftfenderimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.ibody_offfrontwingimage === 1 &&
                          handleImage(`ibody_offfrontwingimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.ibody_offfrontdoorimage === 1 &&
                          handleImage(`ibody_offfrontdoorimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.ibody_offbackdoorimage === 1 &&
                          handleImage(`ibody_offbackdoorimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.ibody_offbackwingimage === 1 &&
                          handleImage(`ibody_offbackwingimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.iac_rearleftfenderimage === 1 &&
                          handleImage(`iac_rearleftfenderimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.ibody_rightfootbandimage === 1 &&
                          handleImage(`ibody_rightfootbandimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.iac_leftfootboardimage === 1 &&
                          handleImage(`iac_leftfootboardimage`)
                        }
                      ></div>
                    </div>
                  )}
                </div>
                <div className="col-lg-4 print-hidden d-flex justify-content-center align-items-center">
                  {defectData && (
                    <div
                      className=" border-2 border-danger rounded inspectionImg11 p-3"
                      style={{ border: "2px solid #b80505" }}
                    >
                      <img
                        className="img-fluid object-fit-contain shadow"
                        style={{ height: 210 }}
                        src={`${defectURL}${defectData?.defect_place}/${defectData?.defect_type}-${defectData?.defect_value}/${defectData?.defect_image}`}
                        alt={defectData?.defect_image}
                        srcSet=""
                      />

                      {!defectData?.defect_image && (
                        <i className="fs-6 fw-600 text-danger-emphasis">
                          No Image
                        </i>
                      )}

                      <div className="text-center mt-2">
                        <p>{defectData?.defect_type}</p>
                      </div>
                    </div>
                  )}
                  {dataLoad && (
                    <div>
                      <InnerLoader />
                    </div>
                  )}
                </div>
              </div>

              <div className="row mt-5">
                <div
                  className="col-12 pt-3 mb-4"
                  style={{ backgroundColor: "#efefef", borderRadius: "10px" }}
                >
                  <div className="row">
                    {legends &&
                      legends?.map((item, index) => (
                        <div key={item?.id} className="col-md-3 col-12">
                          <p>
                            <strong> {item?.value} </strong>
                            {item?.name}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="row">
              <div className="col-12">
                <div
                  className="accordion reportAccordins"
                  id="accordionExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Body frame accident checklist
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.exteriorvalue?.acpercent}%
                          </h5>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                    >
                      <div className="accordion-body">
                        {reportData?.body?.ibody_rediatorcoresupport !== null &&
                          reportData?.body?.ibody_rediatorcoresupport !== "" &&
                          reportData?.body?.ibody_rediatorcoresupport !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_rediatorcoresupport === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Radiator Core Support
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_radiatorcoresupportimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ibody_radiatorcoresupportimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rediatorcoresupport}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_rrightstruttower !== null &&
                          reportData?.body?.ibody_rrightstruttower !== "" &&
                          reportData?.body?.ibody_rrightstruttower !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_rrightstruttower === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Strut Tower Apon
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_rightstrutpoweraponimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ibody_rightstrutpoweraponimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rrightstruttower}
                                </h5>
                              </div>
                            </div>
                          )}
                        {reportData?.body?.ibody_leftstruttower !== null &&
                          reportData?.body?.ibody_leftstruttower !== "" &&
                          reportData?.body?.ibody_leftstruttower !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_leftstruttower === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Strut Tower Apon
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_leftstrutpoweraponimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ibody_leftstrutpoweraponimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_leftstruttower}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_rightfrontrail !== null &&
                          reportData?.body?.ibody_rightfrontrail !== "" &&
                          reportData?.body?.ibody_rightfrontrail !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_rightfrontrail === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Front Rail
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_rightfrontrailimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_rightfrontrailimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rightfrontrail}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_rleftfrontrail !== null &&
                          reportData?.body?.ibody_rleftfrontrail !== "" &&
                          reportData?.body?.ibody_rleftfrontrail !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_rleftfrontrail === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Front Rail
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_leftfrontrailimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_leftfrontrailimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rleftfrontrail}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_cowlpanelfirewall !== null &&
                          reportData?.body?.ibody_cowlpanelfirewall !== "" &&
                          reportData?.body?.ibody_cowlpanelfirewall !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_cowlpanelfirewall === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Cowl Panel Firewall
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_cowlpanelfirewallimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ibody_cowlpanelfirewallimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_cowlpanelfirewall}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_rightapillar !== null &&
                          reportData?.body?.ibody_rightapillar !== "" &&
                          reportData?.body?.ibody_rightapillar !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_rightapillar === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right A Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_rightAPillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_rightAPillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rightapillar}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_leftapiller !== null &&
                          reportData?.ac?.iac_leftapiller !== "" &&
                          reportData?.ac?.iac_leftapiller !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue?.iac_leftapiller === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left A Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_leftapillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_leftapillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_leftapiller}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_frontbpillar !== null &&
                          reportData?.body?.ibody_frontbpillar !== "" &&
                          reportData?.body?.ibody_frontbpillar !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue?.ibdy_frontbpillar ===
                                1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right B Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_rightbpillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_rightbpillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_frontbpillar}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_leftbpiller !== null &&
                          reportData?.ac?.iac_leftbpiller !== "" &&
                          reportData?.ac?.iac_leftbpiller !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue?.iac_leftbpiller === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left B Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_leftbpillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_leftbpillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_leftbpiller}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_rightcpillar !== null &&
                          reportData?.body?.ibody_rightcpillar !== "" &&
                          reportData?.body?.ibody_rightcpillar !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_rightcpillar === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right C Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_rightcpillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_rightcpillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rightcpillar}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_leftcpillar !== null &&
                          reportData?.ac?.iac_leftcpillar !== "" &&
                          reportData?.ac?.iac_leftcpillar !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue?.iac_leftcpillar === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left C Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_leftcpillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_leftcpillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_leftcpillar}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_rightbdpillar !== null &&
                          reportData?.body?.ibody_rightbdpillar !== "" &&
                          reportData?.body?.ibody_rightbdpillar !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_rightbdpillar === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right D Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_rightdpillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_rightdpillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rightbdpillar}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_leftdpiller !== null &&
                          reportData?.ac?.iac_leftdpiller !== "" &&
                          reportData?.ac?.iac_leftdpiller !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue?.iac_leftdpiller === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left D Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_leftdpillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_leftdpillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_leftdpiller}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_bootfloor !== null &&
                          reportData?.ac?.iac_bootfloor !== "" &&
                          reportData?.ac?.iac_bootfloor !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue?.iac_bootfloor === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Boot Floor
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_bootfloorimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_bootfloorimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_bootfloor}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_bootlockpillartyresize !== null &&
                          reportData?.ac?.iac_bootlockpillartyresize !== "" &&
                          reportData?.ac?.iac_bootlockpillartyresize !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.iac_bootlockpillartyresize === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Boot Lock Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_bootlockpillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_bootlockpillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_bootlockpillartyresize}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_rearsubframe !== null &&
                          reportData?.ac?.iac_rearsubframe !== "" &&
                          reportData?.ac?.iac_rearsubframe !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue?.iac_rearsubframe ===
                                1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Sub Frame
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_rearsubframeimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_rearsubframeimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_rearsubframe}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_frontsubframe !== null &&
                          reportData?.body?.ibody_frontsubframe !== "" &&
                          reportData?.body?.ibody_frontsubframe !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_frontsubframe === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Sub Frame
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_frontsubframeimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`ibody_frontsubframeimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_frontsubframe}
                                </h5>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading2">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapse2"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapse2"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Engine / Transmission / Clutch
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.enginetransmission}%
                          </h5>
                        </div>
                      </button>
                    </h2>

                    <div
                      id="panelsStayOpen-collapse2"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                    >
                      <div className="accordion-body">
                        <h4 className="text-secondary text-center fs-6">
                          Fluids/Filters Check
                        </h4>

                        {reportData?.body?.ibody_engineoillevel !== null &&
                          reportData?.body?.ibody_engineoillevel !== "" &&
                          reportData?.body?.ibody_engineoillevel !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.ibody_engineoillevel === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Engine Oil Level
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_engineoillevelimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_engineoillevelimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_engineoillevel}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_engageoilleakage !==
                          null &&
                          reportData?.suspention?.iss_engageoilleakage !== "" &&
                          reportData?.suspention?.iss_engageoilleakage !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.iss_engageoilleakage === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Engine Oil Leakage
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_engineoilleakageimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_engineoilleakageimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_engageoilleakage}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_transmissionoilleakage !==
                          null &&
                          reportData?.suspention?.iss_transmissionoilleakage !==
                            "" &&
                          reportData?.suspention?.iss_transmissionoilleakage !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.iss_transmissionoilleakage === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Transmission Oil Leakage
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_transmissionoilleakageimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(
                                        `iss_transmissionoilleakageimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.suspention
                                      ?.iss_transmissionoilleakage
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_powersteeringoilleakage !==
                          null &&
                          reportData?.suspention
                            ?.iss_powersteeringoilleakage !== "" &&
                          reportData?.suspention
                            ?.iss_powersteeringoilleakage !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.iss_powersteeringoilleakage === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Power Steering Oil Leakage
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_powersteeringoillevelimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(
                                        `iss_powersteeringoillevelimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.suspention
                                      ?.iss_powersteeringoilleakage
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_coolantleakage !== null &&
                          reportData?.suspention?.iss_coolantleakage !== "" &&
                          reportData?.suspention?.iss_coolantleakage !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.iss_coolantleakage ===
                                1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Coolant Leakage
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_coolantleakageimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_coolantleakageimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_coolantleakage}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_breakoilleakage !== null &&
                          reportData?.suspention?.iss_breakoilleakage !== "" &&
                          reportData?.suspention?.iss_breakoilleakage !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.iss_breakoilleakage === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Brake Oil Leakage
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_brakeoilleakageimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`iss_brakeoilleakageimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_breakoilleakage}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center mt-2 fs-5">
                          Mechanical Check
                        </h4>

                        {reportData?.body?.ibody_fanbelts !== null &&
                          reportData?.body?.ibody_fanbelts !== "" &&
                          reportData?.body?.ibody_fanbelts !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.ibody_fanbelts === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Belts (Fan)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_beltsfanimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_beltsfanimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_fanbelts}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_acbelts !== null &&
                          reportData?.body?.ibody_acbelts !== "" &&
                          reportData?.body?.ibody_acbelts !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.ibody_acbelts === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Belts (Ac)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_beltsacimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_beltsacimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_acbelts}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_wires !== null &&
                          reportData?.body?.ibody_wires !== "" &&
                          reportData?.body?.ibody_wires !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.ibody_wires === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Wires (Wiring Harness)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_wiringharnessimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_wiringharnessimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_wires}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_engineblowmanualcheack !==
                          null &&
                          reportData?.suspention?.iss_engineblowmanualcheack !==
                            "" &&
                          reportData?.suspention?.iss_engineblowmanualcheack !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.iss_engineblowmanualcheack === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Engine Blow (Manual Check)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_engineblowimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_engineblowimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.suspention
                                      ?.iss_engineblowmanualcheack
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_enginenoise !== null &&
                          reportData?.suspention?.iss_enginenoise !== "" &&
                          reportData?.suspention?.iss_enginenoise !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.iss_enginenoise === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Engine Noise
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_enginenoiseimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_enginenoiseimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_enginenoise}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_enginevibration !== null &&
                          reportData?.suspention?.iss_enginevibration !== "" &&
                          reportData?.suspention?.iss_enginevibration !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.iss_enginevibration === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Engine Vibration
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_enginevibrationimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`iss_enginevibrationimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_enginevibration}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_enginemounts !== null &&
                          reportData?.suspention?.iss_enginemounts !== "" &&
                          reportData?.suspention?.iss_enginemounts !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.iss_enginemounts === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Engine Mounts
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_enginemountsimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_enginemountsimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_enginemounts}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_pulleysadjuster !== null &&
                          reportData?.suspention?.iss_pulleysadjuster !== "" &&
                          reportData?.suspention?.iss_pulleysadjuster !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.iss_pulleysadjuster === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Pulleys (Adjuster)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_pulleysadjusteriimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_pulleysadjusteriimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_pulleysadjuster}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_hoses !== null &&
                          reportData?.body?.ibody_hoses !== "" &&
                          reportData?.body?.ibody_hoses !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.ibody_hoses === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Hoses</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_hosesimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_hosesimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_hoses}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center mt-2 fs-6">
                          Exhaust Check
                        </h4>

                        {reportData?.suspention?.iss_exhaustsound !== null &&
                          reportData?.suspention?.iss_exhaustsound !== "" &&
                          reportData?.suspention?.iss_exhaustsound !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.iss_exhaustsound === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Exhaust Sound
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_exhaustsoundimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_exhaustsoundimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_exhaustsound}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center mt-2 fs-6">
                          Engine Cooling System
                        </h4>

                        {reportData?.body?.ibody_radiator !== null &&
                          reportData?.body?.ibody_radiator !== "" &&
                          reportData?.body?.ibody_radiator !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.ibody_radiator === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Radiator</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_radiatorimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_radiatorimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_radiator}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_suctionfan !== null &&
                          reportData?.suspention?.iss_suctionfan !== "" &&
                          reportData?.suspention?.iss_suctionfan !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.iss_suctionfan === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Suction Fan
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_sunctionfanimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_sunctionfanimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_suctionfan}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center mt-2 fs-6">
                          Transmission Check / Power Train
                        </h4>
                        <h4 className="text-secondary text-center mt-2 fs-6">
                          Engine Electronics
                        </h4>

                        {reportData?.ac?.iac_starteroperation !== null &&
                          reportData?.ac?.iac_starteroperation !== "" &&
                          reportData?.ac?.iac_starteroperation !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.iac_starteroperation === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Starter Operation
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_starteroperationimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`iac_starteroperationimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_starteroperation}
                                </h5>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading3">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapse3"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapse3"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Brakes
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.brakes}%
                          </h5>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapse3"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                    >
                      <div className="accordion-body">
                        <h4 className="text-secondary text-center fs-6">
                          Machenical Check
                        </h4>

                        {reportData?.test?.itest_frontrightdisc !== null &&
                          reportData?.test?.itest_frontrightdisc !== "" &&
                          reportData?.test?.itest_frontrightdisc !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.brakefields
                                  ?.itest_frontrightdisc === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Disc
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontrightdiscimages === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontrightdiscimages`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontrightdisc}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontleftdisc !== null &&
                          reportData?.test?.itest_frontleftdisc !== "" &&
                          reportData?.test?.itest_frontleftdisc !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.brakefields?.itest_frontleftdisc ===
                                1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Disc
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontleftdiscimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontleftdiscimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontleftdisc}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontrightbrakepad !== null &&
                          reportData?.test?.itest_frontrightbrakepad !== "" &&
                          reportData?.test?.itest_frontrightbrakepad !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.brakefields
                                  ?.itest_frontrightbrakepad === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Brake Pad
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontrightbrakepadimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `itest_frontrightbrakepadimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontrightbrakepad}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontlefttyrepad !== null &&
                          reportData?.test?.itest_frontlefttyrepad !== "" &&
                          reportData?.test?.itest_frontlefttyrepad !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.brakefields
                                  ?.itest_frontlefttyrepad === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Brake Pad
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontlefttyrepad === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontlefttyrepad`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontlefttyrepad}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_parking_handbrake !== null &&
                          reportData?.tyre?.ityre_parking_handbrake !== "" &&
                          reportData?.tyre?.ityre_parking_handbrake !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.brakefields
                                  ?.ityre_parking_handbrake === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Parking / Hand Brake
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_parkingimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_parkingimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_parking_handbrake}
                                </h5>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading6">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapse6"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapse6"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Suspension / Steering
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.suspension}%
                          </h5>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapse6"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                    >
                      <div className="accordion-body">
                        <h4 className="text-secondary text-center fs-6">
                          Front Suspension
                        </h4>

                        {reportData?.interior?.ii_steeringwheelplay !== null &&
                          reportData?.interior?.ii_steeringwheelplay !== "" &&
                          reportData?.interior?.ii_steeringwheelplay !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.ii_steeringwheelplay === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Steering Wheel Play
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ii_steeringwheelplayimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`ii_steeringwheelplayimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.interior?.ii_steeringwheelplay}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rightballjoint !== null &&
                          reportData?.test?.itest_rightballjoint !== "" &&
                          reportData?.test?.itest_rightballjoint !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_rightballjoint === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Ball Joint
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rightballjointimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`itest_rightballjointimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rightballjoint}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_leftballjoint !== null &&
                          reportData?.test?.itest_leftballjoint !== "" &&
                          reportData?.test?.itest_leftballjoint !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_leftballjoint === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Ball Joint
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_leftballjointimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_leftballjointimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_leftballjoint}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rearzlinks !== null &&
                          reportData?.test?.itest_rearzlinks !== "" &&
                          reportData?.test?.itest_rearzlinks !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_rearzlinks === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Z Links
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rightzlinksimages === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`itest_rightzlinksimages`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearzlinks}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_leftzlinks !== null &&
                          reportData?.test?.itest_leftzlinks !== "" &&
                          reportData?.test?.itest_leftzlinks !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_leftzlinks === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Z Links
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_leftzlinksimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`itest_leftzlinksimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_leftzlinks}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_righttieroadend !== null &&
                          reportData?.test?.itest_righttieroadend !== "" &&
                          reportData?.test?.itest_righttieroadend !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_righttieroadend === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Tie Rod End
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_righttierodendimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`itest_righttierodendimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_righttieroadend}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_lefttierodend !== null &&
                          reportData?.test?.itest_lefttierodend !== "" &&
                          reportData?.test?.itest_lefttierodend !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_lefttierodend === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Tie Rod End
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_lefttierodendimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_lefttierodendimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_lefttierodend}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontrightboots !== null &&
                          reportData?.test?.itest_frontrightboots !== "" &&
                          reportData?.test?.itest_frontrightboots !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_frontrightboots === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Boots
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontrightbootsimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontrightbootsimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontrightboots}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontleftboots !== null &&
                          reportData?.test?.itest_frontleftboots !== "" &&
                          reportData?.test?.itest_frontleftboots !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_frontleftboots === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Boots
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontleftbootsimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontleftbootsimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontleftboots}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontrightbushes !== null &&
                          reportData?.test?.itest_frontrightbushes !== "" &&
                          reportData?.test?.itest_frontrightbushes !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_frontrightbushes === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Bushes
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_fontrightbushesimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_fontrightbushesimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontrightbushes}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontleftbushes !== null &&
                          reportData?.test?.itest_frontleftbushes !== "" &&
                          reportData?.test?.itest_frontleftbushes !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_frontleftbushes === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Bushes
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontleftbushesimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontleftbushesimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontleftbushes}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontrightshock !== null &&
                          reportData?.test?.itest_frontrightshock !== "" &&
                          reportData?.test?.itest_frontrightshock !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_frontrightshock === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Shock
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontrightshockimages ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontrightshockimages`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontrightshock}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontleftshock !== null &&
                          reportData?.test?.itest_frontleftshock !== "" &&
                          reportData?.test?.itest_frontleftshock !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_frontleftshock === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Shock
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontleftshockimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontleftshockimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontleftshock}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6 mt-2">
                          Rear Suspension
                        </h4>

                        {reportData?.test?.itest_rearrightbushes !== null &&
                          reportData?.test?.itest_rearrightbushes !== "" &&
                          reportData?.test?.itest_rearrightbushes !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_rearrightbushes === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Right Bushes
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rearrightbushesimages ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_rearrightbushesimages`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearrightbushes}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rearleftbushes !== null &&
                          reportData?.test?.itest_rearleftbushes !== "" &&
                          reportData?.test?.itest_rearleftbushes !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_rearleftbushes === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Left Bushes
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rearleftbushesimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_rearleftbushesimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearleftbushes}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rearrightshock !== null &&
                          reportData?.test?.itest_rearrightshock !== "" &&
                          reportData?.test?.itest_rearrightshock !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_rearrightshock === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Right Shock
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rearrightshockimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_rearrightshockimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearrightshock}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rearleftshock !== null &&
                          reportData?.test?.itest_rearleftshock !== "" &&
                          reportData?.test?.itest_rearleftshock !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_rearleftshock === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear left Shock
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rearleftshockimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_rearleftshockimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearleftshock}
                                </h5>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading5">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapse5"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapse5"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Interior
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.interior}%
                          </h5>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapse5"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                    >
                      <div className="accordion-body">
                        <h4 className="text-secondary text-center fs-6">
                          Steering Controls
                        </h4>

                        {reportData?.tyre?.ityre_steeringwheelcondition !==
                          null &&
                          reportData?.tyre?.ityre_steeringwheelcondition !==
                            "" &&
                          reportData?.tyre?.ityre_steeringwheelcondition !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_steeringwheelcondition === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Steering Wheel Condition
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_steeringwheelconditionimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_steeringwheelconditionimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_steeringwheelcondition
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_steeringwheelbuttons !==
                          null &&
                          reportData?.tyre?.ityre_steeringwheelbuttons !== "" &&
                          reportData?.tyre?.ityre_steeringwheelbuttons !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_steeringwheelbuttons === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Steering Wheel Buttons
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_steeringwheelbuttonsimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_steeringwheelbuttonsimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_steeringwheelbuttons}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_horn !== null &&
                          reportData?.tyre?.ityre_horn !== "" &&
                          reportData?.tyre?.ityre_horn !== "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields?.ityre_horn === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Horn</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_hornimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_hornimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_horn}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_lightsleverswitch !== null &&
                          reportData?.tyre?.ityre_lightsleverswitch !== "" &&
                          reportData?.tyre?.ityre_lightsleverswitch !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_lightsleverswitch === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Lights Lever / Switch (High / Low Beams ,
                                  Indicators)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_lightsleverswitchimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_lightsleverswitchimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_lightsleverswitch}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_wiperswasherlever !== null &&
                          reportData?.tyre?.ityre_wiperswasherlever !== "" &&
                          reportData?.tyre?.ityre_wiperswasherlever !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_wiperswasherlever === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Wiper / Washer Lever (Washer,Speed)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_wiperswasherleverimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_wiperswasherleverimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_wiperswasherlever}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6 mt-2">
                          Mirrors
                        </h4>

                        {reportData?.body?.ibody_rightsidemirro !== null &&
                          reportData?.body?.ibody_rightsidemirro !== "" &&
                          reportData?.body?.ibody_rightsidemirro !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ibody_rightsidemirro === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Side Mirror
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_rightsidemirrorimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_rightsidemirrorimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rightsidemirro}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_leftsidemirror !== null &&
                          reportData?.ac?.iac_leftsidemirror !== "" &&
                          reportData?.ac?.iac_leftsidemirror !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.iac_leftsidemirror === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Side Mirror
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_leftsidemirrorimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_leftsidemirrorimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_leftsidemirror}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rearviewmirrordimmer !==
                          null &&
                          reportData?.tyre?.ityre_rearviewmirrordimmer !== "" &&
                          reportData?.tyre?.ityre_rearviewmirrordimmer !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_rearviewmirrordimmer === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear View Mirror Dimmer
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rearviewmirrordimmerimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_rearviewmirrordimmerimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rearviewmirrordimmer}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6 mt-2">
                          Seats
                        </h4>

                        {reportData?.tyre?.ityre_rightseatadjusterrecliner !==
                          null &&
                          reportData?.tyre?.ityre_rightseatadjusterrecliner !==
                            "" &&
                          reportData?.tyre?.ityre_rightseatadjusterrecliner !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_rightseatadjusterrecliner === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Seat Adjuster Recliner
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rightseatadjusterreclinerimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_rightseatadjusterreclinerimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_rightseatadjusterrecliner
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_leftseatadjusterrecliner !==
                          null &&
                          reportData?.tyre?.ityre_leftseatadjusterrecliner !==
                            "" &&
                          reportData?.tyre?.ityre_leftseatadjusterrecliner !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_leftseatadjusterrecliner === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Seat Adjuster Recliner
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_leftseatadjusterreclinerimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_leftseatadjusterreclinerimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_leftseatadjusterrecliner
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rightseatadjusterleartrack !==
                          null &&
                          reportData?.tyre?.ityre_rightseatadjusterleartrack !==
                            "" &&
                          reportData?.tyre?.ityre_rightseatadjusterleartrack !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_rightseatadjusterleartrack === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Seat Adjuster Lear Track
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rightseatadjusterleartrackimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_rightseatadjusterleartrackimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_rightseatadjusterleartrack
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_leftseatadjusterleartrack !==
                          null &&
                          reportData?.tyre?.ityre_leftseatadjusterleartrack !==
                            "" &&
                          reportData?.tyre?.ityre_leftseatadjusterleartrack !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_leftseatadjusterleartrack === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Seat Adjuster Lear Track
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_leftseatadjusterleartrackimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_leftseatadjusterleartrackimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_leftseatadjusterleartrack
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rightseatbelt !== null &&
                          reportData?.tyre?.ityre_rightseatbelt !== "" &&
                          reportData?.tyre?.ityre_rightseatbelt !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_rightseatbelt === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Seat Belt
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rightseatbeltimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_rightseatbeltimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rightseatbelt}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_leftseatbelt !== null &&
                          reportData?.tyre?.ityre_leftseatbelt !== "" &&
                          reportData?.tyre?.ityre_leftseatbelt !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_leftseatbelt === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Seat Belt
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_leftseatbeltimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_leftseatbeltimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_leftseatbelt}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rearseatbelts !== null &&
                          reportData?.tyre?.ityre_rearseatbelts !== "" &&
                          reportData?.tyre?.ityre_rearseatbelts !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_rearseatbelts === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Seat Belt
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rearseatbeltsimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_rearseatbeltsimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rearseatbelts}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_glovebox !== null &&
                          reportData?.tyre?.ityre_glovebox !== "" &&
                          reportData?.tyre?.ityre_glovebox !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields?.ityre_glovebox === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Glove Box
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_gloveboximage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_gloveboximage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_glovebox}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6 mt-2">
                          Power / Manual Windows & Central Locking
                        </h4>

                        {reportData?.tyre?.ityre_frontrightpowerwindow !==
                          null &&
                          reportData?.tyre?.ityre_frontrightpowerwindow !==
                            "" &&
                          reportData?.tyre?.ityre_frontrightpowerwindow !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_frontrightpowerwindow === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Power Window/Manual Lever
                                </p>
                              </div>

                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_frontrightpowerwindowimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_frontrightpowerwindowimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>

                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_frontrightpowerwindow
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_frontleftpowerwindow !==
                          null &&
                          reportData?.tyre?.ityre_frontleftpowerwindow !== "" &&
                          reportData?.tyre?.ityre_frontleftpowerwindow !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_frontleftpowerwindow === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Power Window/Manual Lever
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_frontleftpowerwindowimage ===
                                  1 && (
                                  <div className="col-lg-2 text-center col-6">
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(
                                          `ityre_frontleftpowerwindowimage`
                                        )
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  </div>
                                )}
                              </div>

                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_frontleftpowerwindow}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rearrightpowerwindow !==
                          null &&
                          reportData?.tyre?.ityre_rearrightpowerwindow !== "" &&
                          reportData?.tyre?.ityre_rearrightpowerwindow !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_rearrightpowerwindow === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Right Power Window/Manual Lever
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rearrightpowerwindowimage ===
                                  1 && (
                                  <div className="col-lg-2 text-center col-6">
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(
                                          `ityre_rearrightpowerwindowimage`
                                        )
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  </div>
                                )}
                              </div>

                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rearrightpowerwindow}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rearleftpowerwindow !== null &&
                          reportData?.tyre?.ityre_rearleftpowerwindow !== "" &&
                          reportData?.tyre?.ityre_rearleftpowerwindow !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_rearleftpowerwindow === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear left Power Window/Manual Lever
                                </p>
                              </div>

                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rearleftpowerwindowimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_rearleftpowerwindowimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>

                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rearleftpowerwindow}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_autolockbutton !== null &&
                          reportData?.tyre?.ityre_autolockbutton !== "" &&
                          reportData?.tyre?.ityre_autolockbutton !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_autolockbutton === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Auto Lock Button{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_autolockbuttonimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_autolockbuttonimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_autolockbutton}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_windowsafetylock !== null &&
                          reportData?.tyre?.ityre_windowsafetylock !== "" &&
                          reportData?.tyre?.ityre_windowsafetylock !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_windowsafetylock === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Window Safety Lock{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_windowsafetylockimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_windowsafetylockimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_windowsafetylock}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6 mt-2">
                          Dash / Roof Controls
                        </h4>

                        {reportData?.tyre?.ityre_interiorlightings !== null &&
                          reportData?.tyre?.ityre_interiorlightings !== "" &&
                          reportData?.tyre?.ityre_interiorlightings !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_interiorlightings === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Interior Lightings{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_interiorlightingsimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_interiorlightingsimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_interiorlightings}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_dashcontrolsac !== null &&
                          reportData?.tyre?.ityre_dashcontrolsac !== "" &&
                          reportData?.tyre?.ityre_dashcontrolsac !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_dashcontrolsac === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Dash Controls - A/C
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_dashcontrolsacimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_dashcontrolsacimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_dashcontrolsac}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_dashcontrolsdefog !== null &&
                          reportData?.tyre?.ityre_dashcontrolsdefog !== "" &&
                          reportData?.tyre?.ityre_dashcontrolsdefog !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_dashcontrolsdefog === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Dash Controls - De-Fog{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_dashcontrolsdefogimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_dashcontrolsdefogimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_dashcontrolsdefog}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_dashcontrolshazzardlights !==
                          null &&
                          reportData?.tyre?.ityre_dashcontrolshazzardlights !==
                            "" &&
                          reportData?.tyre?.ityre_dashcontrolshazzardlights !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_dashcontrolshazzardlights === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Dash Controls - Hazzard Lights{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_dashcontrolshazzardlightsimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_dashcontrolshazzardlightsimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_dashcontrolshazzardlights
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_dashcontrolsparkingbutton !==
                          null &&
                          reportData?.tyre?.ityre_dashcontrolsparkingbutton !==
                            "" &&
                          reportData?.tyre?.ityre_dashcontrolsparkingbutton !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_dashcontrolsparkingbutton === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Dash Controls - Parking Button
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_dashcontrolsparkingbuttonimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_dashcontrolsparkingbuttonimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_dashcontrolsparkingbutton
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_dashcontrolothers !== null &&
                          reportData?.tyre?.ityre_dashcontrolothers !== "" &&
                          reportData?.tyre?.ityre_dashcontrolothers !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_dashcontrolothers === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Dash Controls - Others
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_dashcontrolothersimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_dashcontrolothersimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_dashcontrolothers}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_audio !== null &&
                          reportData?.tyre?.ityre_audio !== "" &&
                          reportData?.tyre?.ityre_audio !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields?.ityre_audio === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Audio/Video
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_audioimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_audioimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_audio}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_trunkrelease !== null &&
                          reportData?.body?.ibody_trunkrelease !== "" &&
                          reportData?.body?.ibody_trunkrelease !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ibody_trunkrelease === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Trunk Release Lever / Button
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_trunkreleaseimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_trunkreleaseimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_trunkrelease}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_fuelcaprelease !== null &&
                          reportData?.body?.ibody_fuelcaprelease !== "" &&
                          reportData?.body?.ibody_fuelcaprelease !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ibody_fuelcaprelease === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Fuel Cap Release Lever / Button{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_fuelcapreleaseimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_fuelcapreleaseimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_fuelcaprelease}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_bonetrelease !== null &&
                          reportData?.body?.ibody_bonetrelease !== "" &&
                          reportData?.body?.ibody_bonetrelease !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ibody_bonetrelease === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Bonnet Release Lever / Button{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_bonnetlreleaseimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_bonnetlreleaseimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_bonetrelease}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6 mt-2">
                          Poshish
                        </h4>

                        {reportData?.tyre?.ityre_roofposhish !== null &&
                          reportData?.tyre?.ityre_roofposhish !== "" &&
                          reportData?.tyre?.ityre_roofposhish !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_roofposhish === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Roof Poshish{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_roofposhishimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_roofposhishimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_roofposhish}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_floormat !== null &&
                          reportData?.tyre?.ityre_floormat !== "" &&
                          reportData?.tyre?.ityre_floormat !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields?.ityre_floormat === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Floor Mat
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_floormatimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_floormatimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_floormat}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_frontrightseatposhish !==
                          null &&
                          reportData?.tyre?.ityre_frontrightseatposhish !==
                            "" &&
                          reportData?.tyre?.ityre_frontrightseatposhish !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_frontrightseatposhish === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Seat Poshish
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_frontrightseatposhishimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_frontrightseatposhishimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_frontrightseatposhish
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_frontleftseatposhish !==
                          null &&
                          reportData?.tyre?.ityre_frontleftseatposhish !== "" &&
                          reportData?.tyre?.ityre_frontleftseatposhish !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_frontleftseatposhish === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Seat Poshish
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_frontleftseatposhishimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_frontleftseatposhishimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_frontleftseatposhish}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rearseatposhish !== null &&
                          reportData?.tyre?.ityre_rearseatposhish !== "" &&
                          reportData?.tyre?.ityre_rearseatposhish !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_rearseatposhish === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Seat Poshish
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rearseatposhishimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_rearseatposhishimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rearseatposhish}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_dashboardcondition !== null &&
                          reportData?.tyre?.ityre_dashboardcondition !== "" &&
                          reportData?.tyre?.ityre_dashboardcondition !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_dashboardcondition === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Dashboard Condition
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_dashboardconditionimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_dashboardconditionimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_dashboardcondition}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6 mt-2">
                          Equipment
                        </h4>

                        {reportData?.ac?.iac_sparetire !== null &&
                          reportData?.ac?.iac_sparetire !== "" &&
                          reportData?.ac?.iac_sparetire !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields?.iac_sparetire === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Spare Tire
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_sparetyreimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_sparetyreimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_sparetire}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_puncturerepairekit !== null &&
                          reportData?.ac?.iac_puncturerepairekit !== "" &&
                          reportData?.ac?.iac_puncturerepairekit !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.iac_puncturerepairekit === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Puncture Kit{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_puncturerepairekitimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_puncturerepairekitimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_puncturerepairekit}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_tools !== null &&
                          reportData?.ac?.iac_tools !== "" &&
                          reportData?.ac?.iac_tools !== "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields?.iac_tools === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Tools</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_toolsimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_toolsimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_tools}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_jack !== null &&
                          reportData?.ac?.iac_jack !== "" &&
                          reportData?.ac?.iac_jack !== "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields?.iac_jack === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Jack</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_jackimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() => handleImage(`iac_jackimage`)}
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_jack}
                                </h5>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading7">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapse7"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapse7"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Ac / Heater
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.acheater}%
                          </h5>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapse7"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                    >
                      <div className="accordion-body">
                        <h4 className="text-secondary text-center fs-6">
                          AC / Heater Check Up
                        </h4>

                        {reportData?.suspention?.iss_acfitted !== null &&
                          reportData?.suspention?.iss_acfitted !== "" &&
                          reportData?.suspention?.iss_acfitted !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.acheatervalue?.iss_acfitted === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  AC Fitted
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_acfittedimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`iss_acfittedimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_acfitted}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_acoperational !== null &&
                          reportData?.tyre?.ityre_acoperational !== "" &&
                          reportData?.tyre?.ityre_acoperational !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.acheatervalue
                                  ?.ityre_acoperational === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  AC Operational
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_acoperationalimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`ityre_acoperationalimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_acoperational}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_blower !== null &&
                          reportData?.tyre?.ityre_blower !== "" &&
                          reportData?.tyre?.ityre_blower !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.acheatervalue?.ityre_blower === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Blower</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_blowerimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_blowerimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_blower}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_cooling !== null &&
                          reportData?.tyre?.ityre_cooling !== "" &&
                          reportData?.tyre?.ityre_cooling !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.acheatervalue?.ityre_cooling === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Cooling</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_coolingimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`ityre_coolingimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_cooling}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_heating !== null &&
                          reportData?.tyre?.ityre_heating !== "" &&
                          reportData?.tyre?.ityre_heating !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.acheatervalue?.ityre_heating === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Heating</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_heatingimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`ityre_heatingimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_heating}
                                </h5>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading8">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapse8"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapse8"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Electrical & Electronics
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.electronics}%
                          </h5>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapse8"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingThree"
                    >
                      <div className="accordion-body">
                        <h4 className="text-secondary text-center fs-6">
                          Computer Check up{" "}
                        </h4>

                        {reportData?.tyre?.ityre_computercheckup !== null &&
                          reportData?.tyre?.ityre_computercheckup !== "" &&
                          reportData?.tyre?.ityre_computercheckup !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_computercheckup === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Computer Check up / Malfunction Check
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_computercheckupimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`ityre_computercheckupimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_computercheckup}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rearviewcamera !== null &&
                          reportData?.tyre?.ityre_rearviewcamera !== "" &&
                          reportData?.tyre?.ityre_rearviewcamera !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_rearviewcamera === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear View Camera
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rearviewcameraimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`ityre_rearviewcameraimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rearviewcamera}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_batterywarninglight !== null &&
                          reportData?.tyre?.ityre_batterywarninglight !== "" &&
                          reportData?.tyre?.ityre_batterywarninglight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_batterywarninglight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Battery Warning Light
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_batterywarninglightimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_batterywarninglightimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_batterywarninglight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_oilpressurelowwarninglight !==
                          null &&
                          reportData?.tyre?.ityre_oilpressurelowwarninglight !==
                            "" &&
                          reportData?.tyre?.ityre_oilpressurelowwarninglight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_oilpressurelowwarninglight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Oil Pressure Low Warning Light{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_oilpressurelowwarninglightimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_oilpressurelowwarninglightimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_oilpressurelowwarninglight
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_tempraturewarninglight !==
                          null &&
                          reportData?.tyre?.ityre_tempraturewarninglight !==
                            "" &&
                          reportData?.tyre?.ityre_tempraturewarninglight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_tempraturewarninglight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Temperature Warning Light / Gauge
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_tempraturewarninglightimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_tempraturewarninglightimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_tempraturewarninglight
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_guages !== null &&
                          reportData?.tyre?.ityre_guages !== "" &&
                          reportData?.tyre?.ityre_guages !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue?.ityre_guages === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Gauges</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_guagesimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_guagesimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_guages}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_airbagwarninglight !== null &&
                          reportData?.tyre?.ityre_airbagwarninglight !== "" &&
                          reportData?.tyre?.ityre_airbagwarninglight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_airbagwarninglight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Air Bag Warning Light{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_airbagwarninglightimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_airbagwarninglightimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_airbagwarninglight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_steeringwarninglight !==
                          null &&
                          reportData?.tyre?.ityre_steeringwarninglight !== "" &&
                          reportData?.tyre?.ityre_steeringwarninglight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_steeringwarninglight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Power Steering Warning Light{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_steeringwarninglightimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_steeringwarninglightimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_steeringwarninglight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_abswarninglight !== null &&
                          reportData?.tyre?.ityre_abswarninglight !== "" &&
                          reportData?.tyre?.ityre_abswarninglight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_abswarninglight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  ABS Warning Light
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_abswarninglightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_abswarninglightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_abswarninglight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_keyfobbatterylowlight !==
                          null &&
                          reportData?.tyre?.ityre_keyfobbatterylowlight !==
                            "" &&
                          reportData?.tyre?.ityre_keyfobbatterylowlight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_keyfobbatterylowlight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Key Fob Battery Low Light
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_keyfobbatterylowlightimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_keyfobbatterylowlightimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_keyfobbatterylowlight
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6">
                          Battery
                        </h4>

                        {reportData?.body?.ibody_voltage !== null &&
                          reportData?.body?.ibody_voltage !== "" &&
                          reportData?.body?.ibody_voltage !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue?.ibody_voltage ===
                                1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Voltage</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_voltageimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_voltageimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_voltage}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_terminalscondition !== null &&
                          reportData?.body?.ibody_terminalscondition !== "" &&
                          reportData?.body?.ibody_terminalscondition !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ibody_terminalscondition === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Terminal Condition
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_terminalsconditionimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ibody_terminalsconditionimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_terminalscondition}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_charging !== null &&
                          reportData?.suspention?.iss_charging !== "" &&
                          reportData?.suspention?.iss_charging !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue?.iss_charging === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Charging</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_chargingimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_chargingimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_charging}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_alternatoroperation !==
                          null &&
                          reportData?.suspention?.iss_alternatoroperation !==
                            "" &&
                          reportData?.suspention?.iss_alternatoroperation !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.iss_alternatoroperation === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Alternator Operation
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_alternate_optionimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_alternate_optionimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.suspention
                                      ?.iss_alternatoroperation
                                  }
                                </h5>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading9">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapse9"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapse9"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Exterior & Body
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.exteriorbasic}%
                          </h5>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapse9"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingThree"
                    >
                      <div className="accordion-body">
                        <h4 className="text-secondary text-center fs-6">
                          Car frame
                        </h4>

                        {reportData?.ac?.iac_trunklock !== null &&
                          reportData?.ac?.iac_trunklock !== "" &&
                          reportData?.ac?.iac_trunklock !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue?.iac_trunklock === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Trunk Lock{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_trunklockimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`iac_trunklockimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_trunklock}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_frontwindshield !== null &&
                          reportData?.body?.ibody_frontwindshield !== "" &&
                          reportData?.body?.ibody_frontwindshield !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ibody_frontwindshield === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Windshield Condition
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_frontwindshieldimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_frontwindshieldimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_frontwindshield}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_rearwindshieldcondition !== null &&
                          reportData?.ac?.iac_rearwindshieldcondition !== "" &&
                          reportData?.ac?.iac_rearwindshieldcondition !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.iac_rearwindshieldcondition === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Windshield Condition
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_rearwindshieldimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_rearwindshieldimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_rearwindshieldcondition}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_frontrightdoorwindow !==
                          null &&
                          reportData?.tyre?.ityre_frontrightdoorwindow !== "" &&
                          reportData?.tyre?.ityre_frontrightdoorwindow !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_frontrightdoorwindow === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Door Window
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_frontrightdoorwindowimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_frontrightdoorwindowimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_frontrightdoorwindow}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_frontleftdoorwindow !== null &&
                          reportData?.tyre?.ityre_frontleftdoorwindow !== "" &&
                          reportData?.tyre?.ityre_frontleftdoorwindow !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_frontleftdoorwindow === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Door Window{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_frontleftdoorwindowimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_frontleftdoorwindowimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_frontleftdoorwindow}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rearrightdoorwindow !== null &&
                          reportData?.tyre?.ityre_rearrightdoorwindow !== "" &&
                          reportData?.tyre?.ityre_rearrightdoorwindow !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_rearrightdoorwindow === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Right Door Window{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rearrightdoorwindowimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_rearrightdoorwindowimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rearrightdoorwindow}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rearleftdoorwindow !== null &&
                          reportData?.tyre?.ityre_rearleftdoorwindow !== "" &&
                          reportData?.tyre?.ityre_rearleftdoorwindow !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_rearleftdoorwindow === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Left Door Window
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rearleftdoorwindowimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_rearleftdoorwindowimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rearleftdoorwindow}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_windscreenwiper !== null &&
                          reportData?.tyre?.ityre_windscreenwiper !== "" &&
                          reportData?.tyre?.ityre_windscreenwiper !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_windscreenwiper === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Windscreen Wiper{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_windscreenwiperimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_windscreenwiperimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_windscreenwiper}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6 mt-2">
                          Exterior Lights
                        </h4>

                        {reportData?.tyre?.ityre_rightheadlight !== null &&
                          reportData?.tyre?.ityre_rightheadlight !== "" &&
                          reportData?.tyre?.ityre_rightheadlight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_rightheadlight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Headlight (Working)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rightheadlightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_rightheadlightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rightheadlight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_leftheadlight !== null &&
                          reportData?.tyre?.ityre_leftheadlight !== "" &&
                          reportData?.tyre?.ityre_leftheadlight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_leftheadlight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Headlight (Working)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_leftheadlightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_leftheadlightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_leftheadlight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_rightheadlight !== null &&
                          reportData?.body?.ibody_rightheadlight !== "" &&
                          reportData?.body?.ibody_rightheadlight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ibody_rightheadlight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Headlight (Condition)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_rightheadlightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_rightheadlightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rightheadlight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_leftheadlight !== null &&
                          reportData?.body?.ibody_leftheadlight !== "" &&
                          reportData?.body?.ibody_leftheadlight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ibody_leftheadlight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Headlight (Condition)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_leftheadlightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_leftheadlightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_leftheadlight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_righttaillight !== null &&
                          reportData?.tyre?.ityre_righttaillight !== "" &&
                          reportData?.tyre?.ityre_righttaillight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_righttaillight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Taillight (Working)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_righttaillightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_righttaillightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_righttaillight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_lefttaillight !== null &&
                          reportData?.tyre?.ityre_lefttaillight !== "" &&
                          reportData?.tyre?.ityre_lefttaillight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_lefttaillight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Taillight (Working)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_lefttaillightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_lefttaillightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_lefttaillight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_righttaillight !== null &&
                          reportData?.body?.ibody_righttaillight !== "" &&
                          reportData?.body?.ibody_righttaillight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ibody_righttaillight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Taillight (Condition)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_righttaillightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_righttaillightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_righttaillight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_lefttaillightcondition !== null &&
                          reportData?.ac?.iac_lefttaillightcondition !== "" &&
                          reportData?.ac?.iac_lefttaillightcondition !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.iac_lefttaillightcondition === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Taillight (Condition)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_lefttaillightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_lefttaillightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_lefttaillightcondition}
                                </h5>
                              </div>
                            </div>
                          )}

                        {/* <div
                          className={`row border align-items-center py-2 reportAccTab ${
                            percentage?.exteriorvalue
                              ?.ibody_rightheadlight === 1
                              ? "bgGreenLight"
                              : "bgRedLight"
                          }`}
                        >
                          <div className="col-lg-7 col-12">
                            <p className="AccorData defectName">
                              Right Headlight (Condition)
                            </p>
                          </div>
                          <div className="col-lg-2 text-center col-6">
                            {isImages?.ibody_rightheadlightimage ===
                              1 && (
                              <Button
                                variant="outlined"
                                className="imageViewBtn print-hidden p-0 align-items-center"
                                onClick={() =>
                                  handleImage(`ibody_rightheadlightimage`)
                                }
                              >
                                <i className="fa-solid fa-image me-2"></i>
                                View
                              </Button>
                            )}
                          </div>
                          <div className="col-lg-3 text-center col-6">
                            <h5 className="m-0 defectName">
                              {reportData?.ac?.ibody_rightheadlight}
                            </h5>
                          </div>
                        </div>



                        <div
                          className={`row border align-items-center py-2 reportAccTab ${
                            percentage?.exteriorvalue
                              ?.ibody_leftheadlight === 1
                              ? "bgGreenLight"
                              : "bgRedLight"
                          }`}
                        >
                          <div className="col-lg-7 col-12">
                            <p className="AccorData defectName">
                              Left Headlight (Condition)
                            </p>
                          </div>
                          <div className="col-lg-2 text-center col-6">
                            {isImages?.ibody_leftheadlightimage ===
                              1 && (
                              <Button
                                variant="outlined"
                                className="imageViewBtn print-hidden p-0 align-items-center"
                                onClick={() =>
                                  handleImage(`ibody_leftheadlightimage`)
                                }
                              >
                                <i className="fa-solid fa-image me-2"></i>
                                View
                              </Button>
                            )}
                          </div>
                          <div className="col-lg-3 text-center col-6">
                            <h5 className="m-0 defectName">
                              {reportData?.ac?.ibody_leftheadlight}
                            </h5>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading12">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapse12"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapse12"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Tyres
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.tyres}%
                          </h5>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapse12"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingThree"
                    >
                      <div className="accordion-body">
                        <h4 className="text-secondary text-center fs-6">
                          Tyres
                        </h4>

                        {reportData?.test?.itest_frontrighttyrebrand !== null &&
                          reportData?.test?.itest_frontrighttyrebrand !== "" &&
                          reportData?.test?.itest_frontrighttyrebrand !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab bgGreenLight`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Tyre Brand
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontrighttyrebrandimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(
                                        `itest_frontrighttyrebrandimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontrighttyrebrand}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontrighttyre !== null &&
                          reportData?.test?.itest_frontrighttyre !== "" &&
                          reportData?.test?.itest_frontrighttyre !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.tyresvalues
                                  ?.itest_frontrighttyre === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Tyre{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontrighttyreimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontrighttyreimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontrighttyre}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontlefttyrebrand !== null &&
                          reportData?.test?.itest_frontlefttyrebrand !== "" &&
                          reportData?.test?.itest_frontlefttyrebrand !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab bgGreenLight`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Tyre Brand
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontlefttyrebrandimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `itest_frontlefttyrebrandimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontlefttyrebrand}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontlefttyre !== null &&
                          reportData?.test?.itest_frontlefttyre !== "" &&
                          reportData?.test?.itest_frontlefttyre !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.tyresvalues?.itest_frontlefttyre ===
                                1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Tyre
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontlefttyreimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontlefttyreimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontlefttyre}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rearrighttyrebrand !== null &&
                          reportData?.test?.itest_rearrighttyrebrand !== "" &&
                          reportData?.test?.itest_rearrighttyrebrand !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab bgGreenLight`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Right Tyre Brand{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rearrighttyrebrandimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `itest_rearrighttyrebrandimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearrighttyrebrand}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rearrighttyre !== null &&
                          reportData?.test?.itest_rearrighttyre !== "" &&
                          reportData?.test?.itest_rearrighttyre !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.tyresvalues?.itest_rearrighttyre ===
                                1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Right Tyre
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rearrighttyreimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_rearrighttyreimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearrighttyre}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rearlefttyrebrand !== null &&
                          reportData?.test?.itest_rearlefttyrebrand !== "" &&
                          reportData?.test?.itest_rearlefttyrebrand !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab bgGreenLight`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Left Tyre Brand{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rearlefttyrebrandimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `itest_rearlefttyrebrandimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearlefttyrebrand}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rearlefttyre !== null &&
                          reportData?.test?.itest_rearlefttyre !== "" &&
                          reportData?.test?.itest_rearlefttyre !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.tyresvalues?.itest_rearlefttyre ===
                                1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Left Tyre
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rearlefttyreimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_rearlefttyreimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearlefttyre}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_tyresize !== null &&
                          reportData?.test?.itest_tyresize !== "" &&
                          reportData?.test?.itest_tyresize !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab bgGreenLight`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Tyre Size
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_tyresizeimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_tyresizeimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_tyresize}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rims !== null &&
                          reportData?.test?.itest_rims !== "" &&
                          reportData?.test?.itest_rims !== "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab bgGreenLight`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Rims</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rimsimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_rimsimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rims}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_wheelcaps !== null &&
                          reportData?.test?.itest_wheelcaps !== "" &&
                          reportData?.test?.itest_wheelcaps !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.tyresvalues?.itest_wheelcaps === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Wheel Caps{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_wheelcapsimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_wheelcapsimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_wheelcaps}
                                </h5>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  {reportData?.interior?.ii_testdrive === "Yes" && (
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading10">
                        <button
                          className="accordion-button w-100"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapse10"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapse10"
                        >
                          <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                            <h5 className="m-0 fs-6">
                              <i className="fa-solid fa-car me-3"></i>
                              Test Drive
                            </h5>

                            {/* <h5 className="m-0 me-3 fs-6">
                            {percentage?.testdrive}%
                          </h5> */}
                          </div>
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapse10"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingThree"
                      >
                        <div className="accordion-body">
                          {reportData?.interior?.ii_enginepick !== null &&
                            reportData?.interior?.ii_enginepick !== "" &&
                            reportData?.interior?.ii_enginepick !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue?.ii_enginepick ===
                                  1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Engine Pick{" "}
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_enginepickimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="align-items-center imageViewBtn print-hidden"
                                      onClick={() =>
                                        handleImage(`ii_enginepickimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_enginepick}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_driveshaft_noise !== null &&
                            reportData?.interior?.ii_driveshaft_noise !== "" &&
                            reportData?.interior?.ii_driveshaft_noise !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_driveshaft_noise === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Drive Shaft Noise{" "}
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_driveshaftnoiceimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_driveshaftnoiceimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_driveshaft_noise}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_gearshiftingauto !== null &&
                            reportData?.interior?.ii_gearshiftingauto !== "" &&
                            reportData?.interior?.ii_gearshiftingauto !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_gearshiftingauto === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Gear Shifting (Automatic)
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_gearshiftingautoimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_gearshiftingautoimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_gearshiftingauto}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_brakepedaloperation !==
                            null &&
                            reportData?.interior?.ii_brakepedaloperation !==
                              "" &&
                            reportData?.interior?.ii_brakepedaloperation !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_brakepedaloperation === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Brake Pedal Operation
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_brakepedaloperationimage ===
                                    1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(
                                          `ii_brakepedaloperationimage`
                                        )
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {
                                      reportData?.interior
                                        ?.ii_brakepedaloperation
                                    }
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_absoperation !== null &&
                            reportData?.interior?.ii_absoperation !== "" &&
                            reportData?.interior?.ii_absoperation !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_absoperation === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    ABS Operation
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_absoperationimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_absoperationimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_absoperation}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_frontsuspension !== null &&
                            reportData?.interior?.ii_frontsuspension !== "" &&
                            reportData?.interior?.ii_frontsuspension !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_frontsuspension === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Front Suspension (While Driving){" "}
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_frontsuspensionimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_frontsuspensionimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_frontsuspension}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_rearsuspension !== null &&
                            reportData?.interior?.ii_rearsuspension !== "" &&
                            reportData?.interior?.ii_rearsuspension !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_rearsuspension === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Rear Suspension (While Driving)
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_rearssupensionimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_rearssupensionimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_rearsuspension}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_steringoperation !== null &&
                            reportData?.interior?.ii_steringoperation !== "" &&
                            reportData?.interior?.ii_steringoperation !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_steringoperation === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Steering Operation (While Driving){" "}
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_steeringoperationimage ===
                                    1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_steeringoperationimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_steringoperation}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_steeringwheelalignment !==
                            null &&
                            reportData?.interior?.ii_steeringwheelalignment !==
                              "" &&
                            reportData?.interior?.ii_steeringwheelalignment !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_steeringwheelalignment === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Steering Wheel Alignment (While Driving){" "}
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_steeringalignimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_steeringalignimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {
                                      reportData?.interior
                                        ?.ii_steeringwheelalignment
                                    }
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.test?.ii_acoperation &&
                            reportData?.test?.ii_acoperation !== null &&
                            reportData?.test?.ii_acoperation !== "" &&
                            reportData?.test?.ii_acoperation !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue?.ii_acoperation ===
                                  1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    AC Operation (While Driving){" "}
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_acoperationimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_acoperationimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.test?.ii_acoperation}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_heateroperation !== null &&
                            reportData?.interior?.ii_heateroperation !== "" &&
                            reportData?.interior?.ii_heateroperation !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_heateroperation === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Heater Operation (While Driving)
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_heateroperationimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_heateroperationimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_heateroperation}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_speedometer !== null &&
                            reportData?.interior?.ii_speedometer !== "" &&
                            reportData?.interior?.ii_speedometer !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue?.ii_speedometer ===
                                  1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Speedometer (While Driving)
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_speedometerimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_speedometerimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_speedometer}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_testdrivedoneby !== null &&
                            reportData?.interior?.ii_testdrivedoneby !== "" &&
                            reportData?.interior?.ii_testdrivedoneby !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab bgGreenLight`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Test Drive Done By{" "}
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_testdrivedonebyimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_testdrivedonebyimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_testdrivedoneby}
                                  </h5>
                                </div>
                              </div>
                            )}
                          .
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <h5 className="report-customer-info fw-600 fw-700">
                    Main - Vehicle Pictures
                  </h5>
                </div>

                {lastImages?.iex_front_view_image !== 0 &&
                  lastImages?.iex_front_view_image?.length > 0 &&
                  lastImages?.iex_front_view_image?.map((image) => (
                    <div className="col-lg-3 col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_front_view_image/${image?.inspectionimage_name}`}
                          alt="Front View Image"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Front View Image</p>
                    </div>
                  ))}

                {lastImages?.iex_oblique_front_right !== 0 &&
                  lastImages?.iex_oblique_front_right?.length > 0 &&
                  lastImages?.iex_oblique_front_right?.map((image) => (
                    <div className="col-lg-3 col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_oblique_front_right/${image?.inspectionimage_name}`}
                          alt="Oblique Front Right"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Oblique Front Right</p>
                    </div>
                  ))}

                {lastImages?.iex_oblique_rear_right !== 0 &&
                  lastImages?.iex_oblique_rear_right?.length > 0 &&
                  lastImages?.iex_oblique_rear_right?.map((image) => (
                    <div className="col-lg-3 col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_oblique_rear_right/${image?.inspectionimage_name}`}
                          alt="Oblique Front Right"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Oblique Rear Right</p>
                    </div>
                  ))}

                {lastImages?.iex_trunk_open_pic !== 0 &&
                  lastImages?.iex_trunk_open_pic?.length > 0 &&
                  lastImages?.iex_trunk_open_pic?.map((image) => (
                    <div className="col-lg-3 col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_trunk_open_pic/${image?.inspectionimage_name}`}
                          alt="Trunk Open"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Trunk Open</p>
                    </div>
                  ))}

                {lastImages?.iex_rear_view_image !== 0 &&
                  lastImages?.iex_rear_view_image?.length > 0 &&
                  lastImages?.iex_rear_view_image?.map((image) => (
                    <div className="col-lg-3 col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_rear_view_image/${image?.inspectionimage_name}`}
                          alt="Trunk Open"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Rear View Image</p>
                    </div>
                  ))}

                {lastImages?.iex_oblique_rear_left !== 0 &&
                  lastImages?.iex_oblique_rear_left?.length > 0 &&
                  lastImages?.iex_oblique_rear_left?.map((image) => (
                    <div className="col-lg-3 col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_oblique_rear_left/${image?.inspectionimage_name}`}
                          alt="Oblique Rear Left"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Oblique Rear Left</p>
                    </div>
                  ))}

                {lastImages?.iex_oblique_front_left !== 0 &&
                  lastImages?.iex_oblique_front_left?.length > 0 &&
                  lastImages?.iex_oblique_front_left?.map((image) => (
                    <div className="col-lg-3 col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_oblique_front_left/${image?.inspectionimage_name}`}
                          alt="Oblique Front Left"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Oblique Front Left</p>
                    </div>
                  ))}

                {lastImages?.iex_vehicle_keys !== 0 &&
                  lastImages?.iex_vehicle_keys?.length > 0 &&
                  lastImages?.iex_vehicle_keys?.map((image) => (
                    <div className="col-lg-3 col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_vehicle_keys/${image?.inspectionimage_name}`}
                          alt="Oblique Front Left"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Vehicle Keys / Remote</p>
                    </div>
                  ))}

                {lastImages?.iex_engine_compartment !== 0 &&
                  lastImages?.iex_engine_compartment?.length > 0 &&
                  lastImages?.iex_engine_compartment?.map((image) => (
                    <div className="col-lg-3 col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_engine_compartment/${image?.inspectionimage_name}`}
                          alt="Engine Compartment"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Engine Compartment</p>
                    </div>
                  ))}

                {lastImages?.iex_engine_number !== 0 &&
                  lastImages?.iex_engine_number?.length > 0 &&
                  lastImages?.iex_engine_number?.map((image) => (
                    <div className="col-lg-3 col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_engine_number/${image?.inspectionimage_name}`}
                          alt="Engine Number"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Engine Number</p>
                    </div>
                  ))}

                {lastImages?.iex_chassis_plate !== 0 &&
                  lastImages?.iex_chassis_plate?.length > 0 &&
                  lastImages?.iex_chassis_plate?.map((image) => (
                    <div className="col-lg-3 col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_chassis_plate/${image?.inspectionimage_name}`}
                          alt="Chassis Plate"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Chassis Plate</p>
                    </div>
                  ))}

                {lastImages?.iex_chassis_number !== 0 &&
                  lastImages?.iex_chassis_number?.length > 0 &&
                  lastImages?.iex_chassis_number?.map((image) => (
                    <div className="col-lg-3 col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_chassis_number/${image?.inspectionimage_name}`}
                          alt="Chassis Number"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Chassis Number</p>
                    </div>
                  ))}

                {lastImages?.iex_front_interior !== 0 &&
                  lastImages?.iex_front_interior?.length > 0 &&
                  lastImages?.iex_front_interior?.map((image) => (
                    <div className="col-lg-3 col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_front_interior/${image?.inspectionimage_name}`}
                          alt=""
                          srcSet="Front Interior"
                        />
                      </div>
                      <p className="text-center">Front Interior</p>
                    </div>
                  ))}

                {lastImages?.iex_back_interior !== 0 &&
                  lastImages?.iex_back_interior?.length > 0 &&
                  lastImages?.iex_back_interior?.map((image) => (
                    <div className="col-lg-3 col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_back_interior/${image?.inspectionimage_name}`}
                          alt="Back Interior"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Back Interior</p>
                    </div>
                  ))}

                {lastImages?.iex_fourwayswitchimage !== 0 &&
                  lastImages?.iex_fourwayswitchimage?.length > 0 &&
                  lastImages?.iex_fourwayswitchimage?.map((image) => (
                    <div className="col-lg-3 col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_fourwayswitchimage/${image?.inspectionimage_name}`}
                          alt="4 Way Switch"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">4 Way Switch</p>
                    </div>
                  ))}


{lastImages?.iex_odo_meter !== 0 &&
                  lastImages?.iex_odo_meter?.length > 0 &&
                  lastImages?.iex_odo_meter?.map((image) => (
                    <div className="col-lg-3 col-6">
                      <img
                        className="img-fluid w-100 p-1 rounded"
                        src={`${imageURL}/iex_odo_meter/${image?.inspectionimage_name}`}
                        alt="Back Interior"
                        srcSet=""
                      />
                      <p className="text-center">Odo Meter</p>
                    </div>
                  ))}


                {lastImages?.iex_extra_images !== 0 &&
                  lastImages?.iex_extra_images?.length > 0 &&
                  lastImages?.iex_extra_images?.map((image) => (
                    <div className="col-lg-3 col-6">
                      <img
                        className="img-fluid w-100 p-1 rounded"
                        src={`${imageURL}/iex_extra_images/${image?.inspectionimage_name}`}
                        alt="Back Interior"
                        srcSet=""
                      />
                      <p className="text-center">Extra Image</p>
                    </div>
                  ))}
              </div>

              <div className="row mt-5">
                <div>
                  <h5 className="report-customer-info fs-4 fw-600 fw-700">
                    Comments
                  </h5>
                </div>
                <div>
                  {/* <p className="CustomerData">
                    Engine num not visible plastic cover fitting Front side
                    fully packed with plastic fittings and shields so some check
                    points not visible accident check point not fully check
                    plastic cover fitting engine room plastic cover pack fitting
                    boot floor boot lock pillar not fully check mate fitting
                    bonnet siller creck rear right left tyre date NA
                  </p> */}
                  <ul className="list-unstyled">
                    <li>{reportData?.initial?.iinitial_comment}</li>

                    <li>{reportData?.initial?.inspector_comment}</li>
                  </ul>
                </div>
                <div>
                  <h5 className="report-customer-info fs-5 fw-600 fw-700">
                    Disclaimer:
                  </h5>
                </div>
                <div>
                  <p>
                    This report estimates the vehicle's condition on the
                    certification date, based on visible parts' working status.
                    Unseen or uninspected parts are not considered. Odometer
                    reading is relied upon, and famewheels.com is not
                    accountable for its verification. The report is subjective,
                    not a commitment on the car's condition. famewheels.com and
                    its associates are not liable for any consequences arising
                    from its use.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container mobileView mb-5">
          <div>
            <div className="row inspectionMain align-items-center mt-5">
              {/* <div className="col-lg-12 text-end border-bottom pb-3 mb-4 print-hidden">
              <Button
                onClick={handlePrint}
                variant="contained"
                className=" bgSecondary text-white"
              >
                <i className="fa-solid fa-print me-2"></i>
                Print Report
              </Button>
            </div> */}
              <div className="col-lg-9 col-12 text-center">
                <h4 className="fw-800 fs-5">
                  CAR INSPECTION <span className="color-secondary">REPORT</span>
                </h4>
              </div>
              <div className="col-lg-3 col-12 text-center">
                <img className="inspectReportLogo mb-2" src={logo} alt="Icon" />
              </div>
              <div className="col-lg-9 flex col-12 text-center w-100 d-flex justify-content-end ">
                <Button
                  onClick={handlePrint}
                  variant="contained"
                  sx={{ fontSize: 12, px: 2, py: 0.5 }}
                  className=" text-black mt-2 rounded-pill print-hidden printBtn"
                >
                  <i className="fa-solid fa-print me-2"></i>
                  Print
                </Button>
              </div>

              <h4 className="text-danger text-center mb-2 mt-4 fw-bold">
                {reportData?.cartitle?.make_name}{" "}
                {reportData?.cartitle?.model_name}{" "}
                {reportData?.cartitle?.year_name}
              </h4>
            </div>
            <div className="row flex-column-reverse mb-5 pb-3 align-items-center">
              <div className="col-md-6 col-12 d-flex justify-content-center align-items-center">
                {lastImages?.iex_front_view_image !== 0 ? (
                  lastImages?.iex_front_view_image?.length > 0 && (
                    <img
                      className="inspectReportCar mb-2"
                      src={`${imageURL}/iex_front_view_image/${lastImages?.iex_front_view_image[0]?.inspectionimage_name}`}
                      alt={"car-image"}
                    />
                  )
                ) : (
                  <img
                    className="inspectReportCar mb-2"
                    src={carImage}
                    alt={"car-image"}
                  />
                )}
              </div>
              <div className="col-md-6 col-12 text-center ratingBar">
                <h4 className="progress-heading fs-3 color-secondary">
                  {reportData?.initial?.iinitial_vehicletitle}
                </h4>
                <div className=" text-center d-flex flex-column justify-content-between h-100 mb-5">
                  <div className=" position-relative">
                    <CircularProgress
                      variant="determinate"
                      value={percentage?.allpercentage * 10}
                      maxValue={100}
                      size={150}
                      thickness={2}
                      style={{ borderRadius: "50%", color: "#b80505" }} // Make it circular by setting the border radius to 50%
                    />
                    <h4 className="report-customer-info fw-600 progresValuePercent">
                      {percentage?.allpercentage} / 10
                    </h4>
                  </div>
                </div>
                <h4 className="progress-heading pt-2 fs-5">Overall Rating</h4>
              </div>
            </div>
          </div>
          <div>
            {/* <div className="row">
              <div className="col-lg-12 my-4 text-center">
                <h4 className="fw-700">Customer Information</h4>
              </div>
              <div className="col-lg-12 col-12 d-flex pb-3">
                <div className="col-lg-2">
                  <h5 className="report-customer-info">Seller Name : </h5>{" "}
                </div>
                <div className="col-lg-10">
                  <div className="borderbottom ms-3">
                    <h5 className="report-customer-info">
                      {reportData?.initial?.iinitial_sellername}
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-12 d-flex pb-3">
                <div className="col-lg-2">
                  <h5 className="report-customer-info">Seller Number : </h5>{" "}
                </div>
                <div className="col-lg-10">
                  <div className="borderbottom ms-3">
                    <h5 className="report-customer-info">
                      {reportData?.initial?.iinitial_sellerno}
                    </h5>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row mt-4">
              <div className="col-lg-12 my-4">
                <h4 className="fw-700">Car Detail</h4>
              </div>
              <div className="col-md-4  col-6">
                <h5 className="report-customer-info">engine type</h5>
                <p className="CustomerData">
                  {reportData?.initial?.iinitial_enginetype}
                </p>
              </div>
              <div className="col-md-4 col-6">
                <h5 className="report-customer-info">mileage</h5>
                <p className="CustomerData">
                  {reportData?.initial?.iinitial_mileage}
                </p>
              </div>
              <div className="col-md-4 col-6">
                <h5 className="report-customer-info">inspection date</h5>
                <p className="CustomerData">
                  {reportData?.initial?.iinitial_date}
                </p>
              </div>
              <div className="col-md-4 col-6">
                <h5 className="report-customer-info">engine no</h5>
                <p className="CustomerData">
                  {reportData?.initial?.iinitial_engineno}
                </p>
              </div>

              <div className="col-md-4 col-6">
                <h5 className="report-customer-info">transmission type</h5>
                <p className="CustomerData">
                  {reportData?.initial?.iinitial_transmission}
                </p>
              </div>
              <div className="col-md-4 col-6">
                <h5 className="report-customer-info">CNG Install</h5>
                <p className="CustomerData">
                  {reportData?.initial?.iinitial_cnginstall}
                </p>
              </div>
              <div className="col-md-4 col-6">
                <h5 className="report-customer-info">engine capacity</h5>
                <p className="CustomerData">
                  {reportData?.initial?.iinitial_enginecapacity}
                </p>
              </div>
              <div className="col-md-4 col-6">
                <h5 className="report-customer-info">chassis no</h5>
                <p className="CustomerData">
                  {reportData?.initial?.iinitial_chassisno}
                </p>
              </div>
              <div className="col-md-4 col-6">
                <h5 className="report-customer-info">registered city</h5>

                {cities?.map((city) => (
                  <p className="CustomerData">
                    {city?.cityID === reportData?.initial?.city_id &&
                      city?.cityName}
                  </p>
                ))}
              </div>

              <div className="col-md-4 col-6">
                <h5 className="report-customer-info">registered Year</h5>
                {years?.map((year) => (
                  <p className="CustomerData">
                    {year?.yearId === reportData?.initial?.year_id &&
                      year?.year}
                  </p>
                ))}{" "}
              </div>
              <div className="col-md-4 col-6">
                <h5 className="report-customer-info">drive type</h5>
                <p className="CustomerData">
                  {reportData?.initial?.iinitial_drivetype}
                </p>
              </div>
              <div className="col-md-4 col-6">
                <h5 className="report-customer-info">registration no</h5>
                <p className="CustomerData">
                  {reportData?.initial?.iinitial_registeredno}
                </p>
              </div>
              <div className="col-md-4 col-6">
                <h5 className="report-customer-info">Colour</h5>
                <p className="CustomerData">
                  {reportData?.initial?.iinitial_color}
                </p>
              </div>

              <div className="row mt-5 pt-3">
                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.acheater}
                    ProgressHeading="AC / HEATER"
                    size={100}
                    thickness={2}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.enginetransmission}
                    ProgressHeading="ENGINE / TRANSMISSION / CLUTCH"
                    size={100}
                    thickness={2}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.exteriorbasic}
                    ProgressHeading="EXTERIOR"
                    size={100}
                    thickness={2}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.exteriorvalue?.dfpercent}
                    ProgressHeading="SKELETON"
                    size={100}
                    thickness={2}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.exteriorvalue?.acpercent}
                    ProgressHeading="ACCIDENT CHECKLIST"
                    size={100}
                    thickness={2}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.brakes}
                    ProgressHeading="BRAKES"
                    size={100}
                    thickness={2}
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.suspension}
                    ProgressHeading="SUSPENSION/STEERING"
                    size={100}
                    thickness={2}
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.interior}
                    ProgressHeading="INTERIOR"
                    size={100}
                    thickness={2}
                  />
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.electronics}
                    ProgressHeading="ELECTRICAL & ELECTRONICS"
                    size={100}
                    thickness={2}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-6">
                  <CircularRangeSlider
                    minValue={0}
                    maxValue={100}
                    defaultValue={percentage?.tyres}
                    ProgressHeading="TYRES"
                    size={100}
                    thickness={2}
                  />
                </div>
              </div>
              <div className="col-12 mt-4">
                <div className="d-flex justify-content-between gap-3 align-items-center">
                  <h4 className="fw-700">Exterior Condition</h4>

                  <div className="d-flex justify-content-center align-items-center gap-2 p-1 rounded border mb-3">
                    <p className="p-0 m-0" style={{ fontSize: "14px" }}>
                      {checked ? "Hide" : "Show"} Problems
                    </p>
                    <Switch
                      checked={checked}
                      color="error"
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>

                  {/* <Button className="bgSecondary color-white" onClick={()=>setShowDefects(!showDefects)}>{showDefects?"Hide":"Show"} Problems</Button> */}
                </div>{" "}
              </div>
              <div className="row">
                <div className="col-lg-8">
                  {checked ? (
                    <div className="inspectionLegends position-relative mb-4">
                      <img
                        className="carWrap img-fluid"
                        src={CarWrap}
                        alt=""
                        srcSet=""
                      />
                      <div
                        onClick={() =>
                          isImages?.ibody_bonnetimage === 1 &&
                          reportData?.bonnet_defect?.length <= 0 &&
                          handleImage(`ibody_bonnetimage`)
                        }
                      >
                        {reportData?.bonnet_defect?.length > 0 &&
                          reportData?.bonnet_defect?.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.ibody_frontbumperimage === 1 &&
                          reportData?.frontBumper_defect?.length <= 0 &&
                          handleImage(`ibody_frontbumperimage`)
                        }
                      >
                        {reportData?.frontBumper_defect?.length > 0 &&
                          reportData?.frontBumper_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.iac_roofimage === 1 &&
                          reportData?.roof_defect?.length <= 0 &&
                          handleImage(`iac_roofimage`)
                        }
                      >
                        {reportData?.roof_defect?.length > 0 &&
                          reportData?.roof_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.iac_trunkimage === 1 &&
                          reportData?.trunk_defect?.length <= 0 &&
                          handleImage(`iac_trunkimage`)
                        }
                      >
                        {reportData?.trunk_defect?.length > 0 &&
                          reportData?.trunk_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.iac_rearbumperimage === 1 &&
                          reportData?.rearbumper_defect?.length <= 0 &&
                          handleImage(`iac_rearbumperimage`)
                        }
                      >
                        {reportData?.rearbumper_defect?.length > 0 &&
                          reportData?.rearbumper_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.iac_frontleftdoorimage === 1 &&
                          reportData?.frontleftdoor_defect?.length <= 0 &&
                          handleImage(`iac_frontleftdoorimage`)
                        }
                      >
                        {reportData?.frontleftdoor_defect?.length > 0 &&
                          reportData?.frontleftdoor_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.iac_rearleftdoorimage === 1 &&
                          reportData?.rearleftdoor_defect?.length <= 0 &&
                          handleImage(`iac_rearleftdoorimage`)
                        }
                      >
                        {reportData?.rearleftdoor_defect?.length > 0 &&
                          reportData?.rearleftdoor_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.iac_frontleftfenderimage === 1 &&
                          reportData?.frontleftfender_defect?.length <= 0 &&
                          handleImage(`iac_frontleftfenderimage`)
                        }
                      >
                        {reportData?.frontleftfender_defect?.length > 0 &&
                          reportData?.frontleftfender_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.ibody_offfrontwingimage === 1 &&
                          reportData?.offfrontwing_defect?.length <= 0 &&
                          handleImage(`ibody_offfrontwingimage`)
                        }
                      >
                        {reportData?.offfrontwing_defect?.length > 0 &&
                          reportData?.offfrontwing_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.ibody_offfrontdoorimage === 1 &&
                          reportData?.offfrontdoor_defect?.length <= 0 &&
                          handleImage(`ibody_offfrontdoorimage`)
                        }
                      >
                        {reportData?.offfrontdoor_defect?.length > 0 &&
                          reportData?.offfrontdoor_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                      <div
                        onClick={() =>
                          isImages?.ibody_offbackdoorimage === 1 &&
                          reportData?.offbackdoor_defect?.length <= 0 &&
                          handleImage(`ibody_offbackdoorimage`)
                        }
                      >
                        {reportData?.offbackdoor_defect?.length > 0 &&
                          reportData?.offbackdoor_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>

                      <div
                        onClick={() =>
                          isImages?.ibody_offbackwingimage === 1 &&
                          reportData?.offbackwing_defect?.length <= 0 &&
                          handleImage(`ibody_offbackwingimage`)
                        }
                      >
                        {reportData?.offbackwing_defect?.length > 0 &&
                          reportData?.offbackwing_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>

                      <div
                        onClick={() =>
                          isImages?.iac_rearleftfenderimage === 1 &&
                          reportData?.rearleftfender_defect?.length <= 0 &&
                          handleImage(`iac_rearleftfenderimage`)
                        }
                      >
                        {reportData?.rearleftfender_defect?.length > 0 &&
                          reportData?.rearleftfender_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>

                      <div
                        onClick={() =>
                          isImages?.ibody_rightfootbandimage === 1 &&
                          reportData?.rightfootboard_defect?.length <= 0 &&
                          handleImage(`ibody_rightfootbandimage`)
                        }
                      >
                        {reportData?.rightfootboard_defect?.length > 0 &&
                          reportData?.rightfootboard_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>

                      <div
                        onClick={() =>
                          isImages?.iac_leftfootboardimage === 1 &&
                          reportData?.leftfootboard_defect?.length <= 0 &&
                          handleImage(`iac_leftfootboardimage`)
                        }
                      >
                        {reportData?.leftfootboard_defect?.length > 0 &&
                          reportData?.leftfootboard_defect.map((defect) => {
                            return (
                              <button
                                className="report_defect"
                                onClick={() =>
                                  handleDefecrGet(
                                    defect.defect_place,
                                    defect.defect_type
                                  )
                                }
                              >
                                {defect.defect_type}
                              </button>
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    <div className="inspectionLegends position-relative mb-4">
                      <img
                        className="carWrap img-fluid"
                        src={CarWrap}
                        alt=""
                        srcSet=""
                      />
                      <div
                        onClick={() =>
                          isImages?.ibody_bonnetimage === 1 &&
                          handleImage(`ibody_bonnetimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.ibody_frontbumperimage === 1 &&
                          handleImage(`ibody_frontbumperimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.iac_roofimage === 1 &&
                          handleImage(`iac_roofimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.iac_trunkimage === 1 &&
                          handleImage(`iac_trunkimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.iac_rearbumperimage === 1 &&
                          handleImage(`iac_rearbumperimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.iac_frontleftdoorimage === 1 &&
                          handleImage(`iac_frontleftdoorimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.iac_rearleftdoorimage === 1 &&
                          handleImage(`iac_rearleftdoorimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.iac_frontleftfenderimage === 1 &&
                          handleImage(`iac_frontleftfenderimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.ibody_offfrontwingimage === 1 &&
                          handleImage(`ibody_offfrontwingimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.ibody_offfrontdoorimage === 1 &&
                          handleImage(`ibody_offfrontdoorimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.ibody_offbackdoorimage === 1 &&
                          handleImage(`ibody_offbackdoorimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.ibody_offbackwingimage === 1 &&
                          handleImage(`ibody_offbackwingimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.iac_rearleftfenderimage === 1 &&
                          handleImage(`iac_rearleftfenderimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.ibody_rightfootbandimage === 1 &&
                          handleImage(`ibody_rightfootbandimage`)
                        }
                      ></div>

                      <div
                        onClick={() =>
                          isImages?.iac_leftfootboardimage === 1 &&
                          handleImage(`iac_leftfootboardimage`)
                        }
                      ></div>
                    </div>
                  )}
                </div>
                <div className="col-lg-4 print-hidden d-flex justify-content-center align-items-center">
                  {defectData && (
                    <div
                      className=" border-2 border-danger rounded inspectionImg11 p-3"
                      style={{ border: "2px solid #b80505" }}
                    >
                      <img
                        className="img-fluid object-fit-contain shadow"
                        style={{ height: 210 }}
                        src={`${defectURL}${defectData?.defect_place}/${defectData?.defect_type}-${defectData?.defect_value}/${defectData?.defect_image}`}
                        alt={defectData?.defect_image}
                        srcSet=""
                      />
                      {!defectData?.defect_image && (
                        <i className="fs-6 fw-600 text-danger-emphasis">
                          No Image
                        </i>
                      )}

                      <div className="text-center mt-2">
                        <p>{defectData?.defect_type}</p>
                      </div>
                    </div>
                  )}
                  {dataLoad && (
                    <div>
                      <InnerLoader />
                    </div>
                  )}
                </div>
              </div>

              <div className="row mt-3">
                <div
                  className="col-12 pt-3"
                  style={{ backgroundColor: "#efefef", borderRadius: "10px" }}
                >
                  <div className="row">
                    {legends &&
                      legends?.map((item, index) => (
                        <div key={item?.id} className="col-md-3 col-6 fs-6">
                          <p className="aboutDefect">
                            <strong> {item?.value} </strong>
                            {item?.name}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="row">
              <div className="col-12">
                <div
                  className="accordion reportAccordins"
                  id="accordionExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Body frame accident checklist
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.exteriorvalue?.acpercent}%
                          </h5>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                    >
                      <div className="accordion-body">
                        {reportData?.body?.ibody_rediatorcoresupport !== null &&
                          reportData?.body?.ibody_rediatorcoresupport !== "" &&
                          reportData?.body?.ibody_rediatorcoresupport !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_rediatorcoresupport === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Radiator Core Support
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_radiatorcoresupportimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ibody_radiatorcoresupportimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rediatorcoresupport}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_rrightstruttower !== null &&
                          reportData?.body?.ibody_rrightstruttower !== "" &&
                          reportData?.body?.ibody_rrightstruttower !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_rrightstruttower === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Strut Tower Apon
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_rightstrutpoweraponimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ibody_rightstrutpoweraponimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rrightstruttower}
                                </h5>
                              </div>
                            </div>
                          )}
                        {reportData?.body?.ibody_leftstruttower !== null &&
                          reportData?.body?.ibody_leftstruttower !== "" &&
                          reportData?.body?.ibody_leftstruttower !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_leftstruttower === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Strut Tower Apon
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_leftstrutpoweraponimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ibody_leftstrutpoweraponimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_leftstruttower}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_rightfrontrail !== null &&
                          reportData?.body?.ibody_rightfrontrail !== "" &&
                          reportData?.body?.ibody_rightfrontrail !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_rightfrontrail === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Front Rail
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_rightfrontrailimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_rightfrontrailimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rightfrontrail}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_rleftfrontrail !== null &&
                          reportData?.body?.ibody_rleftfrontrail !== "" &&
                          reportData?.body?.ibody_rleftfrontrail !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_rleftfrontrail === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Front Rail
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_leftfrontrailimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_leftfrontrailimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rleftfrontrail}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_cowlpanelfirewall !== null &&
                          reportData?.body?.ibody_cowlpanelfirewall !== "" &&
                          reportData?.body?.ibody_cowlpanelfirewall !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_cowlpanelfirewall === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Cowl Panel Firewall
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_cowlpanelfirewallimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ibody_cowlpanelfirewallimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_cowlpanelfirewall}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_rightapillar !== null &&
                          reportData?.body?.ibody_rightapillar !== "" &&
                          reportData?.body?.ibody_rightapillar !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_rightapillar === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right A Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_rightAPillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_rightAPillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rightapillar}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_leftapiller !== null &&
                          reportData?.ac?.iac_leftapiller !== "" &&
                          reportData?.ac?.iac_leftapiller !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue?.iac_leftapiller === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left A Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_leftapillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_leftapillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_leftapiller}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_frontbpillar !== null &&
                          reportData?.body?.ibody_frontbpillar !== "" &&
                          reportData?.body?.ibody_frontbpillar !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue?.ibdy_frontbpillar ===
                                1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right B Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_rightbpillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_rightbpillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_frontbpillar}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_leftbpiller !== null &&
                          reportData?.ac?.iac_leftbpiller !== "" &&
                          reportData?.ac?.iac_leftbpiller !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue?.iac_leftbpiller === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left B Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_leftbpillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_leftbpillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_leftbpiller}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_rightcpillar !== null &&
                          reportData?.body?.ibody_rightcpillar !== "" &&
                          reportData?.body?.ibody_rightcpillar !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_rightcpillar === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right C Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_rightcpillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_rightcpillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rightcpillar}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_leftcpillar !== null &&
                          reportData?.ac?.iac_leftcpillar !== "" &&
                          reportData?.ac?.iac_leftcpillar !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue?.iac_leftcpillar === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left C Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_leftcpillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_leftcpillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_leftcpillar}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_rightbdpillar !== null &&
                          reportData?.body?.ibody_rightbdpillar !== "" &&
                          reportData?.body?.ibody_rightbdpillar !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_rightbdpillar === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right D Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_rightdpillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_rightdpillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rightbdpillar}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_leftdpiller !== null &&
                          reportData?.ac?.iac_leftdpiller !== "" &&
                          reportData?.ac?.iac_leftdpiller !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue?.iac_leftdpiller === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left D Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_leftdpillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_leftdpillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_leftdpiller}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_bootfloor !== null &&
                          reportData?.ac?.iac_bootfloor !== "" &&
                          reportData?.ac?.iac_bootfloor !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue?.iac_bootfloor === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Boot Floor
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_bootfloorimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_bootfloorimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_bootfloor}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_bootlockpillartyresize !== null &&
                          reportData?.ac?.iac_bootlockpillartyresize !== "" &&
                          reportData?.ac?.iac_bootlockpillartyresize !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.iac_bootlockpillartyresize === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Boot Lock Pillar
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_bootlockpillarimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_bootlockpillarimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_bootlockpillartyresize}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_rearsubframe !== null &&
                          reportData?.ac?.iac_rearsubframe !== "" &&
                          reportData?.ac?.iac_rearsubframe !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue?.iac_rearsubframe ===
                                1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Sub Frame
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_rearsubframeimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_rearsubframeimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_rearsubframe}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_frontsubframe !== null &&
                          reportData?.body?.ibody_frontsubframe !== "" &&
                          reportData?.body?.ibody_frontsubframe !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.accidentvalue
                                  ?.ibody_frontsubframe === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Sub Frame
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_frontsubframeimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`ibody_frontsubframeimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_frontsubframe}
                                </h5>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading2">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapse2"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapse2"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Engine / Transmission / Clutch
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.enginetransmission}%
                          </h5>
                        </div>
                      </button>
                    </h2>

                    <div
                      id="panelsStayOpen-collapse2"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                    >
                      <div className="accordion-body">
                        <h4 className="text-secondary text-center fs-6">
                          Fluids/Filters Check
                        </h4>

                        {reportData?.body?.ibody_engineoillevel !== null &&
                          reportData?.body?.ibody_engineoillevel !== "" &&
                          reportData?.body?.ibody_engineoillevel !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.ibody_engineoillevel === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Engine Oil Level
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_engineoillevelimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_engineoillevelimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_engineoillevel}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_engageoilleakage !==
                          null &&
                          reportData?.suspention?.iss_engageoilleakage !== "" &&
                          reportData?.suspention?.iss_engageoilleakage !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.iss_engageoilleakage === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Engine Oil Leakage
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_engineoilleakageimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_engineoilleakageimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_engageoilleakage}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_transmissionoilleakage !==
                          null &&
                          reportData?.suspention?.iss_transmissionoilleakage !==
                            "" &&
                          reportData?.suspention?.iss_transmissionoilleakage !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.iss_transmissionoilleakage === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Transmission Oil Leakage
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_transmissionoilleakageimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(
                                        `iss_transmissionoilleakageimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.suspention
                                      ?.iss_transmissionoilleakage
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_powersteeringoilleakage !==
                          null &&
                          reportData?.suspention
                            ?.iss_powersteeringoilleakage !== "" &&
                          reportData?.suspention
                            ?.iss_powersteeringoilleakage !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.iss_powersteeringoilleakage === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Power Steering Oil Leakage
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_powersteeringoillevelimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(
                                        `iss_powersteeringoillevelimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.suspention
                                      ?.iss_powersteeringoilleakage
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_coolantleakage !== null &&
                          reportData?.suspention?.iss_coolantleakage !== "" &&
                          reportData?.suspention?.iss_coolantleakage !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.iss_coolantleakage ===
                                1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Coolant Leakage
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_coolantleakageimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_coolantleakageimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_coolantleakage}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_breakoilleakage !== null &&
                          reportData?.suspention?.iss_breakoilleakage !== "" &&
                          reportData?.suspention?.iss_breakoilleakage !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.iss_breakoilleakage === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Brake Oil Leakage
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_brakeoilleakageimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`iss_brakeoilleakageimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_breakoilleakage}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center mt-2 fs-5">
                          Mechanical Check
                        </h4>

                        {reportData?.body?.ibody_fanbelts !== null &&
                          reportData?.body?.ibody_fanbelts !== "" &&
                          reportData?.body?.ibody_fanbelts !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.ibody_fanbelts === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Belts (Fan)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_beltsfanimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_beltsfanimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_fanbelts}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_acbelts !== null &&
                          reportData?.body?.ibody_acbelts !== "" &&
                          reportData?.body?.ibody_acbelts !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.ibody_acbelts === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Belts (Ac)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_beltsacimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_beltsacimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_acbelts}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_wires !== null &&
                          reportData?.body?.ibody_wires !== "" &&
                          reportData?.body?.ibody_wires !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.ibody_wires === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Wires (Wiring Harness)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_wiringharnessimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_wiringharnessimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_wires}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_engineblowmanualcheack !==
                          null &&
                          reportData?.suspention?.iss_engineblowmanualcheack !==
                            "" &&
                          reportData?.suspention?.iss_engineblowmanualcheack !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.iss_engineblowmanualcheack === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Engine Blow (Manual Check)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_engineblowimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_engineblowimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.suspention
                                      ?.iss_engineblowmanualcheack
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_enginenoise !== null &&
                          reportData?.suspention?.iss_enginenoise !== "" &&
                          reportData?.suspention?.iss_enginenoise !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.iss_enginenoise === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Engine Noise
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_enginenoiseimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_enginenoiseimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_enginenoise}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_enginevibration !== null &&
                          reportData?.suspention?.iss_enginevibration !== "" &&
                          reportData?.suspention?.iss_enginevibration !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.iss_enginevibration === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Engine Vibration
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_enginevibrationimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`iss_enginevibrationimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_enginevibration}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_enginemounts !== null &&
                          reportData?.suspention?.iss_enginemounts !== "" &&
                          reportData?.suspention?.iss_enginemounts !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.iss_enginemounts === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Engine Mounts
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_enginemountsimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_enginemountsimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_enginemounts}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_pulleysadjuster !== null &&
                          reportData?.suspention?.iss_pulleysadjuster !== "" &&
                          reportData?.suspention?.iss_pulleysadjuster !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.iss_pulleysadjuster === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Pulleys (Adjuster)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_pulleysadjusteriimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_pulleysadjusteriimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_pulleysadjuster}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_hoses !== null &&
                          reportData?.body?.ibody_hoses !== "" &&
                          reportData?.body?.ibody_hoses !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.ibody_hoses === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Hoses</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_hosesimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_hosesimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_hoses}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center mt-2 fs-6">
                          Exhaust Check
                        </h4>

                        {reportData?.suspention?.iss_exhaustsound !== null &&
                          reportData?.suspention?.iss_exhaustsound !== "" &&
                          reportData?.suspention?.iss_exhaustsound !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.iss_exhaustsound === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Exhaust Sound
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_exhaustsoundimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_exhaustsoundimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_exhaustsound}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center mt-2 fs-6">
                          Engine Cooling System
                        </h4>

                        {reportData?.body?.ibody_radiator !== null &&
                          reportData?.body?.ibody_radiator !== "" &&
                          reportData?.body?.ibody_radiator !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.ibody_radiator === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Radiator</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_radiatorimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_radiatorimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_radiator}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_suctionfan !== null &&
                          reportData?.suspention?.iss_suctionfan !== "" &&
                          reportData?.suspention?.iss_suctionfan !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields?.iss_suctionfan === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Suction Fan
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_sunctionfanimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_sunctionfanimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_suctionfan}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center mt-2 fs-6">
                          Transmission Check / Power Train
                        </h4>
                        <h4 className="text-secondary text-center mt-2 fs-6">
                          Engine Electronics
                        </h4>

                        {reportData?.ac?.iac_starteroperation !== null &&
                          reportData?.ac?.iac_starteroperation !== "" &&
                          reportData?.ac?.iac_starteroperation !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.enginefields
                                  ?.iac_starteroperation === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Starter Operation
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_starteroperationimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`iac_starteroperationimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_starteroperation}
                                </h5>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading3">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapse3"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapse3"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Brakes
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.brakes}%
                          </h5>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapse3"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                    >
                      <div className="accordion-body">
                        <h4 className="text-secondary text-center fs-6">
                          Machenical Check
                        </h4>

                        {reportData?.test?.itest_frontrightdisc !== null &&
                          reportData?.test?.itest_frontrightdisc !== "" &&
                          reportData?.test?.itest_frontrightdisc !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.brakefields
                                  ?.itest_frontrightdisc === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Disc
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontrightdiscimages === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontrightdiscimages`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontrightdisc}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontleftdisc !== null &&
                          reportData?.test?.itest_frontleftdisc !== "" &&
                          reportData?.test?.itest_frontleftdisc !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.brakefields?.itest_frontleftdisc ===
                                1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Disc
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontleftdiscimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontleftdiscimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontleftdisc}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontrightbrakepad !== null &&
                          reportData?.test?.itest_frontrightbrakepad !== "" &&
                          reportData?.test?.itest_frontrightbrakepad !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.brakefields
                                  ?.itest_frontrightbrakepad === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Brake Pad
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontrightbrakepadimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `itest_frontrightbrakepadimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontrightbrakepad}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontlefttyrepad !== null &&
                          reportData?.test?.itest_frontlefttyrepad !== "" &&
                          reportData?.test?.itest_frontlefttyrepad !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.brakefields
                                  ?.itest_frontlefttyrepad === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Brake Pad
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontlefttyrepad === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontlefttyrepad`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontlefttyrepad}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_parking_handbrake !== null &&
                          reportData?.tyre?.ityre_parking_handbrake !== "" &&
                          reportData?.tyre?.ityre_parking_handbrake !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.brakefields
                                  ?.ityre_parking_handbrake === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Parking / Hand Brake
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_parkingimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_parkingimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_parking_handbrake}
                                </h5>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading6">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapse6"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapse6"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Suspension / Steering
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.suspension}%
                          </h5>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapse6"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                    >
                      <div className="accordion-body">
                        <h4 className="text-secondary text-center fs-6">
                          Front Suspension
                        </h4>

                        {reportData?.interior?.ii_steeringwheelplay !== null &&
                          reportData?.interior?.ii_steeringwheelplay !== "" &&
                          reportData?.interior?.ii_steeringwheelplay !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.ii_steeringwheelplay === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Steering Wheel Play
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ii_steeringwheelplayimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`ii_steeringwheelplayimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.interior?.ii_steeringwheelplay}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rightballjoint !== null &&
                          reportData?.test?.itest_rightballjoint !== "" &&
                          reportData?.test?.itest_rightballjoint !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_rightballjoint === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Ball Joint
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rightballjointimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`itest_rightballjointimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rightballjoint}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_leftballjoint !== null &&
                          reportData?.test?.itest_leftballjoint !== "" &&
                          reportData?.test?.itest_leftballjoint !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_leftballjoint === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Ball Joint
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_leftballjointimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_leftballjointimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_leftballjoint}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rearzlinks !== null &&
                          reportData?.test?.itest_rearzlinks !== "" &&
                          reportData?.test?.itest_rearzlinks !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_rearzlinks === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Z Links
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rightzlinksimages === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`itest_rightzlinksimages`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearzlinks}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_leftzlinks !== null &&
                          reportData?.test?.itest_leftzlinks !== "" &&
                          reportData?.test?.itest_leftzlinks !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_leftzlinks === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Z Links
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_leftzlinksimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`itest_leftzlinksimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_leftzlinks}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_righttieroadend !== null &&
                          reportData?.test?.itest_righttieroadend !== "" &&
                          reportData?.test?.itest_righttieroadend !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_righttieroadend === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Tie Rod End
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_righttierodendimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`itest_righttierodendimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_righttieroadend}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_lefttierodend !== null &&
                          reportData?.test?.itest_lefttierodend !== "" &&
                          reportData?.test?.itest_lefttierodend !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_lefttierodend === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Tie Rod End
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_lefttierodendimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_lefttierodendimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_lefttierodend}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontrightboots !== null &&
                          reportData?.test?.itest_frontrightboots !== "" &&
                          reportData?.test?.itest_frontrightboots !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_frontrightboots === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Boots
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontrightbootsimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontrightbootsimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontrightboots}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontleftboots !== null &&
                          reportData?.test?.itest_frontleftboots !== "" &&
                          reportData?.test?.itest_frontleftboots !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_frontleftboots === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Boots
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontleftbootsimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontleftbootsimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontleftboots}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontrightbushes !== null &&
                          reportData?.test?.itest_frontrightbushes !== "" &&
                          reportData?.test?.itest_frontrightbushes !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_frontrightbushes === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Bushes
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_fontrightbushesimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_fontrightbushesimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontrightbushes}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontleftbushes !== null &&
                          reportData?.test?.itest_frontleftbushes !== "" &&
                          reportData?.test?.itest_frontleftbushes !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_frontleftbushes === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Bushes
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontleftbushesimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontleftbushesimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontleftbushes}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontrightshock !== null &&
                          reportData?.test?.itest_frontrightshock !== "" &&
                          reportData?.test?.itest_frontrightshock !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_frontrightshock === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Shock
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontrightshockimages ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontrightshockimages`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontrightshock}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontleftshock !== null &&
                          reportData?.test?.itest_frontleftshock !== "" &&
                          reportData?.test?.itest_frontleftshock !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_frontleftshock === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Shock
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontleftshockimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontleftshockimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontleftshock}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6 mt-2">
                          Rear Suspension
                        </h4>

                        {reportData?.test?.itest_rearrightbushes !== null &&
                          reportData?.test?.itest_rearrightbushes !== "" &&
                          reportData?.test?.itest_rearrightbushes !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_rearrightbushes === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Right Bushes
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rearrightbushesimages ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_rearrightbushesimages`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearrightbushes}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rearleftbushes !== null &&
                          reportData?.test?.itest_rearleftbushes !== "" &&
                          reportData?.test?.itest_rearleftbushes !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_rearleftbushes === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Left Bushes
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rearleftbushesimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_rearleftbushesimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearleftbushes}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rearrightshock !== null &&
                          reportData?.test?.itest_rearrightshock !== "" &&
                          reportData?.test?.itest_rearrightshock !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_rearrightshock === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Right Shock
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rearrightshockimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_rearrightshockimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearrightshock}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rearleftshock !== null &&
                          reportData?.test?.itest_rearleftshock !== "" &&
                          reportData?.test?.itest_rearleftshock !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.suspensionfields
                                  ?.itest_rearleftshock === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear left Shock
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rearleftshockimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_rearleftshockimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearleftshock}
                                </h5>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading5">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapse5"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapse5"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Interior
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.interior}%
                          </h5>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapse5"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                    >
                      <div className="accordion-body">
                        <h4 className="text-secondary text-center fs-6">
                          Steering Controls
                        </h4>

                        {reportData?.tyre?.ityre_steeringwheelcondition !==
                          null &&
                          reportData?.tyre?.ityre_steeringwheelcondition !==
                            "" &&
                          reportData?.tyre?.ityre_steeringwheelcondition !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_steeringwheelcondition === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Steering Wheel Condition
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_steeringwheelconditionimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_steeringwheelconditionimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_steeringwheelcondition
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_steeringwheelbuttons !==
                          null &&
                          reportData?.tyre?.ityre_steeringwheelbuttons !== "" &&
                          reportData?.tyre?.ityre_steeringwheelbuttons !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_steeringwheelbuttons === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Steering Wheel Buttons
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_steeringwheelbuttonsimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_steeringwheelbuttonsimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_steeringwheelbuttons}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_horn !== null &&
                          reportData?.tyre?.ityre_horn !== "" &&
                          reportData?.tyre?.ityre_horn !== "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields?.ityre_horn === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Horn</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_hornimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_hornimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_horn}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_lightsleverswitch !== null &&
                          reportData?.tyre?.ityre_lightsleverswitch !== "" &&
                          reportData?.tyre?.ityre_lightsleverswitch !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_lightsleverswitch === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Lights Lever / Switch (High / Low Beams ,
                                  Indicators)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_lightsleverswitchimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_lightsleverswitchimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_lightsleverswitch}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_wiperswasherlever !== null &&
                          reportData?.tyre?.ityre_wiperswasherlever !== "" &&
                          reportData?.tyre?.ityre_wiperswasherlever !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_wiperswasherlever === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Wiper / Washer Lever (Washer,Speed)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_wiperswasherleverimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_wiperswasherleverimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_wiperswasherlever}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6 mt-2">
                          Mirrors
                        </h4>

                        {reportData?.body?.ibody_rightsidemirro !== null &&
                          reportData?.body?.ibody_rightsidemirro !== "" &&
                          reportData?.body?.ibody_rightsidemirro !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ibody_rightsidemirro === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Side Mirror
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_rightsidemirrorimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_rightsidemirrorimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rightsidemirro}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_leftsidemirror !== null &&
                          reportData?.ac?.iac_leftsidemirror !== "" &&
                          reportData?.ac?.iac_leftsidemirror !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.iac_leftsidemirror === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Side Mirror
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_leftsidemirrorimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_leftsidemirrorimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_leftsidemirror}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rearviewmirrordimmer !==
                          null &&
                          reportData?.tyre?.ityre_rearviewmirrordimmer !== "" &&
                          reportData?.tyre?.ityre_rearviewmirrordimmer !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_rearviewmirrordimmer === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear View Mirror Dimmer
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rearviewmirrordimmerimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_rearviewmirrordimmerimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rearviewmirrordimmer}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6 mt-2">
                          Seats
                        </h4>

                        {reportData?.tyre?.ityre_rightseatadjusterrecliner !==
                          null &&
                          reportData?.tyre?.ityre_rightseatadjusterrecliner !==
                            "" &&
                          reportData?.tyre?.ityre_rightseatadjusterrecliner !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_rightseatadjusterrecliner === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Seat Adjuster Recliner
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rightseatadjusterreclinerimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_rightseatadjusterreclinerimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_rightseatadjusterrecliner
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_leftseatadjusterrecliner !==
                          null &&
                          reportData?.tyre?.ityre_leftseatadjusterrecliner !==
                            "" &&
                          reportData?.tyre?.ityre_leftseatadjusterrecliner !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_leftseatadjusterrecliner === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Seat Adjuster Recliner
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_leftseatadjusterreclinerimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_leftseatadjusterreclinerimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_leftseatadjusterrecliner
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rightseatadjusterleartrack !==
                          null &&
                          reportData?.tyre?.ityre_rightseatadjusterleartrack !==
                            "" &&
                          reportData?.tyre?.ityre_rightseatadjusterleartrack !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_rightseatadjusterleartrack === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Seat Adjuster Lear Track
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rightseatadjusterleartrackimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_rightseatadjusterleartrackimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_rightseatadjusterleartrack
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_leftseatadjusterleartrack !==
                          null &&
                          reportData?.tyre?.ityre_leftseatadjusterleartrack !==
                            "" &&
                          reportData?.tyre?.ityre_leftseatadjusterleartrack !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_leftseatadjusterleartrack === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Seat Adjuster Lear Track
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_leftseatadjusterleartrackimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_leftseatadjusterleartrackimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_leftseatadjusterleartrack
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rightseatbelt !== null &&
                          reportData?.tyre?.ityre_rightseatbelt !== "" &&
                          reportData?.tyre?.ityre_rightseatbelt !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_rightseatbelt === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Seat Belt
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rightseatbeltimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_rightseatbeltimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rightseatbelt}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_leftseatbelt !== null &&
                          reportData?.tyre?.ityre_leftseatbelt !== "" &&
                          reportData?.tyre?.ityre_leftseatbelt !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_leftseatbelt === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Seat Belt
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_leftseatbeltimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_leftseatbeltimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_leftseatbelt}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rearseatbelts !== null &&
                          reportData?.tyre?.ityre_rearseatbelts !== "" &&
                          reportData?.tyre?.ityre_rearseatbelts !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_rearseatbelts === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Seat Belt
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rearseatbeltsimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_rearseatbeltsimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rearseatbelts}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_glovebox !== null &&
                          reportData?.tyre?.ityre_glovebox !== "" &&
                          reportData?.tyre?.ityre_glovebox !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields?.ityre_glovebox === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Glove Box
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_gloveboximage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_gloveboximage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_glovebox}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6 mt-2">
                          Power / Manual Windows & Central Locking
                        </h4>

                        {reportData?.tyre?.ityre_frontrightpowerwindow !==
                          null &&
                          reportData?.tyre?.ityre_frontrightpowerwindow !==
                            "" &&
                          reportData?.tyre?.ityre_frontrightpowerwindow !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_frontrightpowerwindow === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Power Window/Manual Lever
                                </p>
                              </div>

                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_frontrightpowerwindowimage ===
                                  1 && (
                                  <div className="col-lg-2 text-center col-6">
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(
                                          `ityre_frontrightpowerwindowimage`
                                        )
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  </div>
                                )}
                              </div>

                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_frontrightpowerwindow
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_frontleftpowerwindow !==
                          null &&
                          reportData?.tyre?.ityre_frontleftpowerwindow !== "" &&
                          reportData?.tyre?.ityre_frontleftpowerwindow !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_frontleftpowerwindow === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Power Window/Manual Lever
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_frontleftpowerwindowimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_frontleftpowerwindowimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>

                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_frontleftpowerwindow}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rearrightpowerwindow !==
                          null &&
                          reportData?.tyre?.ityre_rearrightpowerwindow !== "" &&
                          reportData?.tyre?.ityre_rearrightpowerwindow !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_rearrightpowerwindow === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Right Power Window/Manual Lever
                                </p>
                              </div>
                              {isImages?.ityre_rearrightpowerwindowimage ===
                                1 && (
                                <div className="col-lg-2 text-center col-6">
                                  <div className="col-lg-2 text-center col-6">
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(
                                          `ityre_rearrightpowerwindowimage`
                                        )
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  </div>
                                </div>
                              )}

                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rearrightpowerwindow}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rearleftpowerwindow !== null &&
                          reportData?.tyre?.ityre_rearleftpowerwindow !== "" &&
                          reportData?.tyre?.ityre_rearleftpowerwindow !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_rearleftpowerwindow === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear left Power Window/Manual Lever
                                </p>
                              </div>

                              {isImages?.ityre_rearleftpowerwindowimage ===
                                1 && (
                                <div className="col-lg-2 text-center col-6">
                                  <div className="col-lg-2 text-center col-6">
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(
                                          `ityre_rearleftpowerwindowimage`
                                        )
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  </div>
                                </div>
                              )}

                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rearleftpowerwindow}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_autolockbutton !== null &&
                          reportData?.tyre?.ityre_autolockbutton !== "" &&
                          reportData?.tyre?.ityre_autolockbutton !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_autolockbutton === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Auto Lock Button{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_autolockbuttonimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_autolockbuttonimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_autolockbutton}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_windowsafetylock !== null &&
                          reportData?.tyre?.ityre_windowsafetylock !== "" &&
                          reportData?.tyre?.ityre_windowsafetylock !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_windowsafetylock === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Window Safety Lock{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_windowsafetylockimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_windowsafetylockimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_windowsafetylock}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6 mt-2">
                          Dash / Roof Controls
                        </h4>

                        {reportData?.tyre?.ityre_interiorlightings !== null &&
                          reportData?.tyre?.ityre_interiorlightings !== "" &&
                          reportData?.tyre?.ityre_interiorlightings !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_interiorlightings === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Interior Lightings{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_interiorlightingsimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_interiorlightingsimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_interiorlightings}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_dashcontrolsac !== null &&
                          reportData?.tyre?.ityre_dashcontrolsac !== "" &&
                          reportData?.tyre?.ityre_dashcontrolsac !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_dashcontrolsac === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Dash Controls - A/C
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_dashcontrolsacimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_dashcontrolsacimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_dashcontrolsac}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_dashcontrolsdefog !== null &&
                          reportData?.tyre?.ityre_dashcontrolsdefog !== "" &&
                          reportData?.tyre?.ityre_dashcontrolsdefog !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_dashcontrolsdefog === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Dash Controls - De-Fog{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_dashcontrolsdefogimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_dashcontrolsdefogimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_dashcontrolsdefog}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_dashcontrolshazzardlights !==
                          null &&
                          reportData?.tyre?.ityre_dashcontrolshazzardlights !==
                            "" &&
                          reportData?.tyre?.ityre_dashcontrolshazzardlights !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_dashcontrolshazzardlights === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Dash Controls - Hazzard Lights{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_dashcontrolshazzardlightsimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_dashcontrolshazzardlightsimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_dashcontrolshazzardlights
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_dashcontrolsparkingbutton !==
                          null &&
                          reportData?.tyre?.ityre_dashcontrolsparkingbutton !==
                            "" &&
                          reportData?.tyre?.ityre_dashcontrolsparkingbutton !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_dashcontrolsparkingbutton === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Dash Controls - Parking Button
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_dashcontrolsparkingbuttonimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_dashcontrolsparkingbuttonimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_dashcontrolsparkingbutton
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_dashcontrolothers !== null &&
                          reportData?.tyre?.ityre_dashcontrolothers !== "" &&
                          reportData?.tyre?.ityre_dashcontrolothers !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_dashcontrolothers === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Dash Controls - Others
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_dashcontrolothersimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_dashcontrolothersimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_dashcontrolothers}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_audio !== null &&
                          reportData?.tyre?.ityre_audio !== "" &&
                          reportData?.tyre?.ityre_audio !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields?.ityre_audio === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Audio/Video
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_audioimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_audioimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_audio}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_trunkrelease !== null &&
                          reportData?.body?.ibody_trunkrelease !== "" &&
                          reportData?.body?.ibody_trunkrelease !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ibody_trunkrelease === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Trunk Release Lever / Button
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_trunkreleaseimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_trunkreleaseimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_trunkrelease}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_fuelcaprelease !== null &&
                          reportData?.body?.ibody_fuelcaprelease !== "" &&
                          reportData?.body?.ibody_fuelcaprelease !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ibody_fuelcaprelease === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Fuel Cap Release Lever / Button{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_fuelcapreleaseimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_fuelcapreleaseimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_fuelcaprelease}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_bonetrelease !== null &&
                          reportData?.body?.ibody_bonetrelease !== "" &&
                          reportData?.body?.ibody_bonetrelease !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ibody_bonetrelease === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Bonnet Release Lever / Button{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_bonnetlreleaseimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_bonnetlreleaseimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_bonetrelease}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6 mt-2">
                          Poshish
                        </h4>

                        {reportData?.tyre?.ityre_roofposhish !== null &&
                          reportData?.tyre?.ityre_roofposhish !== "" &&
                          reportData?.tyre?.ityre_roofposhish !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_roofposhish === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Roof Poshish{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_roofposhishimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_roofposhishimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_roofposhish}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_floormat !== null &&
                          reportData?.tyre?.ityre_floormat !== "" &&
                          reportData?.tyre?.ityre_floormat !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields?.ityre_floormat === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Floor Mat
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_floormatimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_floormatimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_floormat}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_frontrightseatposhish !==
                          null &&
                          reportData?.tyre?.ityre_frontrightseatposhish !==
                            "" &&
                          reportData?.tyre?.ityre_frontrightseatposhish !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_frontrightseatposhish === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Seat Poshish
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_frontrightseatposhishimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_frontrightseatposhishimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_frontrightseatposhish
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_frontleftseatposhish !==
                          null &&
                          reportData?.tyre?.ityre_frontleftseatposhish !== "" &&
                          reportData?.tyre?.ityre_frontleftseatposhish !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_frontleftseatposhish === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Seat Poshish
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_frontleftseatposhishimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_frontleftseatposhishimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_frontleftseatposhish}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rearseatposhish !== null &&
                          reportData?.tyre?.ityre_rearseatposhish !== "" &&
                          reportData?.tyre?.ityre_rearseatposhish !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_rearseatposhish === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Seat Poshish
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rearseatposhishimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_rearseatposhishimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rearseatposhish}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_dashboardcondition !== null &&
                          reportData?.tyre?.ityre_dashboardcondition !== "" &&
                          reportData?.tyre?.ityre_dashboardcondition !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.ityre_dashboardcondition === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Dashboard Condition
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_dashboardconditionimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_dashboardconditionimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_dashboardcondition}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6 mt-2">
                          Equipment
                        </h4>

                        {reportData?.ac?.iac_sparetire !== null &&
                          reportData?.ac?.iac_sparetire !== "" &&
                          reportData?.ac?.iac_sparetire !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields?.iac_sparetire === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Spare Tire
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_sparetyreimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_sparetyreimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_sparetire}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_puncturerepairekit !== null &&
                          reportData?.ac?.iac_puncturerepairekit !== "" &&
                          reportData?.ac?.iac_puncturerepairekit !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields
                                  ?.iac_puncturerepairekit === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Puncture Kit{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_puncturerepairekitimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_puncturerepairekitimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_puncturerepairekit}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_tools !== null &&
                          reportData?.ac?.iac_tools !== "" &&
                          reportData?.ac?.iac_tools !== "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields?.iac_tools === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Tools</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_toolsimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_toolsimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_tools}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_jack !== null &&
                          reportData?.ac?.iac_jack !== "" &&
                          reportData?.ac?.iac_jack !== "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.interiorfields?.iac_jack === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Jack</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_jackimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() => handleImage(`iac_jackimage`)}
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_jack}
                                </h5>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading7">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapse7"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapse7"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Ac / Heater
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.acheater}%
                          </h5>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapse7"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                    >
                      <div className="accordion-body">
                        <h4 className="text-secondary text-center fs-6">
                          AC / Heater Check Up
                        </h4>

                        {reportData?.suspention?.iss_acfitted !== null &&
                          reportData?.suspention?.iss_acfitted !== "" &&
                          reportData?.suspention?.iss_acfitted !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.acheatervalue?.iss_acfitted === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  AC Fitted
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_acfittedimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`iss_acfittedimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_acfitted}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_acoperational !== null &&
                          reportData?.tyre?.ityre_acoperational !== "" &&
                          reportData?.tyre?.ityre_acoperational !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.acheatervalue
                                  ?.ityre_acoperational === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  AC Operational
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_acoperationalimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`ityre_acoperationalimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_acoperational}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_blower !== null &&
                          reportData?.tyre?.ityre_blower !== "" &&
                          reportData?.tyre?.ityre_blower !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.acheatervalue?.ityre_blower === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Blower</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_blowerimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_blowerimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_blower}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_cooling !== null &&
                          reportData?.tyre?.ityre_cooling !== "" &&
                          reportData?.tyre?.ityre_cooling !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.acheatervalue?.ityre_cooling === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Cooling</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_coolingimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`ityre_coolingimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_cooling}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_heating !== null &&
                          reportData?.tyre?.ityre_heating !== "" &&
                          reportData?.tyre?.ityre_heating !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.acheatervalue?.ityre_heating === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Heating</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_heatingimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`ityre_heatingimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_heating}
                                </h5>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading8">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapse8"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapse8"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Electrical & Electronics
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.electronics}%
                          </h5>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapse8"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingThree"
                    >
                      <div className="accordion-body">
                        <h4 className="text-secondary text-center fs-6">
                          Computer Check up{" "}
                        </h4>

                        {reportData?.tyre?.ityre_computercheckup !== null &&
                          reportData?.tyre?.ityre_computercheckup !== "" &&
                          reportData?.tyre?.ityre_computercheckup !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_computercheckup === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Computer Check up / Malfunction Check
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_computercheckupimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`ityre_computercheckupimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_computercheckup}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rearviewcamera !== null &&
                          reportData?.tyre?.ityre_rearviewcamera !== "" &&
                          reportData?.tyre?.ityre_rearviewcamera !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_rearviewcamera === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear View Camera
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rearviewcameraimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`ityre_rearviewcameraimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rearviewcamera}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_batterywarninglight !== null &&
                          reportData?.tyre?.ityre_batterywarninglight !== "" &&
                          reportData?.tyre?.ityre_batterywarninglight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_batterywarninglight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Battery Warning Light
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_batterywarninglightimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_batterywarninglightimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_batterywarninglight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_oilpressurelowwarninglight !==
                          null &&
                          reportData?.tyre?.ityre_oilpressurelowwarninglight !==
                            "" &&
                          reportData?.tyre?.ityre_oilpressurelowwarninglight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_oilpressurelowwarninglight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Oil Pressure Low Warning Light{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_oilpressurelowwarninglightimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_oilpressurelowwarninglightimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_oilpressurelowwarninglight
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_tempraturewarninglight !==
                          null &&
                          reportData?.tyre?.ityre_tempraturewarninglight !==
                            "" &&
                          reportData?.tyre?.ityre_tempraturewarninglight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_tempraturewarninglight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Temperature Warning Light / Gauge
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_tempraturewarninglightimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_tempraturewarninglightimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_tempraturewarninglight
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_guages !== null &&
                          reportData?.tyre?.ityre_guages !== "" &&
                          reportData?.tyre?.ityre_guages !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue?.ityre_guages === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Gauges</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_guagesimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_guagesimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_guages}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_airbagwarninglight !== null &&
                          reportData?.tyre?.ityre_airbagwarninglight !== "" &&
                          reportData?.tyre?.ityre_airbagwarninglight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_airbagwarninglight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Air Bag Warning Light{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_airbagwarninglightimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_airbagwarninglightimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_airbagwarninglight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_steeringwarninglight !==
                          null &&
                          reportData?.tyre?.ityre_steeringwarninglight !== "" &&
                          reportData?.tyre?.ityre_steeringwarninglight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_steeringwarninglight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Power Steering Warning Light{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_steeringwarninglightimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_steeringwarninglightimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_steeringwarninglight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_abswarninglight !== null &&
                          reportData?.tyre?.ityre_abswarninglight !== "" &&
                          reportData?.tyre?.ityre_abswarninglight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_abswarninglight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  ABS Warning Light
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_abswarninglightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_abswarninglightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_abswarninglight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_keyfobbatterylowlight !==
                          null &&
                          reportData?.tyre?.ityre_keyfobbatterylowlight !==
                            "" &&
                          reportData?.tyre?.ityre_keyfobbatterylowlight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ityre_keyfobbatterylowlight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Key Fob Battery Low Light
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_keyfobbatterylowlightimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_keyfobbatterylowlightimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.tyre
                                      ?.ityre_keyfobbatterylowlight
                                  }
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6">
                          Battery
                        </h4>

                        {reportData?.body?.ibody_voltage !== null &&
                          reportData?.body?.ibody_voltage !== "" &&
                          reportData?.body?.ibody_voltage !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue?.ibody_voltage ===
                                1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Voltage</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_voltageimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_voltageimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_voltage}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_terminalscondition !== null &&
                          reportData?.body?.ibody_terminalscondition !== "" &&
                          reportData?.body?.ibody_terminalscondition !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.ibody_terminalscondition === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Terminal Condition
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_terminalsconditionimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ibody_terminalsconditionimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_terminalscondition}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_charging !== null &&
                          reportData?.suspention?.iss_charging !== "" &&
                          reportData?.suspention?.iss_charging !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue?.iss_charging === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Charging</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_chargingimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_chargingimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.suspention?.iss_charging}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.suspention?.iss_alternatoroperation !==
                          null &&
                          reportData?.suspention?.iss_alternatoroperation !==
                            "" &&
                          reportData?.suspention?.iss_alternatoroperation !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.electronicsvalue
                                  ?.iss_alternatoroperation === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Alternator Operation
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iss_alternate_optionimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iss_alternate_optionimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {
                                    reportData?.suspention
                                      ?.iss_alternatoroperation
                                  }
                                </h5>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading9">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapse9"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapse9"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Exterior & Body
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.exteriorbasic}%
                          </h5>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapse9"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingThree"
                    >
                      <div className="accordion-body">
                        <h4 className="text-secondary text-center fs-6">
                          Car frame
                        </h4>

                        {reportData?.ac?.iac_trunklock !== null &&
                          reportData?.ac?.iac_trunklock !== "" &&
                          reportData?.ac?.iac_trunklock !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue?.iac_trunklock === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Trunk Lock{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_trunklockimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(`iac_trunklockimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_trunklock}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_frontwindshield !== null &&
                          reportData?.body?.ibody_frontwindshield !== "" &&
                          reportData?.body?.ibody_frontwindshield !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ibody_frontwindshield === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Windshield Condition
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_frontwindshieldimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_frontwindshieldimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_frontwindshield}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_rearwindshieldcondition !== null &&
                          reportData?.ac?.iac_rearwindshieldcondition !== "" &&
                          reportData?.ac?.iac_rearwindshieldcondition !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.iac_rearwindshieldcondition === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Windshield Condition
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_rearwindshieldimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_rearwindshieldimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_rearwindshieldcondition}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_frontrightdoorwindow !==
                          null &&
                          reportData?.tyre?.ityre_frontrightdoorwindow !== "" &&
                          reportData?.tyre?.ityre_frontrightdoorwindow !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_frontrightdoorwindow === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Door Window
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_frontrightdoorwindowimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_frontrightdoorwindowimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_frontrightdoorwindow}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_frontleftdoorwindow !== null &&
                          reportData?.tyre?.ityre_frontleftdoorwindow !== "" &&
                          reportData?.tyre?.ityre_frontleftdoorwindow !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_frontleftdoorwindow === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Door Window{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_frontleftdoorwindowimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_frontleftdoorwindowimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_frontleftdoorwindow}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rearrightdoorwindow !== null &&
                          reportData?.tyre?.ityre_rearrightdoorwindow !== "" &&
                          reportData?.tyre?.ityre_rearrightdoorwindow !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_rearrightdoorwindow === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Right Door Window{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rearrightdoorwindowimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_rearrightdoorwindowimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rearrightdoorwindow}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_rearleftdoorwindow !== null &&
                          reportData?.tyre?.ityre_rearleftdoorwindow !== "" &&
                          reportData?.tyre?.ityre_rearleftdoorwindow !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_rearleftdoorwindow === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Left Door Window
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rearleftdoorwindowimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `ityre_rearleftdoorwindowimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rearleftdoorwindow}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_windscreenwiper !== null &&
                          reportData?.tyre?.ityre_windscreenwiper !== "" &&
                          reportData?.tyre?.ityre_windscreenwiper !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_windscreenwiper === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Windscreen Wiper{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_windscreenwiperimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_windscreenwiperimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_windscreenwiper}
                                </h5>
                              </div>
                            </div>
                          )}

                        <h4 className="text-secondary text-center fs-6 mt-2">
                          Exterior Lights
                        </h4>

                        {reportData?.tyre?.ityre_rightheadlight !== null &&
                          reportData?.tyre?.ityre_rightheadlight !== "" &&
                          reportData?.tyre?.ityre_rightheadlight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_rightheadlight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Headlight (Working)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_rightheadlightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_rightheadlightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_rightheadlight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_leftheadlight !== null &&
                          reportData?.tyre?.ityre_leftheadlight !== "" &&
                          reportData?.tyre?.ityre_leftheadlight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_leftheadlight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Headlight (Working)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_leftheadlightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_leftheadlightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_leftheadlight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_rightheadlight !== null &&
                          reportData?.body?.ibody_rightheadlight !== "" &&
                          reportData?.body?.ibody_rightheadlight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ibody_rightheadlight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Headlight (Condition)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_rightheadlightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_rightheadlightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_rightheadlight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_leftheadlight !== null &&
                          reportData?.body?.ibody_leftheadlight !== "" &&
                          reportData?.body?.ibody_leftheadlight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ibody_leftheadlight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Headlight (Condition)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_leftheadlightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_leftheadlightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_leftheadlight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_righttaillight !== null &&
                          reportData?.tyre?.ityre_righttaillight !== "" &&
                          reportData?.tyre?.ityre_righttaillight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_righttaillight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Taillight (Working)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_righttaillightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_righttaillightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_righttaillight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.tyre?.ityre_lefttaillight !== null &&
                          reportData?.tyre?.ityre_lefttaillight !== "" &&
                          reportData?.tyre?.ityre_lefttaillight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ityre_lefttaillight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Taillight (Working)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ityre_lefttaillightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ityre_lefttaillightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.tyre?.ityre_lefttaillight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.body?.ibody_righttaillight !== null &&
                          reportData?.body?.ibody_righttaillight !== "" &&
                          reportData?.body?.ibody_righttaillight !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.ibody_righttaillight === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Right Taillight (Condition)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.ibody_righttaillightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`ibody_righttaillightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.body?.ibody_righttaillight}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.ac?.iac_lefttaillightcondition !== null &&
                          reportData?.ac?.iac_lefttaillightcondition !== "" &&
                          reportData?.ac?.iac_lefttaillightcondition !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.exteriorvalue
                                  ?.iac_lefttaillightcondition === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Left Taillight (Condition)
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.iac_lefttaillightimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`iac_lefttaillightimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.ac?.iac_lefttaillightcondition}
                                </h5>
                              </div>
                            </div>
                          )}

                        {/* <div
                          className={`row border align-items-center py-2 reportAccTab ${
                            percentage?.exteriorvalue
                              ?.ibody_rightheadlight === 1
                              ? "bgGreenLight"
                              : "bgRedLight"
                          }`}
                        >
                          <div className="col-lg-7 col-12">
                            <p className="AccorData defectName">
                              Right Headlight (Condition)
                            </p>
                          </div>
                          <div className="col-lg-2 text-center col-6">
                            {isImages?.ibody_rightheadlightimage ===
                              1 && (
                              <Button
                                variant="outlined"
                                className="imageViewBtn print-hidden p-0 align-items-center"
                                onClick={() =>
                                  handleImage(`ibody_rightheadlightimage`)
                                }
                              >
                                <i className="fa-solid fa-image me-2"></i>
                                View
                              </Button>
                            )}
                          </div>
                          <div className="col-lg-3 text-center col-6">
                            <h5 className="m-0 defectName">
                              {reportData?.ac?.ibody_rightheadlight}
                            </h5>
                          </div>
                        </div>



                        <div
                          className={`row border align-items-center py-2 reportAccTab ${
                            percentage?.exteriorvalue
                              ?.ibody_leftheadlight === 1
                              ? "bgGreenLight"
                              : "bgRedLight"
                          }`}
                        >
                          <div className="col-lg-7 col-12">
                            <p className="AccorData defectName">
                              Left Headlight (Condition)
                            </p>
                          </div>
                          <div className="col-lg-2 text-center col-6">
                            {isImages?.ibody_leftheadlightimage ===
                              1 && (
                              <Button
                                variant="outlined"
                                className="imageViewBtn print-hidden p-0 align-items-center"
                                onClick={() =>
                                  handleImage(`ibody_leftheadlightimage`)
                                }
                              >
                                <i className="fa-solid fa-image me-2"></i>
                                View
                              </Button>
                            )}
                          </div>
                          <div className="col-lg-3 text-center col-6">
                            <h5 className="m-0 defectName">
                              {reportData?.ac?.ibody_leftheadlight}
                            </h5>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="heading12">
                      <button
                        className="accordion-button w-100"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapse12"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapse12"
                      >
                        <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                          <h5 className="m-0 fs-6">
                            <i className="fa-solid fa-car me-3"></i>
                            Tyres
                          </h5>

                          <h5 className="m-0 me-3 fs-6">
                            {percentage?.tyres}%
                          </h5>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapse12"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingThree"
                    >
                      <div className="accordion-body">
                        <h4 className="text-secondary text-center fs-6">
                          Tyres
                        </h4>

                        {reportData?.test?.itest_frontrighttyrebrand !== null &&
                          reportData?.test?.itest_frontrighttyrebrand !== "" &&
                          reportData?.test?.itest_frontrighttyrebrand !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab bgGreenLight`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Tyre Brand
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontrighttyrebrandimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="align-items-center imageViewBtn print-hidden"
                                    onClick={() =>
                                      handleImage(
                                        `itest_frontrighttyrebrandimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontrighttyrebrand}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontrighttyre !== null &&
                          reportData?.test?.itest_frontrighttyre !== "" &&
                          reportData?.test?.itest_frontrighttyre !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.tyresvalues
                                  ?.itest_frontrighttyre === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Right Tyre{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontrighttyreimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontrighttyreimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontrighttyre}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontlefttyrebrand !== null &&
                          reportData?.test?.itest_frontlefttyrebrand !== "" &&
                          reportData?.test?.itest_frontlefttyrebrand !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab bgGreenLight`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Tyre Brand
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontlefttyrebrandimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `itest_frontlefttyrebrandimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontlefttyrebrand}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_frontlefttyre !== null &&
                          reportData?.test?.itest_frontlefttyre !== "" &&
                          reportData?.test?.itest_frontlefttyre !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.tyresvalues?.itest_frontlefttyre ===
                                1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Front Left Tyre
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_frontlefttyreimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_frontlefttyreimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_frontlefttyre}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rearrighttyrebrand !== null &&
                          reportData?.test?.itest_rearrighttyrebrand !== "" &&
                          reportData?.test?.itest_rearrighttyrebrand !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab bgGreenLight`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Right Tyre Brand{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rearrighttyrebrandimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `itest_rearrighttyrebrandimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearrighttyrebrand}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rearrighttyre !== null &&
                          reportData?.test?.itest_rearrighttyre !== "" &&
                          reportData?.test?.itest_rearrighttyre !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.tyresvalues?.itest_rearrighttyre ===
                                1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Right Tyre
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rearrighttyreimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_rearrighttyreimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearrighttyre}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rearlefttyrebrand !== null &&
                          reportData?.test?.itest_rearlefttyrebrand !== "" &&
                          reportData?.test?.itest_rearlefttyrebrand !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab bgGreenLight`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Left Tyre Brand{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rearlefttyrebrandimage ===
                                  1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(
                                        `itest_rearlefttyrebrandimage`
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearlefttyrebrand}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rearlefttyre !== null &&
                          reportData?.test?.itest_rearlefttyre !== "" &&
                          reportData?.test?.itest_rearlefttyre !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.tyresvalues?.itest_rearlefttyre ===
                                1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Rear Left Tyre
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rearlefttyreimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_rearlefttyreimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rearlefttyre}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_tyresize !== null &&
                          reportData?.test?.itest_tyresize !== "" &&
                          reportData?.test?.itest_tyresize !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab bgGreenLight`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Tyre Size
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_tyresizeimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_tyresizeimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_tyresize}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_rims !== null &&
                          reportData?.test?.itest_rims !== "" &&
                          reportData?.test?.itest_rims !== "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab bgGreenLight`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">Rims</p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_rimsimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_rimsimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_rims}
                                </h5>
                              </div>
                            </div>
                          )}

                        {reportData?.test?.itest_wheelcaps !== null &&
                          reportData?.test?.itest_wheelcaps !== "" &&
                          reportData?.test?.itest_wheelcaps !==
                            "Not Applicable" && (
                            <div
                              className={`row border align-items-center py-2 reportAccTab ${
                                percentage?.tyresvalues?.itest_wheelcaps === 1
                                  ? "bgGreenLight"
                                  : "bgRedLight"
                              }`}
                            >
                              <div className="col-lg-7 col-12">
                                <p className="AccorData defectName">
                                  Wheel Caps{" "}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center col-6">
                                {isImages?.itest_wheelcapsimage === 1 && (
                                  <Button
                                    variant="outlined"
                                    className="imageViewBtn print-hidden p-0 align-items-center"
                                    onClick={() =>
                                      handleImage(`itest_wheelcapsimage`)
                                    }
                                  >
                                    <i className="fa-solid fa-image me-2"></i>
                                    View
                                  </Button>
                                )}
                              </div>
                              <div className="col-lg-3 text-center col-6">
                                <h5 className="m-0 defectName">
                                  {reportData?.test?.itest_wheelcaps}
                                </h5>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  {reportData?.interior?.ii_testdrive === "Yes" && (
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading10">
                        <button
                          className="accordion-button w-100"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapse10"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapse10"
                        >
                          <div className=" reportAcc_Header d-flex justify-content-between align-items-center w-100">
                            <h5 className="m-0 fs-6">
                              <i className="fa-solid fa-car me-3"></i>
                              Test Drive
                            </h5>

                            {/* <h5 className="m-0 me-3 fs-6">
                            {percentage?.testdrive}%
                          </h5> */}
                          </div>
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapse10"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingThree"
                      >
                        <div className="accordion-body">
                          {reportData?.interior?.ii_enginepick !== null &&
                            reportData?.interior?.ii_enginepick !== "" &&
                            reportData?.interior?.ii_enginepick !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue?.ii_enginepick ===
                                  1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Engine Pick{" "}
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_enginepickimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="align-items-center imageViewBtn print-hidden"
                                      onClick={() =>
                                        handleImage(`ii_enginepickimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_enginepick}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_driveshaft_noise !== null &&
                            reportData?.interior?.ii_driveshaft_noise !== "" &&
                            reportData?.interior?.ii_driveshaft_noise !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_driveshaft_noise === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Drive Shaft Noise{" "}
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_driveshaftnoiceimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_driveshaftnoiceimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_driveshaft_noise}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_gearshiftingauto !== null &&
                            reportData?.interior?.ii_gearshiftingauto !== "" &&
                            reportData?.interior?.ii_gearshiftingauto !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_gearshiftingauto === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Gear Shifting (Automatic)
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_gearshiftingautoimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_gearshiftingautoimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_gearshiftingauto}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_brakepedaloperation !==
                            null &&
                            reportData?.interior?.ii_brakepedaloperation !==
                              "" &&
                            reportData?.interior?.ii_brakepedaloperation !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_brakepedaloperation === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Brake Pedal Operation
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_brakepedaloperationimage ===
                                    1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(
                                          `ii_brakepedaloperationimage`
                                        )
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {
                                      reportData?.interior
                                        ?.ii_brakepedaloperation
                                    }
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_absoperation !== null &&
                            reportData?.interior?.ii_absoperation !== "" &&
                            reportData?.interior?.ii_absoperation !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_absoperation === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    ABS Operation
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_absoperationimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_absoperationimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_absoperation}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_frontsuspension !== null &&
                            reportData?.interior?.ii_frontsuspension !== "" &&
                            reportData?.interior?.ii_frontsuspension !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_frontsuspension === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Front Suspension (While Driving){" "}
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_frontsuspensionimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_frontsuspensionimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_frontsuspension}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_rearsuspension !== null &&
                            reportData?.interior?.ii_rearsuspension !== "" &&
                            reportData?.interior?.ii_rearsuspension !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_rearsuspension === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Rear Suspension (While Driving)
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_rearssupensionimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_rearssupensionimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_rearsuspension}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_steringoperation !== null &&
                            reportData?.interior?.ii_steringoperation !== "" &&
                            reportData?.interior?.ii_steringoperation !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_steringoperation === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Steering Operation (While Driving){" "}
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_steeringoperationimage ===
                                    1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_steeringoperationimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_steringoperation}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_steeringwheelalignment !==
                            null &&
                            reportData?.interior?.ii_steeringwheelalignment !==
                              "" &&
                            reportData?.interior?.ii_steeringwheelalignment !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_steeringwheelalignment === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Steering Wheel Alignment (While Driving){" "}
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_steeringalignimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_steeringalignimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {
                                      reportData?.interior
                                        ?.ii_steeringwheelalignment
                                    }
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.test?.ii_acoperation &&
                            reportData?.test?.ii_acoperation !== null &&
                            reportData?.test?.ii_acoperation !== "" &&
                            reportData?.test?.ii_acoperation !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue?.ii_acoperation ===
                                  1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    AC Operation (While Driving){" "}
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_acoperationimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_acoperationimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.test?.ii_acoperation}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_heateroperation !== null &&
                            reportData?.interior?.ii_heateroperation !== "" &&
                            reportData?.interior?.ii_heateroperation !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue
                                    ?.ii_heateroperation === 1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Heater Operation (While Driving)
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_heateroperationimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_heateroperationimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_heateroperation}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_speedometer !== null &&
                            reportData?.interior?.ii_speedometer !== "" &&
                            reportData?.interior?.ii_speedometer !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab ${
                                  percentage?.testdrivevalue?.ii_speedometer ===
                                  1
                                    ? "bgGreenLight"
                                    : "bgRedLight"
                                }`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Speedometer (While Driving)
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_speedometerimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_speedometerimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_speedometer}
                                  </h5>
                                </div>
                              </div>
                            )}
                          {reportData?.interior?.ii_testdrivedoneby !== null &&
                            reportData?.interior?.ii_testdrivedoneby !== "" &&
                            reportData?.interior?.ii_testdrivedoneby !==
                              "Not Applicable" && (
                              <div
                                className={`row border align-items-center py-2 reportAccTab bgGreenLight`}
                              >
                                <div className="col-lg-7 col-12">
                                  <p className="AccorData defectName">
                                    Test Drive Done By{" "}
                                  </p>
                                </div>
                                <div className="col-lg-2 text-center col-6">
                                  {isImages?.ii_testdrivedonebyimage === 1 && (
                                    <Button
                                      variant="outlined"
                                      className="imageViewBtn print-hidden p-0 align-items-center"
                                      onClick={() =>
                                        handleImage(`ii_testdrivedonebyimage`)
                                      }
                                    >
                                      <i className="fa-solid fa-image me-2"></i>
                                      View
                                    </Button>
                                  )}
                                </div>
                                <div className="col-lg-3 text-center col-6">
                                  <h5 className="m-0 defectName">
                                    {reportData?.interior?.ii_testdrivedoneby}
                                  </h5>
                                </div>
                              </div>
                            )}
                          .
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <h5 className="report-customer-info fw-600 fw-700">
                    Main - Vehicle Pictures
                  </h5>
                </div>

                {lastImages?.iex_front_view_image !== 0 &&
                  lastImages?.iex_front_view_image?.length > 0 &&
                  lastImages?.iex_front_view_image?.map((image) => (
                    <div className="col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_front_view_image/${image?.inspectionimage_name}`}
                          alt="Front View Image"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Front View Image</p>
                    </div>
                  ))}

                {lastImages?.iex_oblique_front_right !== 0 &&
                  lastImages?.iex_oblique_front_right?.length > 0 &&
                  lastImages?.iex_oblique_front_right?.map((image) => (
                    <div className="col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_oblique_front_right/${image?.inspectionimage_name}`}
                          alt="Oblique Front Right"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Oblique Front Right</p>
                    </div>
                  ))}

                {lastImages?.iex_oblique_rear_right !== 0 &&
                  lastImages?.iex_oblique_rear_right?.length > 0 &&
                  lastImages?.iex_oblique_rear_right?.map((image) => (
                    <div className="col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_oblique_rear_right/${image?.inspectionimage_name}`}
                          alt="Oblique Front Right"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Oblique Rear Right</p>
                    </div>
                  ))}

                {lastImages?.iex_trunk_open_pic !== 0 &&
                  lastImages?.iex_trunk_open_pic?.length > 0 &&
                  lastImages?.iex_trunk_open_pic?.map((image) => (
                    <div className="col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_trunk_open_pic/${image?.inspectionimage_name}`}
                          alt="Trunk Open"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Trunk Open</p>
                    </div>
                  ))}

                {lastImages?.iex_rear_view_image !== 0 &&
                  lastImages?.iex_rear_view_image?.length > 0 &&
                  lastImages?.iex_rear_view_image?.map((image) => (
                    <div className="col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_rear_view_image/${image?.inspectionimage_name}`}
                          alt="Trunk Open"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Rear View Image</p>
                    </div>
                  ))}

                {lastImages?.iex_oblique_rear_left !== 0 &&
                  lastImages?.iex_oblique_rear_left?.length > 0 &&
                  lastImages?.iex_oblique_rear_left?.map((image) => (
                    <div className="col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_oblique_rear_left/${image?.inspectionimage_name}`}
                          alt="Oblique Rear Left"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Oblique Rear Left</p>
                    </div>
                  ))}

                {lastImages?.iex_oblique_front_left !== 0 &&
                  lastImages?.iex_oblique_front_left?.length > 0 &&
                  lastImages?.iex_oblique_front_left?.map((image) => (
                    <div className="col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_oblique_front_left/${image?.inspectionimage_name}`}
                          alt="Oblique Front Left"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Oblique Front Left</p>
                    </div>
                  ))}

                {lastImages?.iex_vehicle_keys !== 0 &&
                  lastImages?.iex_vehicle_keys?.length > 0 &&
                  lastImages?.iex_vehicle_keys?.map((image) => (
                    <div className="col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_vehicle_keys/${image?.inspectionimage_name}`}
                          alt="Oblique Front Left"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Vehicle Keys / Remote</p>
                    </div>
                  ))}

                {lastImages?.iex_engine_compartment !== 0 &&
                  lastImages?.iex_engine_compartment?.length > 0 &&
                  lastImages?.iex_engine_compartment?.map((image) => (
                    <div className="col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_engine_compartment/${image?.inspectionimage_name}`}
                          alt="Engine Compartment"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Engine Compartment</p>
                    </div>
                  ))}

                {lastImages?.iex_engine_number !== 0 &&
                  lastImages?.iex_engine_number?.length > 0 &&
                  lastImages?.iex_engine_number?.map((image) => (
                    <div className="col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_engine_number/${image?.inspectionimage_name}`}
                          alt="Engine Number"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Engine Number</p>
                    </div>
                  ))}

                {lastImages?.iex_chassis_plate !== 0 &&
                  lastImages?.iex_chassis_plate?.length > 0 &&
                  lastImages?.iex_chassis_plate?.map((image) => (
                    <div className="col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_chassis_plate/${image?.inspectionimage_name}`}
                          alt="Chassis Plate"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Chassis Plate</p>
                    </div>
                  ))}

                {lastImages?.iex_chassis_number !== 0 &&
                  lastImages?.iex_chassis_number?.length > 0 &&
                  lastImages?.iex_chassis_number?.map((image) => (
                    <div className="col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_chassis_number/${image?.inspectionimage_name}`}
                          alt="Chassis Number"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Chassis Number</p>
                    </div>
                  ))}

                {lastImages?.iex_front_interior !== 0 &&
                  lastImages?.iex_front_interior?.length > 0 &&
                  lastImages?.iex_front_interior?.map((image) => (
                    <div className="col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_front_interior/${image?.inspectionimage_name}`}
                          alt=""
                          srcSet="Front Interior"
                        />
                      </div>
                      <p className="text-center">Front Interior</p>
                    </div>
                  ))}

                {lastImages?.iex_back_interior !== 0 &&
                  lastImages?.iex_back_interior?.length > 0 &&
                  lastImages?.iex_back_interior?.map((image) => (
                    <div className="col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_back_interior/${image?.inspectionimage_name}`}
                          alt="Back Interior"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">Back Interior</p>
                    </div>
                  ))}

                {lastImages?.iex_fourwayswitchimage !== 0 &&
                  lastImages?.iex_fourwayswitchimage?.length > 0 &&
                  lastImages?.iex_fourwayswitchimage?.map((image) => (
                    <div className="col-6">
                      <div>
                        <img
                          className="img-fluid w-100 p-1 rounded"
                          src={`${imageURL}/iex_fourwayswitchimage/${image?.inspectionimage_name}`}
                          alt="4 Way Switch"
                          srcSet=""
                        />
                      </div>
                      <p className="text-center">4 Way Switch</p>
                    </div>
                  ))}



{lastImages?.iex_odo_meter !== 0 &&
                  lastImages?.iex_odo_meter?.length > 0 &&
                  lastImages?.iex_odo_meter?.map((image) => (
                    <div className="col-6">
                      <img
                        className="img-fluid w-100 p-1 rounded"
                        src={`${imageURL}/iex_odo_meter/${image?.inspectionimage_name}`}
                        alt="Back Interior"
                        srcSet=""
                      />
                      <p className="text-center">Odo Meter</p>
                    </div>
                  ))}


                {lastImages?.iex_extra_images !== 0 &&
                  lastImages?.iex_extra_images?.length > 0 &&
                  lastImages?.iex_extra_images?.map((image) => (
                    <div className="col-6">
                      <img
                        className="img-fluid w-100 p-1 rounded"
                        src={`${imageURL}/iex_extra_images/${image?.inspectionimage_name}`}
                        alt="Back Interior"
                        srcSet=""
                      />
                      <p className="text-center">Extra Image</p>
                    </div>
                  ))}
              </div>
              <div className="mt-5">
                <div>
                  <h5 className="report-customer-info fs-6 fw-600 fw-700">
                    Comments
                  </h5>
                </div>
                <div>
                  <ul className="list-unstyled">
                    <li>{reportData?.initial?.iinitial_comment}</li>
                    <li>{reportData?.body?.ibody_comment}</li>
                    <li>{reportData?.interior?.iin_comment}</li>
                    <li>{reportData?.ac?.iac_comment}</li>
                    <li>{reportData?.test?.itest_comment}</li>
                    <li>{reportData?.suspention?.iss_comment}</li>
                    <li>{reportData?.tyre?.ityre_comment}</li>
                    <li>{reportData?.initial?.inspector_comment}</li>
                  </ul>
                </div>
                <di>
                  <h5 className="fs-6 fw-600 fw-700">Disclaimer:</h5>
                </di>
                <di>
                  <p style={{ fontSize: "13px" }}>
                    This report estimates the vehicle's condition on the
                    certification date, based on visible parts' working status.
                    Unseen or uninspected parts are not considered. Odometer
                    reading is relied upon, and famewheels.com is not
                    accountable for its verification. The report is subjective,
                    not a commitment on the car's condition. famewheels.com and
                    its associates are not liable for any consequences arising
                    from its use.
                  </p>
                </di>
              </div>
            </div>
          </div>
        </div>
      )}

      <ImageModal image={imageURL} onClose={LoginClose} open={isOpen} />
    </>
  );
}
